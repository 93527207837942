export const formatTime = (secNum: number) => {
  const hours = Math.floor(secNum / 3600)
  const minutes = Math.floor((secNum - hours * 3600) / 60)
  const seconds = Math.floor(secNum - hours * 3600 - minutes * 60)
  const formatted = `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`

  if (hours > 0) {
    return `${hours < 10 ? `0${hours}` : hours}:${formatted}`
  }

  return formatted
}
