import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { Meta } from '@client/components/Layout/Meta'
import { usePageViewAnalytics } from '@client/hooks/usePageViewAnalytics'
import { Lang, PaginationElement, RootState } from '@client/types'
import { Layout } from '@components/Layout'
import { Chronology } from '@routes/Screen/Chronology'
import { getScreenChronology } from '@selectors/screenChronologySelectors'
import { fetchChrono } from '@store/Screens/screensActions'
import equal from 'fast-deep-equal/es6'

interface LatestParams {
  lang: Lang
  slug: string
}

export const Latest: React.FC<RouteConfigComponentProps<LatestParams>> = ({
  location: { pathname },
  route,
  match: {
    params: { lang = 'en', slug }
  }
}) => {
  const dispatch = useDispatch()

  const chronologyData: PaginationElement = useSelector(
    (state: RootState) => getScreenChronology(state, 'news'),
    equal
  )

  usePageViewAnalytics({ pathname, title: 'News Feed' })

  useEffect(() => {
    dispatch(
      fetchChrono.request({
        screenName: 'news',
        page: 0,
        lang,
        loadPage: true
      })
    )
  }, [dispatch, lang, slug])

  const handleFetchNextChrono = () => {
    if (chronologyData) {
      dispatch(
        fetchChrono.request({
          screenName: 'news',
          page: chronologyData.page,
          lang,
          loadPage: false
        })
      )
    }
  }

  return (
    <Layout pathname={pathname} routeName={route && route.name} type="specials">
      {true && (
        <Meta
          screen={`/${slug}`}
          lang={lang}
          og={{
            title: 'News Feed — Meduza',
            description: 'News Feed — Meduza',
            keywords:
              'News, report, conflict, war, what we know, brief, Russia, Ukraine, Syria, Putin, president, Zelensky, ruble, economy, oil, gas',
            url: 'https://meduza.io/en/latest/news',
            image:
              'https://meduza.io/image/attachments/images/006/066/471/original/d2Z43pdohOngOw-8C8xfVg.png'
          }}
        />
      )}

      <div>
        {chronologyData ? (
          <Chronology
            lang={lang}
            header="News Feed"
            screenName="news"
            fetchNext={handleFetchNextChrono}
            styleContext="isInChronology"
          />
        ) : (
          <div />
        )}
      </div>
    </Layout>
  )
}
