import { RootState } from '@client/types'
import { createCachedSelector } from 're-reselect'

export const getMaterials = (state: RootState) => state.materials

export const getMaterialsById = (state: RootState) => state.materials.byId
export const getCurrentMaterial = (state: RootState) =>
  state.materials.currentMaterial
export const getMaterialLive = (state: RootState) => state.materialLive
export const getMaterialEmbedById = (state: RootState) =>
  state.materialEmbed.byId

export const getCurrentMaterialBanners = ({ materials }: RootState) =>
  materials.currentMaterial
    ? materials.byId[materials.currentMaterial]?.banners
    : null

export const getUnderTheSunScreen = (state: RootState, url: string) => ({
  underTheSun: state.screens.mainScreens['under-the-sun'],
  url
})

export const getUnblockLink = (state: RootState) => state.materials.unblockLink

export const getMaterial = createCachedSelector(
  getMaterialsById,
  (state: RootState, url: string) => url,
  (materials, url) => materials[url]
)((state: RootState, url: string) => url)

export const getCurrentUnderTheSunScreen = createCachedSelector(
  [getUnderTheSunScreen],
  ({ underTheSun, url }) => {
    return underTheSun
      ? {
          ...underTheSun,
          mobile: underTheSun.mobile.map((items) =>
            items
              .filter((item) => item.key !== url)
              .map((item) => {
                if (item.groupped) {
                  const filtered = item.keys.filter((key) => key !== url)
                  return Object.assign({}, item, { keys: filtered })
                }
                return item
              })
          )
        }
      : null
  }
)((_, url) => url)
