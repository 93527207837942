import React from 'react'
import { MaterialContent } from '@client/types'
import { BookLandingMaterial } from '@routes/Material/BookLandingMaterial'
import { BrandMaterial } from '@routes/Material/BrandMaterial'
import { CardMaterial } from '@routes/Material/CardMaterial'
import { EmbedMaterial } from '@routes/Material/EmbedMaterial'
import { GeneralMaterial } from '@routes/Material/GeneralMaterial'
import { LiveMaterial } from '@routes/Material/LiveMaterial'
import { PodcastMaterial } from '@routes/Material/PodcastMaterial'
import { SlidesMaterial } from '@routes/Material/SlidesMaterial'

export const renderMaterialComponents = (material: MaterialContent) => {
  switch (material.layout) {
    case 'brand':
      return <BrandMaterial material={material} />
    case 'slides':
      return <SlidesMaterial material={material} />
    case 'card':
      return <CardMaterial material={material} />
    case 'podcast':
      return <PodcastMaterial material={material} />
    case 'game':
      return <EmbedMaterial material={material} />
    case 'live':
      return <LiveMaterial material={material} />
    case 'book_landing':
      return <BookLandingMaterial material={material} />
    default:
      return <GeneralMaterial material={material} />
  }
}
