export default {
  ru: {
    changeLang: 'EN',
    search: 'Поиск',
    menu: 'Открыть меню',
    auth: 'Авторизация',
    profile: 'Профиль',
    contact: 'Написать нам',
    bookmarks: 'Закладки',
    login: 'Войти',
    logout: 'Выйти'
  },

  en: {
    changeLang: 'RU',
    search: 'Search',
    menu: 'Open menu',
    auth: 'Authentication',
    profile: 'Profile',
    contact: 'Contact Us',
    bookmarks: 'Bookmarks',
    login: 'Login',
    logout: 'Logout'
  }
}
