import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTooltip } from '@client/selectors/tooltipSelector'
import { hideTooltip } from '@client/store/Tooltip/tooltipActions'
import { TooltipState } from '@client/store/Tooltip/tooltipReducer'
import { makeClassName } from '@utils/makeClassName'
import { viewportSize } from '@utils/viewportSize'

import { Tooltip } from '@meduza/ui-kit-2'

import styles from './TooltipContainer.module.css'

/*
 * Контейнер для сноски (сноска находится в ките)
 * при появлении сноски ставится дата-атрибут на сноску, чтобы подсвечивать ее
 */

type FootnoteStyleType = {
  left: number
  top: number
}

export const TooltipContainer = () => {
  const dispatch = useDispatch()
  const tooltip = useSelector(getTooltip)

  const [prevTooltip, setPrevTooltip] = useState<TooltipState | null>(null)

  const [position, setPosition] = useState<
    ['left' | 'center', 'top' | 'bottom']
  >(['center', 'top'])

  const popoverRef = useRef<HTMLDivElement | null>(null)

  const tooltipRef = tooltip?.target || prevTooltip?.target

  const isFootnoteExists = !!tooltipRef
  const [footnoteStyle, setFootnoteStyle] = useState<FootnoteStyleType>({
    left: 0,
    top: 0
  })

  const hide = useCallback(() => {
    setPrevTooltip(null)
    dispatch(hideTooltip())
  }, [setPrevTooltip, dispatch])

  useEffect(() => {
    if (!tooltipRef || !popoverRef.current) {
      return
    }

    const rect = tooltipRef.getBoundingClientRect()
    const popover = popoverRef.current.getBoundingClientRect()

    const { height } = viewportSize()

    const coords = {
      top: rect.bottom,
      bottom: rect.top - popover.height - rect.height,
      center: rect.left + rect.width / 2 - popover.width / 2,
      left: rect.left
    }

    const positionY = rect.bottom + popover.height < height ? 'top' : 'bottom'
    const positionX = coords.center > 0 ? 'center' : 'left'

    setPosition([positionX, positionY])

    setFootnoteStyle({
      left: coords[positionX],
      top: coords[positionY]
    })
  }, [tooltipRef])

  const handleHover = () => {
    if (!!tooltip.target) {
      setPrevTooltip(tooltip)
    }
  }

  useEffect(() => {
    if (isFootnoteExists) {
      window.addEventListener('scroll', hide)
    }

    return () => {
      window.removeEventListener('scroll', hide)
    }
  }, [hide, isFootnoteExists])

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.active, isFootnoteExists]
      ])}
    >
      <div
        className={styles.container}
        style={footnoteStyle}
        onMouseEnter={(): void => handleHover()}
        onMouseLeave={(): void => hide()}
      >
        {isFootnoteExists && (
          <div className={styles.footnote} ref={popoverRef}>
            <Tooltip position={position}>
              <h3>Magic link</h3>
              <p>
                Это волшебная ссылка, ее&nbsp;можно отправить тому, у&nbsp;кого
                &laquo;Медуза&raquo; заблокирована&nbsp;&mdash; и&nbsp;все
                откроется!
              </p>
              <p>
                <a
                  href="https://meduza.io/cards/meduza-teper-ne-tolko-inoagent-no-esche-i-nezhelatelnaya-organizatsiya-opasno-li-takoe-izdanie-chitat"
                  target="_blank"
                  rel="noreferrer"
                >
                  Будьте осторожны
                </a>
                : &laquo;Медуза&raquo; в&nbsp;РФ&nbsp;&mdash;
                &laquo;нежелательная&raquo; организация. Не&nbsp;посылайте наши
                статьи людям, которым вы&nbsp;не&nbsp;доверяете.
              </p>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}
