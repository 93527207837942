// import loadable from '@loadable/component'
import { handleError } from '@utils/handleError'
import { Socket } from 'phoenix-elixir'
import { eventChannel } from 'redux-saga'
import { LiveResponse } from 'types/apiTypes'

// const { Socket } = loadable.lib(() => import('phoenix-elixir'))

interface ISocket {
  onOpen: (func: () => void) => void
  onClose: (func: () => void) => void
  onError: (func: () => void) => void
  connect: () => void
  disconnect: () => void
  channel: (channelUrl: string) => IChannel<LiveResponse>
}

type IResponse<T> = (payload: T) => void
type IError = ({ reason }: { reason: string }) => void

interface IChannel<T> {
  join: () => {
    receive: (
      message: string,
      arg2: IResponse<T>
    ) => {
      receive: (message: string, arg2: IError) => void
    }
  }
  on: (message: string, arg2: IResponse<T>) => void
  leave: () => void
}

let socket: ISocket
let channel: IChannel<LiveResponse>

/*
Создает инстанс сокета и канал, в который эмитит сообщения из инстанса сокета (его статусы)
*/

export function createWebSocketConnection(url: string) {
  socket = new Socket(
    process.env.NODE_ENV === 'development' ? `wss://meduza.io${url}` : url
  )

  socket.connect()

  return eventChannel((emit) => {
    socket.onOpen(() => emit({ message: 'open' }))
    socket.onError(() => emit({ message: 'fail' }))
    socket.onClose(() => emit({ message: 'close' }))

    return () => socket.disconnect()
  })
}

export function reconnect() {
  socket.connect()
}

/*
 Создает канал, в который эмитит сообщения из канала сокета (phoenix)
 */

export function createSocketChannel(channelUrl: string) {
  channel = socket.channel(channelUrl)

  return eventChannel((emit) => {
    channel
      .join()
      .receive('ok', (payload: LiveResponse) =>
        emit({
          message: 'ok',
          data: payload
        })
      )
      .receive('error', ({ reason }: { reason: string }) => {
        handleError(
          new Error('failed join socket channel'),
          'FAILED_JOIN_SOCKET_CHANNEL'
        )
        console.log('failed join channel', reason)
      })

    channel.on('create', (payload: LiveResponse) =>
      emit({
        message: 'create',
        data: payload
      })
    )
    channel.on('update', (payload: LiveResponse) =>
      emit({
        message: 'update',
        data: payload
      })
    )

    return () => {
      channel.leave()
    }
  })
}

export function closeConnection() {
  if (channel) {
    channel.leave()
  }

  if (socket) {
    socket.disconnect()
  }
}
