import { handleError } from '@utils/handleError'
import { eventChannel } from 'redux-saga'

export const createAudioChannel = (
  audio: HTMLAudioElement,
  episodeUrl: string
) => {
  return eventChannel((emit) => {
    const handlePlaying = () => {
      emit({ message: 'play', episodeUrl })
    }

    const handlePause = () => {
      emit({ message: 'pause', episodeUrl })
    }

    const handleTimeUpdate = () => {
      emit({ message: 'timeupdate', episodeUrl, time: audio.currentTime })
    }

    const handleEnd = () => {
      emit({ message: 'finish', episodeUrl })
    }

    const handleStalled = (e: Event) => {
      handleError(new Error('Audio playing stalled'), 'AUDIO_PLAYING_STALLED')
      emit({ message: 'stalled', episodeUrl, error: e })
    }

    const handlePlayingError = (e: Event) => {
      handleError(new Error('Audio playing error'), 'AUDIO_PLAYING_ERROR')
      emit({ message: 'error', episodeUrl, error: e })
    }

    const handleCanPlay = () => {
      audio.play()
    }

    audio.addEventListener('playing', handlePlaying)
    audio.addEventListener('pause', handlePause)
    audio.addEventListener('timeupdate', handleTimeUpdate)
    audio.addEventListener('ended', handleEnd)
    audio.addEventListener('error', handlePlayingError)
    audio.addEventListener('stalled', handleStalled)
    audio.addEventListener('canplay', handleCanPlay)

    return () => {
      audio.removeEventListener('playing', handlePlaying)
      audio.removeEventListener('pause', handlePause)
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      audio.removeEventListener('ended', handleEnd)
      audio.removeEventListener('error', handlePlayingError)
      audio.removeEventListener('stalled', handleStalled)
      audio.removeEventListener('canplay', handleCanPlay)
    }
  })
}
