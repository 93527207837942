import React from 'react'
import { Link } from 'react-router-dom'
import { DesktopBlock, ImageUrls, ScreenDocument } from '@client/types'

import { CollectionBlockItem } from './CollectionBlockItem'

import styles from './CollectionBlock.module.css'

interface CollectionBlockProps {
  correlation: keyof ImageUrls
  blocks: ScreenDocument[]
  meta: DesktopBlock['meta']
}

export const CollectionBlock: React.FC<CollectionBlockProps> = ({
  blocks,
  meta,
  correlation
}) => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.title}>{meta?.title}</h3>
        </header>
        <ul className={styles.list}>
          {blocks.map((d) => (
            <li key={d.url} className={styles.item}>
              <CollectionBlockItem block={d} correlation={correlation} />
            </li>
          ))}
        </ul>
      </div>
      {meta?.link && (
        <footer className={styles.footer}>
          <Link to={meta.link.path}>Full News Feed</Link>
        </footer>
      )}
    </section>
  )
}
