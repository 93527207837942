import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { Layout } from '@components/Layout'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { logout } from '@store/CurrentUser/currentUserActions'
import { showModal } from '@store/Modal/modalActions'

import { Button } from '@meduza/ui-kit-2'

import styles from './Account.module.css'

export const Account: React.FC<RouteConfigComponentProps> = ({
  location: { pathname },
  route
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { firebase_user, data } = useSelector(getCurrentUser)

  useEffect(() => {
    if (!firebase_user) {
      history.push('/')
    }
  }, [history, firebase_user])

  const handleDeleteUser = () => {
    dispatch(
      showModal({
        type: 'deleteUser'
      })
    )
  }

  const handleLogoutClick = () => {
    dispatch(logout.request())
    history.push('/')
  }

  if (!firebase_user) {
    return <div />
  }

  const { email } = firebase_user

  return (
    <Layout pathname={pathname} routeName={route && route.name} type="account">
      <Helmet titleTemplate="%s">
        <title>Личный кабинет</title>
      </Helmet>
      <div className={styles.root}>
        <section className={styles.container}>
          <header className={styles.header}>
            <h2 className={styles.title}>{email}</h2>
          </header>

          {data && (
            <div className={styles.block}>
              <div className={styles.buttons}>
                <div className={styles.button}>
                  <Button onClick={handleLogoutClick} theme="gray">
                    Выйти
                  </Button>
                </div>
                <div className={styles.button}>
                  <Button onClick={handleDeleteUser} theme="red">
                    Удалить профиль
                  </Button>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Layout>
  )
}

Account.whyDidYouRender = true
