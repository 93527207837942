import { useCallback, useEffect, useState } from 'react'

interface UseTopbarScrollProps {
  ref: React.RefObject<HTMLDivElement>
  containerRef?: React.RefObject<HTMLDivElement>
}

export const useTopbarScroll = ({
  ref,
  containerRef
}: UseTopbarScrollProps) => {
  const [visible, setVisible] = useState(false)

  const handleScroll = useCallback(() => {
    if (!ref?.current) return

    const elRect = ref.current.getBoundingClientRect()
    const elTopOffset = elRect.top + window.scrollY
    const scrollTop = window.scrollY + 1

    if (
      containerRef?.current &&
      scrollTop > containerRef.current.offsetHeight
    ) {
      setVisible(false)
      return
    }

    setVisible(scrollTop > elTopOffset)
  }, [ref, containerRef])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return visible
}
