import React from 'react'
import { useDispatch } from 'react-redux'
import { callToAction } from '@client/store/App/appActions'
import { CallToActions } from '@client/types'
import { makeClassName } from '@client/utils/makeClassName'

import {
  IconCopy,
  IconEmail,
  IconFacebook,
  IconLinkedin,
  IconReddit,
  IconX
} from './SharePanelIcons'

import styles from './SharePanel.module.css'

interface SharePanelProps {
  place: string | undefined
  theme?: string
}

const ShareButton: React.FC<{
  icon: React.JSX.Element
  handleClick: (service: CallToActions) => void
  service: CallToActions
}> = ({ icon, handleClick, service }) => {
  return (
    <button
      className={styles.button}
      type="button"
      onClick={() => handleClick(service)}
    >
      {icon}
    </button>
  )
}

const SHARE_BUTTONS: { icon: React.JSX.Element; service: CallToActions }[] = [
  { icon: <IconX />, service: 'tw' },
  { icon: <IconFacebook />, service: 'fb' },
  { icon: <IconLinkedin />, service: 'linkedin' },
  { icon: <IconReddit />, service: 'reddit' },
  { icon: <IconEmail />, service: 'email' },
  { icon: <IconCopy />, service: 'copy' }
]

export const SharePanel: React.FC<SharePanelProps> = ({ place, theme }) => {
  const dispatch = useDispatch()

  const handleClick = (service: CallToActions) => {
    dispatch(
      callToAction({
        place,
        service
      })
    )
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles[theme], true]
      ])}
    >
      {SHARE_BUTTONS.map((button) => (
        <ShareButton
          icon={button.icon}
          service={button.service}
          key={button.service + place}
          handleClick={handleClick}
        />
      ))}
    </div>
  )
}
