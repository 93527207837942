import { useContext, useMemo } from 'react'
import { AlertContext } from '@common/Alert/types'

import { Context } from './Context'

/*
 * Хук для алертов
 */

export const useAlert = (): AlertContext => {
  const alertContext = useContext(Context) as AlertContext
  return useMemo(() => alertContext, [alertContext])
}

export default useAlert
