import React from 'react'
import { Lang, ScreenDocument } from '@client/types'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { Link } from '@common/Link'
import { PodcastCover } from '@common/PodcastCover'
import { makeClassName } from '@utils/makeClassName'

import { Meta, MetaItem, Tag, Timestamp } from '@meduza/ui-kit-2'

import styles from './ChronologyItem.module.css'

export interface ChronologyItemProps {
  document: ScreenDocument
  styleContext?: string
  lang?: Lang
  handleItemClick?: () => void
}

export const ChronologyItem: React.FC<ChronologyItemProps> = ({
  document,
  styleContext,
  lang,
  handleItemClick
}) => {
  const { url, title, second_title, tag, layout, datetime, image, footer } =
    document

  const classNames = [
    [styles.root, true],
    [styles[layout], !!layout && !!styles[layout]]
  ]

  if (styleContext) {
    classNames.push([
      styles[styleContext],
      !!styleContext && !!styles[styleContext]
    ])
  }

  const body = (
    <div className={makeClassName(classNames)}>
      {image && (
        <figure className={styles.figure}>
          {layout === 'podcast' ? (
            <div className={styles.cover}>
              <PodcastCover
                image={image.base_urls && image.base_urls.is1to2}
                styleContext="isInChronology"
              />
            </div>
          ) : (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${
                  image.base_urls && image.base_urls.is1to2
                })`
              }}
            />
          )}
        </figure>
      )}
      <div className={styles.body}>
        <div className={styles.header}>
          {tag && tag.name !== 'news' && layout !== 'brief' && (
            <div className={styles.tag}>
              <Tag size="small" theme="inherit">
                {tag.name}
              </Tag>
            </div>
          )}
          {layout === 'brief' && (
            <div className={styles.icon}>
              <img
                src="https://meduza.io/image/attachments/images/002/100/677/original/xABM3du_ea-RbspQsDyIjQ.png"
                width="30"
                height="30"
                alt=""
              />
            </div>
          )}
          <h3>
            <strong>{title}</strong>
            {second_title && <span> {second_title}</span>}
          </h3>
        </div>
        <div className={styles.footer}>
          <Meta>
            <MetaItem>
              <Timestamp publishedAt={datetime} type="fromNow" locale={lang} />
            </MetaItem>
          </Meta>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.block}>
      {footer && footer.action_url ? (
        <a
          className={styles.link}
          href={footer.action_url}
          onClick={handleItemClick}
        >
          {body}
        </a>
      ) : (
        <Link className={styles.link} to={`/${url}`} onClick={handleItemClick}>
          {body}
        </Link>
      )}
      {layout !== 'simple' && (
        <div className={styles.bookmark}>
          <BookmarkIcon url={url} />
        </div>
      )}
    </div>
  )
}
