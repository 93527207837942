import { HistoryElement } from '@client/types'
// import { User as FirebaseUser } from 'firebase/auth'
import { eventChannel } from 'redux-saga'
import { FirebaseFunctionsData } from 'types/storeTypes'

/*
Вызов firebase functions
*/

export function* callFunctions(
  endpoint: string,
  data?: FirebaseFunctionsData
): unknown {
  const firebase = window.firebaseApp
  const functions = firebase.functions()

  const response = yield functions.httpsCallable(endpoint)(data)
  return response.data
}

export function* loginGoogle(): unknown {
  const firebase = window.firebaseApp
  const googleAuthProvider = new window.firebase.auth.GoogleAuthProvider()
  return yield firebase.auth().signInWithPopup(googleAuthProvider)
}

export function* sendEmail(
  email: string,
  actionCodeSettings: { url: string; handleCodeInApp: boolean }
): unknown {
  const firebase = window.firebaseApp
  return yield firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
}

export function* logout(): unknown {
  const firebase = window.firebaseApp
  return yield firebase.auth().signOut()
}

export function* toggleBookmark(
  uid: string,
  bookmarks: Record<string, HistoryElement>,
  articleKey: string
) {
  const firebase = window.firebase
  const db = window.firebaseApp.firestore()

  const isInBookmarks = bookmarks && bookmarks[articleKey]

  const bookmarksShelf = db
    .collection('users')
    .doc(uid)
    .collection('doc')
    .doc('bookmarks')

  if (!isInBookmarks) {
    yield bookmarksShelf.set(
      {
        [articleKey]: firebase.firestore.Timestamp.fromDate(new Date())
      },
      { merge: true }
    )
  } else {
    yield bookmarksShelf.set(
      {
        [articleKey]: firebase.firestore.FieldValue.delete()
      },
      { merge: true }
    )
  }
}

export function* markMaterialRead(uid: string, articleKey: string) {
  const firebase = window.firebase
  const db = window.firebaseApp.firestore()

  const historyShelf = db
    .collection('users')
    .doc(uid)
    .collection('doc')
    .doc('history')

  yield historyShelf.set(
    {
      [articleKey]: firebase.firestore.Timestamp.fromDate(new Date())
    },
    { merge: true }
  )
}

export function* episodeTimeUpdate(
  uid: string,
  episodeUrl: string,
  progress: number
) {
  const firebase = window.firebaseApp
  const db = firebase.firestore()

  yield db
    .collection('users')
    .doc(uid)
    .collection('doc')
    .doc('episode_history_shelf')
    .set(
      {
        // TODO: костыль для приложения
        [episodeUrl]: progress === 1 ? 1.01 : progress
      },
      { merge: true }
    )
}

// TODO: FIREBASE
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createFirestoreSubscriber(user: any, collection: string[]) {
  const firebase = window.firebaseApp
  const db = firebase.firestore()

  return eventChannel((emit) => {
    const unsubscribers: (() => void)[] = []

    collection.forEach((element) => {
      const unsubscribe = db
        .collection('users')
        .doc(user.uid)
        .collection('doc')
        .doc(element)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .onSnapshot((item: any) => {
          const data = item.data()
          emit({ message: element, data })
        })

      unsubscribers.push(unsubscribe)
    })

    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe()
      })
    }
  })
}
