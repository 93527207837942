export enum ALERT_TEXTS {
  ON_USER_DELETE = 'Ваш профиль удален',
  ON_ERROR_SENT = 'Опечатка отправлена. Спасибо!',
  ON_PLAYER_ERROR = 'Ошибка воспроизведения',
  ON_LIVE_ERROR = 'Ошибка подключения, перезагрузите страницу',
  ON_SUBSCRIPTION_UPDATE = 'Подписка обновлена',
  ON_SUBSCRIPTION_DELETE = 'Подписка удалена',
  ON_MESSAGE_SENT = 'Сообщение отправлено',
  ON_DAILY_SUBSCRIBE = 'Вам отправлен email',
  ON_CARD_CHANGE = 'Карта изменена',
  ON_ERROR = 'Произошла ошибка. Попробуйте еще раз'
}

export const POSITIONS: Record<string, string> = {
  TOP_LEFT: 'top left',
  TOP_CENTER: 'top center',
  TOP_RIGHT: 'top right',
  MIDDLE_LEFT: 'middle left',
  MIDDLE: 'middle',
  MIDDLE_RIGHT: 'middle right',
  BOTTOM_LEFT: 'bottom left',
  BOTTOM_CENTER: 'bottom center',
  BOTTOM_RIGHT: 'bottom right'
}

export enum TYPES {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum TRANSITIONS {
  FADE = 'fade',
  SCALE = 'scale',
  FADE_IN = 'fadeIn'
}
