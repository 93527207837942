import React from 'react'
import { useSelector } from 'react-redux'
import { SlidesMaterialContent } from '@client/types'
import { useTrackScroll } from '@hooks/useTrackScroll'
import { getCurrentUser } from '@selectors/currentUserSelectors'

import { Slide } from '../MaterialComponents/Slide'

import styles from './SlidesMaterial.module.css'

export interface SlidesMaterialProps {
  material: SlidesMaterialContent
}

export const SlidesMaterial: React.FC<SlidesMaterialProps> = ({
  material: {
    content: { slides },
    url,
    container
  }
}) => {
  const { bookmarks } = useSelector(getCurrentUser)

  const { ref } = useTrackScroll(url)

  const isInBookmarks = bookmarks && !!bookmarks[url]

  const bgColor =
    (container.background && container.background.color) ||
    (slides && slides[0].bg_color)

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.bg} style={{ backgroundColor: bgColor }} />
      {slides && (
        <div className={styles.slides}>
          {slides.map((slide, i) => (
            <Slide
              url={url}
              key={`slide${i}`}
              blocks={slide.blocks}
              isInBookmarks={isInBookmarks}
              index={i}
            />
          ))}
        </div>
      )}
    </div>
  )
}
