import { useContext, useMemo } from 'react'
import { LightboxContext } from '@common/Lightbox/types'

import DefaultContext from './Context'

/*
 * Хук для лайтбокса - увеличения изображений по клику
 */

const useLightbox = (): LightboxContext | null => {
  const alertContext = useContext(DefaultContext)
  return useMemo(() => alertContext, [alertContext])
}

export default useLightbox
