const PREFIX = `MDZ_`
// TODO: начать использовать это
function set<T = Record<string, unknown>>(key: string, value: T): void {
  if (typeof localStorage === 'undefined') {
    return
  }

  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(PREFIX + key, serializedValue)
  } catch (error) {
    throw new Error('store serialization failed')
  }
}

function get<T = Record<string, unknown>>(key: string): T | undefined {
  if (typeof localStorage === 'undefined') {
    return null
  }

  try {
    const serializedValue = localStorage.getItem(PREFIX + key)
    if (serializedValue == null) {
      return null
    }
    return JSON.parse(serializedValue)
  } catch (error) {
    throw new Error('store deserialization failed')
  }
}

export const localStorageService = {
  set,
  get
}
