import React from 'react'
import { ImageUrls, ScreenDocument } from '@client/types'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import { Tag } from '@meduza/ui-kit-2'

import styles from './CollectionBlockItem.module.css'

interface CollectionBlockItemProps {
  block: ScreenDocument
  correlation: keyof ImageUrls
  featured?: boolean
}

export const CollectionBlockItem: React.FC<CollectionBlockItemProps> = ({
  block,
  correlation,
  featured
}) => {
  const { layout, tag, footer, url } = block

  const BlockTitleStyleContext = [`isIn${capitalize(layout)}`]

  BlockTitleStyleContext.push(
    featured ? 'isInTopicBlockItemFeatured' : 'isInTopicBlockItem'
  )

  if (correlation === 'is1to2') {
    BlockTitleStyleContext.push('is1to3')
  }

  const shouldRenderBookmark = layout !== 'simple'

  return (
    <article
      className={makeClassName([
        [styles.root, true],
        [styles.featured, !!featured]
      ])}
    >
      <header className={styles.header}>
        {tag && (
          <div className={styles.tag}>
            <Tag size="small" theme="inherit">
              {tag.name}
            </Tag>
          </div>
        )}
        <BlockTitle block={block} styleContext={BlockTitleStyleContext} />
      </header>
      <footer className={styles.footer}>
        <BlockMetaContainer block={block} />
        {shouldRenderBookmark && (
          <div className={styles.bookmark}>
            <BookmarkIcon theme="light" url={url} styleContext="isInGrid" />
          </div>
        )}
      </footer>
      {footer && footer.is_external && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content,react/jsx-no-target-blank
        <a
          className={styles.external}
          href={footer.action_url}
          target="_blank"
        />
      )}
    </article>
  )
}
