import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REQUEST_STATUS } from '@client/enums'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import { FormInput, FormItem, FormMessage, FormTextarea } from '@common/Form'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import {
  getFeedbackToken,
  resetStatuses,
  sendReaction
} from '@store/CurrentUser/currentUserActions'
import { hideModal } from '@store/Modal/modalActions'
import { validateEmail } from '@utils/validateEmail'

import { Button } from '@meduza/ui-kit-2'

import styles from './Modal.module.css'

export const ReactionModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { show: showAlert } = useAlert()

  const {
    feedback_token,
    last_opened_material,
    sendReactionStatus
  } = useSelector(getCurrentUser)

  useEffect(() => {
    if (!feedback_token) {
      dispatch(resetStatuses())
      dispatch(getFeedbackToken.request())
    }
  }, [dispatch, feedback_token])

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({ email: false, message: false })

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setErrors({ ...errors, email: false })
  }

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    setErrors({ ...errors, message: false })
  }

  const handleSendReaction = () => {
    if (!validateEmail(email)) {
      setErrors({ ...errors, email: true })
    } else if (message.length === 0) {
      setErrors({ ...errors, message: true })
    } else {
      if (feedback_token) {
        dispatch(
          sendReaction.request({
            email,
            text: message,
            key: history.location.pathname.slice(1),
            csrfToken: feedback_token,
            tag: last_opened_material ? last_opened_material.url : 'news',
            type: 'idea'
          })
        )
      } else {
        showAlert(ALERT_TEXTS.ON_ERROR, {
          type: TYPES.ERROR
        })
        dispatch(hideModal())
      }
    }
  }

  useEffect(() => {
    if (sendReactionStatus === REQUEST_STATUS.SUCCESS) {
      showAlert(ALERT_TEXTS.ON_MESSAGE_SENT, {
        type: TYPES.INFO
      })
      dispatch(hideModal())
    } else if (sendReactionStatus === REQUEST_STATUS.FAILURE) {
      showAlert(ALERT_TEXTS.ON_ERROR, {
        type: TYPES.ERROR
      })
      dispatch(hideModal())
    }
  }, [showAlert, dispatch, sendReactionStatus])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Хотите связаться с&nbsp;нами? Вот самый быстрый способ</h3>
        <p>
          Предложите тему. Расскажите, все&nbsp;ли хорошо работает. Поделитесь
          с&nbsp;редакцией любой идеей. Напишите нам!
        </p>
      </div>
      <div className={styles.form}>
        <FormItem>
          <label className={styles.label} htmlFor="contacts">
            Как с вами связаться?
          </label>
          <FormInput
            placeholder="Адрес электронной почты"
            size="medium"
            value={email}
            invalid={errors.email}
            onChange={handleEmailChange}
          />
        </FormItem>
        <FormItem>
          <label className={styles.label} htmlFor="textarea">
            О чем хотите рассказать?
          </label>
          <FormTextarea
            placeholder="Введите сообщение&hellip;"
            styleContext="isInReaction"
            value={message}
            onChange={handleMessageChange}
            id="textarea"
          />
          {errors.message && (
            <FormMessage type="invalid">Введите сообщение</FormMessage>
          )}
        </FormItem>
        <div className={styles.submit}>
          <Button
            theme="gold"
            onClick={handleSendReaction}
            state={
              sendReactionStatus === REQUEST_STATUS.LOADING
                ? 'isLoading'
                : 'isDefault'
            }
          >
            Отправить
          </Button>
        </div>
      </div>
    </div>
  )
}
