import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from '@common/Link'
import { ViewSwitcherContainer } from '@common/ViewSwitcherContainer/ViewSwitcherContainer'
import { getIsMobile } from '@selectors/appSelectors'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { getModal } from '@selectors/modalSelectors'
import { logout } from '@store/CurrentUser/currentUserActions'
import { hideModal, showModal } from '@store/Modal/modalActions'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'

import i18n from './i18n'
import { Logo } from './Logo'
import { ProfileMobile } from './ProfileMobile'
import scheme from './scheme.json'
import { UserDropdown } from './UserDropdown'

import styles from './Header.module.css'

interface MenuData {
  switcher: boolean
  slogan?: string
  primary: {
    link: string
    title: string
    external?: boolean
    mute: boolean
  }[]
  secondary?: {
    link: string
    title: string
  }[]
}

export const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)

  const {
    location: { pathname }
  } = history

  const { type: modalType } = useSelector(getModal)
  const { lang, firebase_user } = useSelector(getCurrentUser)

  const [navOpened, setNavOpened] = useState(false)

  const searchModalActive = modalType === 'search'

  useEffect(() => {
    document.body.dataset.nav = navOpened.toString()
  }, [navOpened])

  const handleToggleNavClick = () => {
    if (searchModalActive) {
      dispatch(hideModal())
    }

    if (navOpened) {
      setNavOpened(false)
    } else {
      setNavOpened(true)
    }
  }

  const handleCloseNavClick = () => {
    setNavOpened(false)
  }

  const handleSearchClick = () => {
    setNavOpened(false)

    if (searchModalActive) {
      dispatch(hideModal())
    } else {
      window.scrollTo(0, 0)
      dispatch(
        showModal({
          type: 'search'
        })
      )
    }
  }

  const handleAuthClick = () => {
    setNavOpened(false)

    window.scrollTo(0, 0)
    dispatch(
      showModal({
        type: 'loginHeader',
        coordinates: 0
      })
    )
  }

  const handleLogoutClick = () => {
    if (pathname === '/account' || pathname === '/bookmarks') {
      history.replace('/')
    }
    dispatch(logout.request())
  }

  const handleMessageClick = () => {
    setNavOpened(false)

    window.scrollTo(0, 0)
    dispatch(
      showModal({
        type: 'sendMessage'
      })
    )
  }

  const data: MenuData = scheme[lang]

  return (
    <header
      className={makeClassName([
        [styles.root, true],
        [styles.isNavOpened, navOpened],
        [styles.isSearchOpened, searchModalActive],
        [styles[lang], !!styles[lang]]
      ])}
      role="banner"
    >
      <Logo lang={lang} />
      <div className={styles.menuToggle}>
        <button
          onClick={handleToggleNavClick}
          className={styles.item}
          type="button"
          aria-label={i18n[lang].menu}
        >
          <SvgSymbol
            icon={navOpened ? 'cross' : 'menu'}
            size="unset"
            styleContext="isInHeader"
          />
        </button>
      </div>
      <div className={styles.nav}>
        <nav className={styles.menu}>
          {data.primary.map((item) => (
            <span
              className={makeClassName([
                [styles.item, true],
                [styles.isMute, item.mute],
                [styles.isItemActive, item.link === pathname]
              ])}
              key={item.link}
            >
              {item.external ? (
                <a
                  href={item.link}
                  key={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              ) : (
                <Link to={item.link} onClick={handleCloseNavClick}>
                  {item.title}
                </Link>
              )}
            </span>
          ))}
        </nav>

        {isMobile && (
          <div className={styles.mobileNav}>
            {lang === 'ru' && (
              <div className={styles.profile}>
                <ProfileMobile
                  firebaseUser={firebase_user}
                  lang={lang}
                  handleAuthClick={handleAuthClick}
                  handleMessageClick={handleMessageClick}
                  handleCloseNavClick={handleCloseNavClick}
                />
              </div>
            )}

            {data.secondary && (
              <nav className={styles.menuAdditional}>
                {data.secondary.map((item) => (
                  <a
                    className={makeClassName([
                      [styles.menuAdditionalItem, true],
                      [styles.isMenuItemActive, item.link === pathname]
                    ])}
                    href={item.link}
                    onClick={handleCloseNavClick}
                    key={item.link}
                  >
                    {item.title}
                  </a>
                ))}
              </nav>
            )}

            {data.switcher && (
              <div className={styles.switcher}>
                <ViewSwitcherContainer size="md" label="Режим по порядку" />
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        {lang === 'en' && (
          <Link
            to="/en/latest/news"
            className={makeClassName([
              [styles.item, true],
              [styles.itemFeed, true]
            ])}
          >
            News Feed
          </Link>
        )}

        {!isMobile && lang === 'ru' && (
          <div
            className={makeClassName([
              [styles.item, true],
              [styles.itemToggle, true]
            ])}
          >
            <ViewSwitcherContainer size="sm" theme="dark" />
          </div>
        )}

        <button
          className={makeClassName([
            [styles.item, true],
            [styles.itemSearch, true]
          ])}
          onClick={handleSearchClick}
          type="button"
          aria-label={i18n[lang].search}
        >
          {!searchModalActive && (
            <SvgSymbol icon="search" size="unset" styleContext="isInHeader" />
          )}
          {searchModalActive && (
            <SvgSymbol icon="cross" size="unset" styleContext="isInHeader" />
          )}
        </button>

        <a
          href={lang === 'ru' ? '/en' : '/'}
          className={makeClassName([
            [styles.item, true],
            [styles.itemLang, true]
          ])}
        >
          {i18n[lang].changeLang}
        </a>

        {lang === 'ru' && (
          <UserDropdown
            handleAuthClick={handleAuthClick}
            lang={lang}
            handleLogoutClick={handleLogoutClick}
            handleCloseNavClick={handleCloseNavClick}
            handleMessageClick={handleMessageClick}
            firebaseUser={firebase_user}
          />
        )}
      </div>
    </header>
  )
}
