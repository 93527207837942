import React from 'react'
import { ImageUrls, SimpleScreenDocument } from '@client/types'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import styles from './SimpleBlock.module.css'

interface SimpleBlockProps {
  block: SimpleScreenDocument
  styleContext?: string
  correlation: keyof ImageUrls
}

export const SimpleBlock: React.FC<SimpleBlockProps> = ({
  block,
  styleContext,
  correlation
}) => (
  <article
    className={makeClassName([
      [styles.root, true],
      [styles[correlation], !!correlation && !!styles[correlation]],
      [
        !!styleContext && styles[styleContext],
        !!styleContext && !!styles[styleContext]
      ]
    ])}
  >
    <div className={styles.wrap}>
      <div className={styles.content}>
        <BlockTitle
          block={block}
          styleContext={`isIn${capitalize(block.layout)}`}
          correlation={correlation}
        />
      </div>
      <div className={styles.footer}>
        <BlockMetaContainer block={block} />
      </div>
    </div>
  </article>
)
