/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ImageUrls } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import { locale } from './locale'

import styles from './DailyBlock.module.css'

interface BriefSubscriptionProps {
  correlation?: keyof ImageUrls
  handleSubscribeToDaily: any
  daily: any
  email: any
  handleInput: any
  error: any
}

export const DailyBlock: React.FC<BriefSubscriptionProps> = ({
  correlation,
  handleSubscribeToDaily,
  daily,
  email,
  handleInput,
  error
}) => {
  const {
    action,
    label,
    title,
    second_title,
    success_message,
    wrong_email_message,
    something_wrong
  } = locale

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [
          !!correlation && styles[correlation],
          !!correlation && !!styles[correlation]
        ]
      ])}
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <div className={styles.text}>
            <p dangerouslySetInnerHTML={{ __html: second_title }} />
          </div>
          <form className={styles.subscribe} onSubmit={handleSubscribeToDaily}>
            {!daily.subscribed && (
              <div className={styles.form}>
                <div
                  className={makeClassName([
                    [[styles.field], true],
                    [styles.invalid, (error || daily.error) && !!styles.invalid]
                  ])}
                >
                  <label htmlFor="brief-email">
                    <span>{label}</span>
                  </label>
                  <input
                    className={styles.input}
                    type="email"
                    defaultValue={email}
                    onInput={handleInput}
                    id="brief-email"
                    placeholder={label}
                  />
                  {error && (
                    <span
                      className={styles.message}
                      dangerouslySetInnerHTML={{
                        __html: wrong_email_message
                      }}
                    />
                  )}

                  {daily.error && (
                    <span
                      className={styles.message}
                      dangerouslySetInnerHTML={{
                        __html: something_wrong
                      }}
                    />
                  )}
                </div>

                <button
                  className={styles.button}
                  type="submit"
                  aria-label={action}
                >
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="#FFF" fillRule="nonzero">
                      <path d="M12.68 5.9L8.31 1.53A.75.75 0 0 1 9.37.47l4.9 4.9a.75.75 0 0 1 0 1.06l-4.9 4.9a.75.75 0 0 1-1.06-1.06l4.37-4.37z" />
                      <path d="M13.74 5.15H1a.75.75 0 0 0 0 1.5h12.74a.75.75 0 1 0 0-1.5z" />
                    </g>
                  </svg>
                </button>
              </div>
            )}

            {!daily.subscribed && (!error || daily.error) && (
              <div className={styles.description}>
                <p dangerouslySetInnerHTML={{ __html: locale.captcha }} />
              </div>
            )}

            {daily.subscribed && (
              <div
                className={styles.success}
                dangerouslySetInnerHTML={{ __html: success_message }}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
