import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './FormMessage.module.css'

interface FormMessageProps {
  type: 'valid' | 'invalid' | 'tip'
  styleContext?: string
  children: React.ReactNode
}

export const FormMessage: React.FC<FormMessageProps> = ({
  type,
  children,
  styleContext
}) => (
  <div
    className={makeClassName([
      [[styles.root], true],
      [[styles[type]], !!type],
      [!!styleContext && styles[styleContext], !!styleContext]
    ])}
  >
    {children}
  </div>
)
