import { pluralize } from '@utils/pluralize'

export const i18n = {
  ru: {
    broadcast: 'перевернуть трансляцию',
    new: 'Новое',
    message: (length: number): string =>
      pluralize(length, 'новое сообщение', 'новых сообщения', 'новых сообщений')
  },

  en: {
    broadcast: 'flip broadcast',
    new: 'New',
    message: (length: number): string =>
      pluralize(length, 'new message', 'new messages', 'new messages')
  }
}
