import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ImageUrls, RichMediaScreenDocument } from '@client/types'
import { useBannerTrack } from '@hooks/useBannerTrack'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { getLang } from '@selectors/currentUserSelectors'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import { Tag } from '@meduza/ui-kit-2'

import { PictureElement } from './PictureElement'
import { VideoElement } from './VideoElement'

import styles from './MediaBlock.module.css'

interface MediaBlockProps {
  correlation: keyof ImageUrls
  block: RichMediaScreenDocument
  origin: string
}

export const MediaBlock: React.FC<MediaBlockProps> = ({
  correlation,
  block,
  origin
}) => {
  const [expanded, setExpanded] = useState(false)
  const bannerTrackRef = useBannerTrack(block, origin)
  const lang = useSelector(getLang)

  const handleExpandClick = () => {
    setExpanded(true)
  }

  const { layout, mobile_layout: mobileLayout, tag, video, picture, cc } = block

  return (
    <article
      ref={bannerTrackRef}
      className={makeClassName([
        [styles.root, true],
        [styles.isPicture, !!picture && !!styles.isPicture]
      ])}
    >
      <div className={styles.content}>
        <div className={styles.tag}>
          {tag && (
            <Tag size="small" theme="inherit">
              {tag.name}
            </Tag>
          )}
        </div>
        <div className={styles.title}>
          <BlockTitle
            block={block}
            correlation={correlation}
            styleContext={`isIn${capitalize(layout)}`}
          />
        </div>
        <div className={styles.meta}>
          <BlockMetaContainer block={block} />
        </div>
      </div>
      {video && (
        <div className={styles.video}>
          <VideoElement video={video} />
        </div>
      )}

      {picture && (
        <div className={styles.picture}>
          <PictureElement picture={picture} layout={mobileLayout} />
        </div>
      )}

      {cc && (
        <div className={styles.cc}>
          {cc.caption && (
            <div
              className={makeClassName([
                [styles.caption, true],
                [styles.expanded, expanded]
              ])}
            >
              <div
                className={styles.captionShort}
                onClick={handleExpandClick}
                onKeyPress={handleExpandClick}
                role="button"
                tabIndex={0}
              >
                {cc.caption_short}&nbsp;
                <span className={styles.control}>
                  {lang === 'ru' ? 'Читать дальше' : 'Read more'}
                </span>
              </div>

              <div
                className={styles.captionExpanded}
                dangerouslySetInnerHTML={{ __html: cc.caption }}
              />
            </div>
          )}

          {cc.credit && (
            <div
              className={styles.credit}
              dangerouslySetInnerHTML={{ __html: cc.credit }}
            />
          )}
        </div>
      )}
    </article>
  )
}
