import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ScreenPicture } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './PictureElement.module.css'

interface PictureElementProps {
  picture: ScreenPicture
  layout: string
}

export const PictureElement: React.FC<PictureElementProps> = ({
  picture: {
    display,
    width,
    height,
    optimised_urls,
    mobile_url,
    mobile_retina_url,
    base_urls
  },
  layout
}) => {
  const ratio = width / height
  const paddingBottom = `${100 / ratio}%`

  const [ref, inView, entry] = useInView({
    threshold: 0.25,
    triggerOnce: true
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  return (
    <figure
      className={makeClassName([
        [styles.root, true],
        [styles[display], !!display && !!styles[display]],
        [styles[layout], !!layout && !!styles[layout]]
      ])}
      ref={ref}
    >
      <picture style={{ paddingBottom }}>
        {visible && (
          <>
            {optimised_urls && (
              <source
                type="image/webp"
                media="(max-width: 1023px)"
                srcSet={`
              ${optimised_urls.mobile_url}`}
              />
            )}

            {mobile_url && (
              <source
                media="(max-width: 1023px)"
                srcSet={`
              ${mobile_url} 1x,
              ${mobile_retina_url} 2x `}
              />
            )}

            {base_urls && (
              <source
                media="(max-width: 1023px)"
                srcSet={`
              ${optimised_urls.mobile_url}`}
              />
            )}
            <source
              media="(min-width: 1024px)"
              srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
            />
            <img src={mobile_url} alt="" />
          </>
        )}
      </picture>
    </figure>
  )
}
