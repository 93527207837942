import React from 'react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ScreenImage } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './ImageElement.module.css'

/*
 * Элемент изображения для блоков экрана
 */

interface PictureElementProps {
  image: ScreenImage
  layout: string
}

export const ImageElement: React.FC<PictureElementProps> = ({
  image: { display, mobile_ratio, width, height, optimised_urls, base_urls },
  layout
}) => {
  const ratio = mobile_ratio || width / height
  const paddingBottom = `${100 / ratio}%`

  const [ref, inView, entry] = useInView({
    threshold: 0.25,
    triggerOnce: true
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  return (
    <figure
      className={makeClassName([
        [styles.root, true],
        [!!display && styles[display], !!display && !!styles[display]],
        [styles[layout], !!layout && !!styles[layout]]
      ])}
      ref={ref}
    >
      <picture style={{ paddingBottom }}>
        {visible && (
          <>
            {optimised_urls && (
              <source
                type="image/webp"
                media="(max-width: 1023px)"
                srcSet={`
              ${optimised_urls.mobile_url || optimised_urls.isMobile}`}
              />
            )}

            {base_urls && (
              <source
                media="(max-width: 1023px)"
                srcSet={`
              ${optimised_urls.mobile_url || base_urls.isMobile}`}
              />
            )}
            <source
              media="(min-width: 1024px)"
              srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
            />

            <img src={base_urls.isMobile} alt="" />
          </>
        )}
      </picture>
    </figure>
  )
}
