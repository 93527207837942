import analyticsSaga from '@store/Analytics/analyticsSaga'
import appSaga from '@store/App/appSaga'
import audioPlayerSaga from '@store/AudioPlayer/audioPlayerSaga'
import bookmarksSaga from '@store/Bookmarks/bookmarksSaga'
import currentUserSaga from '@store/CurrentUser/currentUserSaga'
import materialsSaga from '@store/Materials/materialsSaga'
import screensSaga from '@store/Screens/screensSaga'
import searchSaga from '@store/Search/searchSaga'
import { all } from 'redux-saga/effects'

export default function* rootSaga() {
  yield all([
    appSaga(),
    audioPlayerSaga(),
    analyticsSaga(),
    bookmarksSaga(),
    currentUserSaga(),
    materialsSaga(),
    screensSaga(),
    searchSaga()
  ])
}
