import React from 'react'
import { BriefDocument, ImageUrls } from '@client/types'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { makeClassName } from '@utils/makeClassName'

import styles from './BriefBlock.module.css'

interface BriefBlockProps {
  block: BriefDocument
  correlation: keyof ImageUrls
}

export const BriefBlock: React.FC<BriefBlockProps> = ({
  block,
  correlation
}) => (
  <div
    className={makeClassName([
      [[styles.root], true],
      [styles[correlation], !!correlation && !!styles[correlation]]
    ])}
  >
    <div className={styles.content}>
      <BlockTitle
        block={block}
        styleContext="isInBrief"
        correlation={correlation}
      />
    </div>
    <div className={styles.footer}>
      <BlockMetaContainer block={block} />
    </div>
  </div>
)
