import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBookmarks,
  getFirebaseUser,
  getLang
} from '@selectors/currentUserSelectors'
import { toggleBookmark } from '@store/CurrentUser/currentUserActions'
import { showModal } from '@store/Modal/modalActions'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'

import styles from './BookmarkIcon.module.css'

/*
 * Иконка закладки для материала (в блоках экранов использована кнопка из кита)
 */

interface BookmarkIconProps {
  styleContext?: string
  theme?: string
  url: string | null
}

export const BookmarkIcon: React.FC<BookmarkIconProps> = ({
  styleContext,
  theme,
  url
}) => {
  const dispatch = useDispatch()

  const firebaseUser = useSelector(getFirebaseUser)
  const bookmarks = useSelector(getBookmarks)
  const lang = useSelector(getLang)

  if (lang === 'en' || !url) {
    return null
  }

  const handleAuth = () => {
    localStorage.setItem('saved_bookmark', url)

    dispatch(
      showModal({
        type: 'loginBookmark',
        coordinates: window.scrollY
      })
    )
  }

  const handleClick = () => {
    if (firebaseUser) {
      dispatch(toggleBookmark(url))
    } else {
      handleAuth()
    }
  }

  const isInBookmarks = bookmarks && bookmarks[url]
  const label = isInBookmarks ? 'Убрать из закладок' : 'Добавить в закладки'

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.isInBookmarks, !!isInBookmarks],
        [!!styleContext && styles[styleContext], !!styleContext],
        [!!theme && styles[theme], !!theme]
      ])}
    >
      <button
        className={styles.control}
        onClick={handleClick}
        type="button"
        aria-label={label}
      >
        <SvgSymbol icon="bookmark" size="unset" />
      </button>
    </div>
  )
}
