import { Lang, ScreenNames } from '@client/types'
import { createAsyncAction } from '@utils/createAsyncAction'
import {
  ChronologyResponse,
  ScreenResponse,
  UnderTheSunResponse
} from 'types/apiTypes'
import {
  FetchChronoPayload,
  FetchScreenPayload,
  FetchSpecialPayload
} from 'types/storeTypes'

export const fetchScreen = createAsyncAction<
  FetchScreenPayload,
  { response: ScreenResponse; name: ScreenNames },
  undefined
>('@SCREEN/FETCH_SCREEN')

export const fetchSpecials = createAsyncAction<
  FetchSpecialPayload,
  { response: ScreenResponse; slug: string },
  undefined
>('@SCREEN/FETCH_SPECIALS')

export const fetchUnderTheSun = createAsyncAction<
  Lang,
  { response: UnderTheSunResponse },
  undefined
>('@SCREEN/FETCH_UNDER_THE_SUN')

export const fetchChrono = createAsyncAction<
  FetchChronoPayload,
  { response: ChronologyResponse; screenName: string },
  undefined
>('@SCREEN/FETCH_CHRONO')
