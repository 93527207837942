const getPlatform = () => {
  const hostname = window.location.hostname

  if (hostname === 'meduza.io') {
    return 'website'
  }

  return 'mirror'
}

export const getBannersByPlatform = (banners) => {
  if (!banners) {
    return []
  }

  const platform = getPlatform()

  const bannersByPlatform = banners.filter((banner) => {
    return banner.platform.includes(platform)
  })

  return bannersByPlatform
}
