import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LIVE_SLIDES_ON_PAGE } from '@client/constants'
import { LiveMaterialContent, RootState } from '@client/types'
import { i18n } from '@routes/Material/LiveMaterial/i18n'
import { getLang } from '@selectors/currentUserSelectors'
import {
  getItemIds,
  getLive,
  getNewItemIds,
  getNewItemIdsRendered,
  getPendingItemsIds
} from '@selectors/liveSelectors'
import {
  connectLive,
  disconnectLive,
  reverseLive,
  showPendingLive
} from '@store/Materials/materialsActions'
import { getRelativeClientRect } from '@utils/getRelativeClientRect'

import { Button, MaterialNote, Switcher } from '@meduza/ui-kit-2'

import { Slide } from '../MaterialComponents/Slide'

import styles from './LiveMaterial.module.css'

export interface LiveMaterialProps {
  material: LiveMaterialContent
}

export const LiveMaterial: React.FC<LiveMaterialProps> = ({
  material: {
    url,
    content: { broadcast, lead }
  }
}) => {
  const dispatch = useDispatch()

  const {
    connected,
    reversed,
    has_next,
    items
  } = useSelector((state: RootState) => getLive(state, url))
  const itemIds = useSelector((state: RootState) =>
    getItemIds(state, url, reversed)
  )
  const newItemIds = useSelector((state: RootState) =>
    getNewItemIds(state, url)
  )
  const pendingItemIds = useSelector((state: RootState) =>
    getPendingItemsIds(state, url)
  )
  const newItemIdsRendered = useSelector((state: RootState) =>
    getNewItemIdsRendered(state, url)
  )

  const lang = useSelector(getLang)

  const [scrollToPendingVisible, setScrollToPendingVisible] = useState(false)
  const [page, setPage] = useState(1)

  const hasNext = itemIds.length > page * LIVE_SLIDES_ON_PAGE

  const intro = useRef<HTMLDivElement | null>(null)

  const hasPendingItems = pendingItemIds.length > 0
  const isNewItemsRendered = newItemIdsRendered.length > 0

  const handleScroll = useCallback(() => {
    if (!intro.current || !hasPendingItems) {
      return
    }

    const rect = intro.current.getBoundingClientRect()

    if (rect.bottom <= 0 && !scrollToPendingVisible) {
      setScrollToPendingVisible(true)
    } else if (rect.bottom > 0 && scrollToPendingVisible) {
      setScrollToPendingVisible(false)
    }
  }, [hasPendingItems, scrollToPendingVisible])

  useEffect(() => {
    if (broadcast.active && !connected) {
      dispatch(connectLive.request(url))
    }
  }, [broadcast.active, connected, dispatch, url])

  useEffect(() => {
    return () => {
      if (connected) {
        dispatch(disconnectLive.request(url))
      }
    }
  }, [connected, dispatch, url])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false)

    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [handleScroll])

  const handleReverseChange = () => {
    dispatch(reverseLive(url))
    setPage(1)
  }

  const handleShowPendingClick = () => {
    dispatch(showPendingLive(url))

    if (intro.current) {
      const introRect = getRelativeClientRect(intro.current)

      dispatch(showPendingLive(url))

      window.scroll({
        top: introRect.top + introRect.height - 45,
        behavior: 'smooth'
      })
    }
  }

  const handleLoadMoreClick = () => {
    if (broadcast.active) {
      setPage((prevPage) => prevPage + 1)
    } else {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const renderIntro = () =>
    lead ? (
      <Slide url={url} blocks={lead} styleContext="isInIntro" index={0} />
    ) : null

  const renderNewSlides = () => {
    if (newItemIds.length === 0) {
      return null
    } else {
      return newItemIds.map((itemId) => (
        <Slide
          key={`${itemId}`}
          styleContext="isInLive"
          authors={broadcast && broadcast.authors}
          {...items[itemId]}
        />
      ))
    }
  }

  const slides = useMemo(
    () =>
      itemIds
        .slice(0, page * LIVE_SLIDES_ON_PAGE)
        .filter((itemId) => items[itemId].status === 'published'),
    [itemIds, items, page]
  )

  const renderSlides = () => {
    return slides.map((itemId) => (
      <Slide
        key={`${itemId}-${reversed}`}
        styleContext="isInLive"
        authors={broadcast && broadcast.authors}
        {...items[itemId]}
      />
    ))
  }

  const materialNotesData = broadcast?.material_footer.find(
    (item) => item.type === 'material_note'
  )

  return (
    <div className={styles.root}>
      <div className={styles.layout}>
        <div ref={intro} className={styles.intro}>
          {renderIntro()}
        </div>

        {broadcast && !broadcast.active && (
          <div className={styles.switcher}>
            <Switcher
              enabled={reversed}
              styleContext="isInLive"
              childrenRight={<span>{i18n[lang].broadcast}</span>}
              onChange={handleReverseChange}
            />
          </div>
        )}

        {hasPendingItems && (
          <div className={styles.update} data-sticked={scrollToPendingVisible}>
            <Button
              size="default"
              theme="gold"
              onClick={handleShowPendingClick}
            >
              {pendingItemIds.length}{' '}
              {i18n[lang].message(pendingItemIds.length)}
            </Button>
          </div>
        )}

        {renderNewSlides()}

        {isNewItemsRendered && (
          <div className={styles.divider}>
            <span>{i18n[lang].new}</span>
          </div>
        )}

        {renderSlides()}

        {(has_next || hasNext) && (
          <div className={styles.more}>
            <Button size="default" theme="gold" onClick={handleLoadMoreClick}>
              Показать еще
            </Button>
          </div>
        )}

        <Slide
          url={url}
          styleContext="isInLive"
          key="share"
          blocks={[
            {
              type: 'share',
              data: { share: true },
              id: 'LiveShare'
            }
          ]}
        />
        {materialNotesData?.data && (
          <MaterialNote
            key="footer"
            block={materialNotesData}
            styleContext="center"
          />
        )}
      </div>
    </div>
  )
}
