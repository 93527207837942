/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './ChaptersButtonIcon.module.css'

const ChaptersButtonIcon = ({
  setIsModalActive,
  display = 'default',
  position = 'default',
  type = null
}) => {
  const handleIconClick = () => {
    setIsModalActive((prev) => !prev)
  }
  const handleClick = () => {
    setIsModalActive(true)
  }

  const modalCenter = display === 'center' && position === 'modal'

  return (
    <div
      onClick={handleClick}
      className={makeClassName([
        [[styles.button], true],
        [[styles.buttonCenter], display === 'center'],
        [[styles.buttonCard], type === 'card']
      ])}
    >
      <div
        onClick={handleIconClick}
        className={makeClassName([
          [[styles.link], true],
          [[styles.linkCard], type === 'card'],
          [[styles.modalLink], position === 'modal'],
          [[styles.modalLinkCenter], modalCenter],
          [[styles.modalLinkCard], modalCenter && type === 'card']
        ])}
      >
        <svg
          className={makeClassName([
            [[styles.icon], true],
            [[styles.iconCard], type === 'card']
          ])}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M10.3334 1H1"
            stroke="black"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="13.9995" cy="1" r="1" fill="black" />
          <path
            d="M7.66668 5H1"
            stroke="black"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="13.9995" cy="5" r="1" fill="black" />
          <path
            d="M10.3334 9H1"
            stroke="black"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="13.9995" cy="9" r="1" fill="black" />
          <path
            d="M7.66668 13H1"
            stroke="black"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="13.9995" cy="13" r="1" fill="black" />
        </svg>
      </div>
    </div>
  )
}

export default ChaptersButtonIcon
