import {
  MaterialContent,
  Og,
  ScreenContent,
  ShareServices
} from '@client/types'
import { popupWindow } from '@utils/popupWindow'

import { sharePopupLegacy } from './sharePopupLegacy'

const targets: Record<
  string,
  {
    service: keyof Og['short']
    share: string
  }
> = {
  fb: {
    service: 'facebook',
    share: '//www.facebook.com/sharer/sharer.php?u={{href}}'
  },

  tw: {
    service: 'twitter',
    share: '//twitter.com/intent/tweet?text={{text}}&url={{href}}&via={{via}}'
  },

  tg: {
    service: 'telegram',
    share:
      '//telegram.me/share/url?url={{href}}&text=Канал%20«Медузы»:%20@meduzalive'
  },

  reddit: {
    service: 'reddit',
    share: 'http://www.reddit.com/submit?url={{href}}&title={{text}}'
  },

  linkedin: {
    service: 'linkedin',
    share: 'https://www.linkedin.com/feed/?shareActive=true&shareUrl={{href}}'
  }
}

export const sharePopup = (
  service: ShareServices,
  material: MaterialContent | ScreenContent
) => {
  if (!material.og.short) {
    return sharePopupLegacy(service, material)
  }

  const {
    title,
    second_title,
    url: materialUrl,
    og: { short }
  } = material as MaterialContent

  const isEng = materialUrl.startsWith('en/')

  const text = `${title} ${second_title ? ` ${second_title}` : ''}`

  if (service === 'pdf') {
    const path = window.location.origin + material.og.pdf?.standard.path

    window.open(path, 'Download')

    return
  }

  if (service === 'email') {
    const email = `mailto:?subject=Meduza&body=${text} ${short.mail}`
    window.open(email, 'Email')
    return
  }

  if (service === 'copy') {
    navigator.clipboard.writeText(short.copy)
    return
  }

  const via = isEng ? 'meduza_en' : 'meduzaproject'
  const shareService = targets[service]
  const href = short[shareService.service]

  let url = targets[service].share.replace(
    /\{\{href\}\}/,
    window.encodeURIComponent(href)
  )

  url = url.replace(/\{\{text\}\}/, window.encodeURIComponent(text))
  url = url.replace(/\{\{via\}\}/, via)

  popupWindow(url, 'Share', 480, 320)
}
