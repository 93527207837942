export enum PlayerStatus {
  LOADING = 'LOADING',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR'
}

export enum AudioPanelTypes {
  DEFAULT = 'default',
  INSTANT = 'instant'
}

export const AudioPlayerPlaybackRates = {
  HALF: 0.5,
  ONE: 1,
  ONE_HALF: 1.5,
  TWO: 2
}

export enum PlayButtonTypeEnums {
  EXTERNAL = 'EXTERNAL',
  GENERAL = 'GENERAL'
}

export enum ProgressTypeEnums {
  PANEL = 'panel',
  CIRCLE = 'circle'
}
