import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { pageView } from '@store/App/appActions'

// Хук для аналитики pageView (шлет не только в GA, но и в api

export const usePageViewAnalytics = ({
  pathname,
  title,
  tag
}: {
  pathname: string
  title?: string
  tag?: string
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (title) {
      dispatch(pageView({ pathname, title, tag }))
    }
  }, [dispatch, pathname, tag, title])
}
