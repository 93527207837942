import { ScreenDocument } from '@client/types'
import { createAction } from '@reduxjs/toolkit'

/*
 Трекает блок баннера в аналитику
 */

export const trackBannerBlock = createAction<{
  origin: string
  document: ScreenDocument
  location: string
}>('@ANALYTICS/TRACK_BANNER_BLOCK')

/*
 Трекает скролл материала
 */

export const trackMaterialScroll = createAction<{
  url: string
  percentage: number
}>('@ANALYTICS/TRACK_MATERIAL_SCROLL')

/*
 Трекает что-нибудь из кита (через postMessage)
 */

export const trackUiKitAction = createAction<{
  category?: string
  element: string
  action: string
}>('@ANALYTICS/TRACK_UI_KIT_ACTION')
