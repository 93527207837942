import React from 'react'
import { Link } from 'react-router-dom'

import { SvgSymbol } from '@meduza/ui-kit-2'

import styles from './Header.module.css'

interface LogoProps {
  lang: string
}

export const Logo: React.FC<LogoProps> = ({ lang }) => {
  return (
    <div className={styles.logo}>
      <Link
        className={styles.meduza}
        to={lang === 'en' ? '/en' : '/'}
        aria-label="Meduza"
      >
        <SvgSymbol icon="meduzaLogo" size="unset" />
      </Link>
    </div>
  )
}
