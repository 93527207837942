import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { MobileInformerDocument } from '@client/types'
import { Informer } from '@common/Informer/Informer'
import {
  informerBecomeVisible,
  informerClicked
} from '@store/Materials/materialsActions'

import styles from './InformerBlock.module.css'

interface InformerBlockProps {
  block: MobileInformerDocument
}

export const InformerBlock: React.FC<InformerBlockProps> = ({
  block: { url, icon_url, text }
}) => {
  const dispatch = useDispatch()
  const [tracked, setTracked] = useState(false)
  const [ref, inView] = useInView()

  useEffect(() => {
    if (!tracked) {
      if (inView) {
        dispatch(informerBecomeVisible(url))
        setTracked(true)
      }
    }
  }, [dispatch, inView, tracked, url])

  const handleClick = () => {
    dispatch(informerClicked(url))
  }

  return (
    <div ref={ref} className={styles.root}>
      <Informer icon={icon_url!}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          {text}
        </a>
      </Informer>
    </div>
  )
}
