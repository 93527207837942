import { combineReducers } from 'redux'

import { appReducer } from './App/appReducer'
import { audioPlayerReducer } from './AudioPlayer/audioPlayerReducer'
import { bannersReducer } from './Banners/bannersReducer'
import { bookmarksReducer } from './Bookmarks/bookmarksReducer'
import { userReducer } from './CurrentUser/currentUserReducer'
import { footnotesReducer } from './Footnote/footnoteReducer'
import { embedReducer } from './Materials/embedReducer'
import { liveReducer } from './Materials/liveReducer'
import { materialsReducer } from './Materials/materialsReducer'
import { modalReducer } from './Modal/modalReducer'
import { screensChronologyReducer } from './Screens/screensChronologyReducer'
import { screensDocumentsReducer } from './Screens/screensDocumentsReducer'
import { screensReducer } from './Screens/screensReducer'
import { searchReducer } from './Search/searchReducer'
import { tooltipsReducer } from './Tooltip/tooltipReducer'

export const rootReducer = combineReducers({
  app: appReducer,
  audioPlayer: audioPlayerReducer,
  banners: bannersReducer,
  currentUser: userReducer,
  screens: screensReducer,
  screensChronology: screensChronologyReducer,
  materials: materialsReducer,
  modal: modalReducer,
  screenDocuments: screensDocumentsReducer,
  search: searchReducer,
  bookmarks: bookmarksReducer,
  materialEmbed: embedReducer,
  materialLive: liveReducer,
  footnote: footnotesReducer,
  tooltip: tooltipsReducer
})
