import {
  fetchChrono,
  fetchScreen,
  fetchSpecials,
  fetchUnderTheSun
} from '@store/Screens/screensActions'
import { handleError } from '@utils/handleError'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as api from '../api'

export function* fetchScreenSaga({
  payload
}: ReturnType<typeof fetchScreen.request>) {
  try {
    const response = yield call(api.fetchScreen, payload)
    yield put(fetchScreen.success({ response, name: payload.name }))
  } catch (err) {
    yield call(handleError, err, fetchScreen.failure.type)
    yield put(fetchScreen.failure())
  }
}

export function* fetchSpecialsSaga({
  payload
}: ReturnType<typeof fetchSpecials.request>) {
  try {
    const response = yield call(api.fetchSpecials, payload)
    yield put(fetchSpecials.success({ response, slug: payload.slug }))
  } catch (err) {
    yield call(handleError, err, fetchSpecials.failure.type)
    yield put(fetchSpecials.failure())
  }
}

export function* fetchUnderTheSunSaga({
  payload
}: ReturnType<typeof fetchUnderTheSun.request>) {
  try {
    const response = yield call(api.fetchUnderTheSun, payload)
    yield put(fetchUnderTheSun.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchUnderTheSun.failure.type)
    yield put(fetchUnderTheSun.failure())
  }
}

export function* fetchChronoSaga({
  payload,
  payload: { screenName }
}: ReturnType<typeof fetchChrono.request>) {
  try {
    const response = yield call(api.fetchChrono, payload)
    yield put(fetchChrono.success({ response, screenName }))
  } catch (err) {
    yield call(handleError, err, fetchChrono.failure.type)
    yield put(fetchChrono.failure())
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(fetchScreen.request.type, fetchScreenSaga),
    takeEvery(fetchSpecials.request.type, fetchSpecialsSaga),
    takeEvery(fetchUnderTheSun.request.type, fetchUnderTheSunSaga),
    takeEvery(fetchChrono.request.type, fetchChronoSaga)
  ])
}
