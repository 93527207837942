declare global {
  interface Selection {
    modify(s: string, t: string, u: string): void
  }
}

export const getSelectedText = () => {
  // если мы ничего не выделили, возвращаем null
  if (!window.getSelection) {
    return null
  }

  // получаем объект с выделенным текстом
  const sel = window.getSelection()

  const range = sel?.getRangeAt(0)

  // если объект выделения пустой, возвращаем null
  if (!sel || sel.isCollapsed) {
    return null
  }

  const anchorNode = sel.anchorNode
  const anchorOffset = sel.anchorOffset
  const focusNode = sel.focusNode
  const focusOffset = sel.focusOffset

  // сравниваем диапазоны смещения выделения. Если выделение происходит справа налево, то начальная точка у sel и range
  // будут разными. На всякий случай проверяем и конечную точку (чтобы покрыть больше узких кейсов).
  // Если выделение было справа налево, то заменяем это выделение на "слева - направо" для того, чтобы modify
  // правильно отработал в Firefox
  if (
    anchorNode &&
    focusNode &&
    (sel?.anchorOffset !== range?.startOffset ||
      sel?.focusOffset !== range?.endOffset)
  ) {
    sel?.setBaseAndExtent(focusNode, focusOffset, anchorNode, anchorOffset)
  }

  // получаем выделенный текст. В канале в слак он будет отображаться в маркированном виде
  const text = sel.toString()

  const NUMBER = 2

  // выделяем кол-во слов === NUMBER после выделенного сегмента
  for (let i = 0; i < NUMBER; i++) {
    sel.modify('extend', 'forward', 'word')
  }
  const after = sel.toString()

  // возвращаем выделение к началу изначально выделенного сегмента
  sel.collapseToStart()

  // выделяем кол-во слов === NUMBER перед выделенным сегментом
  for (let i = 0; i < NUMBER; i++) {
    sel.modify('extend', 'backward', 'word')
  }
  const before = sel.toString()

  // возвращаем выделение того сегмента, которое было выделено изначально
  if (anchorNode && focusNode) {
    sel.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset)
  }

  // объект, в котором в before будет лежать слово/слова до выделенного текста
  // в after будет лежать слово/слова после выделенного текста
  const expanded = {
    before: '',
    after: ''
  }

  expanded.before = before
  expanded.after = after.split(text).slice(1).join(text)

  return {
    text,
    expanded
  }
}
