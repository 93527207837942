/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react'
import { ChapterDataBlock } from '@client/types/Blocks'
import { makeClassName } from '@utils/makeClassName'

import { ChaptersButtonIcon } from '../ChaptersButtonIcon'

import styles from './ChaptersModalWindow.module.css'

interface ChaptersModalWindowProps {
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  block: Array<ChapterDataBlock>
  display?: string
  contents: Array<string>
  type?: string
}

const ChaptersModalWindow: React.FC<ChaptersModalWindowProps> = ({
  setActive,
  block,
  display,
  contents,
  type
}) => {
  useEffect(() => {
    const body = document.body
    body.dataset.chapter = 'true'
    return () => {
      document.body.dataset.chapter = 'false'
    }
  }, [])

  // функция для удаления тегов <a> и <span> для предотвращения попадания ссылок и сносок в главы/подглавы в Оглавлении
  const replaceLink = (string: string) => {
    if (string.includes('</a>') || string.includes('</span>')) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(string, 'text/html')

      // Удаление тегов a и span, оставляя только текст
      doc.querySelectorAll('a, span').forEach((element) => {
        const textNode = document.createTextNode(element.textContent)
        element.parentNode.replaceChild(textNode, element)
      })

      return doc.body.innerHTML
    }
    return string
  }

  const arrayOfTags = contents.sort()

  // функция для скролла к якорному тегу при клике в Оглавлении
  const handleClick = (event, anchorId) => {
    event.preventDefault()

    const targetElement = document.getElementById(anchorId)

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      setActive(false)
    }
  }

  return (
    <div
      className={makeClassName([
        [[styles.modal], true],
        [[styles.modalCenter], display === 'center']
      ])}
      onClick={() => setActive(false)}
    >
      <div
        className={makeClassName([
          [[styles.modalContainer], true],
          [[styles.modalContainerCenter], display === 'center'],
          [[styles.modalContainerCard], type === 'card']
        ])}
      >
        <ChaptersButtonIcon
          position="modal"
          display={display}
          setIsModalActive={setActive}
          type={type}
        />

        <div
          className={makeClassName([
            [[styles.modalContent], true],
            [[styles.modalContentCenter], display === 'center'],
            [[styles.modalContentCard], type === 'card']
          ])}
        >
          {block.map((item) => (
            <a
              className={styles.modalChapterBlock}
              key={item.id}
              href={`#${item.id}`}
              onClick={(event) => handleClick(event, item.id)}
            >
              {item?.chapter ? (
                <>
                  <p
                    className={styles.itemChapterFirst}
                    dangerouslySetInnerHTML={{
                      __html: replaceLink(item.data + '. ')
                    }}
                  ></p>
                  <p
                    className={styles.itemChapterSecond}
                    dangerouslySetInnerHTML={{
                      __html: replaceLink(item.chapter)
                    }}
                  ></p>
                </>
              ) : (
                <p
                  className={styles.itemSubChapter}
                  style={{
                    paddingLeft: `calc(${arrayOfTags.indexOf(
                      item.type
                    )} * 15px)`
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceLink(item.data)
                  }}
                ></p>
              )}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChaptersModalWindow
