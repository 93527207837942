import { PopoverBanner } from '@client/components/Common/PopupBanner/PopupBanner.types'
import { ScreenViewType } from '@client/constants'
import { REQUEST_STATUS } from '@client/enums'
import { BannersData, ExchangeRatesData } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import {
  changeScreenView,
  fetchBanners,
  fetchExchangeRates,
  fetchPopoverBanner,
  setIsEmbedded,
  setIsInApp,
  setIsMobile,
  setIsPreview
} from '@store/App/appActions'

export interface AppState {
  banners?: BannersData
  popoverBanner?: PopoverBanner[]
  exchangeRates?: ExchangeRatesData
  isInApp: boolean
  isEmbedded: boolean
  isPreview: boolean
  isMobile: boolean
  screen_view_type: string
  exchangeRatesRequestStatus: REQUEST_STATUS
  bannersRequestStatus: REQUEST_STATUS
  popoverBannerRequestStatus: REQUEST_STATUS
  isFetchInitiated: boolean
}

const initialState: AppState = {
  isInApp: false,
  isEmbedded: false,
  isPreview: false,
  isMobile: false,
  screen_view_type: ScreenViewType.GRID,
  exchangeRatesRequestStatus: REQUEST_STATUS.IDLE,
  bannersRequestStatus: REQUEST_STATUS.IDLE,
  popoverBannerRequestStatus: REQUEST_STATUS.IDLE,
  banners: null,
  popoverBanner: null,
  isFetchInitiated: false
}

export const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsEmbedded, (state) => {
      state.isEmbedded = true
    })
    .addCase(setIsPreview, (state) => {
      state.isPreview = true
    })
    .addCase(setIsInApp, (state) => {
      state.isInApp = true
    })
    .addCase(setIsMobile, (state, { payload }) => {
      state.isMobile = payload
    })
    .addCase(changeScreenView, (state, { payload: { screenViewType } }) => {
      state.screen_view_type = screenViewType
    })
    .addCase(fetchExchangeRates.request, (state) => {
      state.exchangeRatesRequestStatus = REQUEST_STATUS.LOADING
      state.isFetchInitiated = true
    })
    .addCase(fetchExchangeRates.failure, (state) => {
      state.exchangeRatesRequestStatus = REQUEST_STATUS.FAILURE
      state.isFetchInitiated = false
    })
    .addCase(
      fetchExchangeRates.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        state.exchangeRatesRequestStatus = REQUEST_STATUS.SUCCESS
        state.exchangeRates = data
        state.isFetchInitiated = false
      }
    )
    .addCase(fetchPopoverBanner.request, (state) => {
      state.popoverBannerRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchPopoverBanner.failure, (state) => {
      state.popoverBannerRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchPopoverBanner.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        state.popoverBannerRequestStatus = REQUEST_STATUS.SUCCESS
        state.popoverBanner = data
      }
    )
    .addCase(fetchBanners.request, (state) => {
      state.bannersRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchBanners.failure, (state) => {
      state.bannersRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchBanners.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        state.bannersRequestStatus = REQUEST_STATUS.SUCCESS
        state.banners = data
      }
    )
})
