import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/constants'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import { FormItem } from '@common/Form/FormItem'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { deleteUser } from '@store/CurrentUser/currentUserActions'
import { hideModal } from '@store/Modal/modalActions'

import { Button } from '@meduza/ui-kit-2'

import styles from './Modal.module.css'

export const DeleteUserModal: React.FC = () => {
  const dispatch = useDispatch()
  const { deleteUserStatus, firebase_user } = useSelector(getCurrentUser)
  const alert = useAlert()

  useEffect(() => {
    if (!firebase_user) {
      dispatch(hideModal())
      alert.show(ALERT_TEXTS.ON_USER_DELETE, {
        type: TYPES.INFO
      })
    }
  }, [dispatch, firebase_user, alert])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Вы&nbsp;уверены, что хотите удалить профиль?</h3>

        <div className={styles.subtitle}>
          Мы&nbsp;удалим не&nbsp;только ваш профиль, но&nbsp;и&nbsp;все данные,
          связанные с&nbsp;ним на&nbsp;всех устройствах
        </div>
      </div>

      <div className={styles.controls}>
        <FormItem>
          <Button
            onClick={() => dispatch(deleteUser.request())}
            theme="red"
            state={
              deleteUserStatus === REQUEST_STATUS.LOADING
                ? 'isLoading'
                : 'isDefault'
            }
          >
            Да, удалить профиль
          </Button>
        </FormItem>
        <FormItem>
          <Button onClick={() => dispatch(hideModal())} theme="gray">
            Нет, вернуться назад
          </Button>
        </FormItem>
        {deleteUserStatus === REQUEST_STATUS.FAILURE && (
          <div className={styles.error}>
            Ошибка. Попробуйте еще раз или напишите на&nbsp;
            <a href="mailto:support@meduza.io">support@meduza.io</a>
          </div>
        )}
      </div>
    </div>
  )
}
