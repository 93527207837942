import React from 'react'
import { Lang } from '@client/types'
import { Link } from '@common/Link'
import firebase from 'firebase/compat/app'

import { SvgSymbol } from '@meduza/ui-kit-2'

import i18n from '../i18n'

import styles from './ProfileMobile.module.css'

interface ProfileMobileProps {
  handleAuthClick: () => void
  handleCloseNavClick: () => void
  handleMessageClick: () => void
  // isNotify?: boolean
  firebaseUser: firebase.User | null
  lang: Lang
}

export const ProfileMobile: React.FC<ProfileMobileProps> = ({
  handleAuthClick,
  handleCloseNavClick,
  handleMessageClick,
  // isNotify,
  firebaseUser,
  lang
}) => (
  <div className={styles.root}>
    {firebaseUser && (
      <>
        <div className={styles.item}>
          <Link
            className={styles.link}
            to="/bookmarks"
            onClick={handleCloseNavClick}
          >
            {i18n[lang].bookmarks}
          </Link>
        </div>
        <div className={styles.item}>
          <Link to="/account" onClick={handleCloseNavClick}>
            <div className={styles.userIcon}>
              <SvgSymbol icon="user" size="unset" />
              {/*{isNotify && <span className={styles.notify} />}*/}
            </div>

            <span className={styles.label}>{i18n[lang].profile}</span>
          </Link>
        </div>
        <div className={styles.item}>
          <button type="button" onClick={handleMessageClick}>
            <span className={styles.label}>{i18n[lang].contact}</span>
          </button>
        </div>
      </>
    )}

    {!firebaseUser && (
      <div className={styles.login}>
        <button
          type="button"
          onClick={handleAuthClick}
          aria-label={i18n[lang].login}
        >
          {i18n[lang].login}
        </button>
      </div>
    )}
  </div>
)
