import loadable from '@loadable/component'

export { GameMaterial } from './GameMaterial'
export { Material } from './Material'

export const LoadableMaterial = loadable(() => import('./Material'), {
  resolveComponent: (components) => components.Material
})

export const LoadableGameMaterial = loadable(() => import('./GameMaterial'), {
  resolveComponent: (components) => components.GameMaterial
})
