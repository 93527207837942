export const i18n = {
  ru: {
    error: {
      title: 'Нашли опечатку?',
      label: 'Комментарий для автора',
      placeholder: 'Комментарий для автора (необязательно)'
    },
    send: 'Отправить'
  },
  en: {
    error: {
      title: 'Found a typo?',
      label: 'Leave a comment for the author',
      placeholder: 'Leave a comment for the author (optional)'
    },
    send: 'Send'
  }
}
