import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmbedMaterialContent, StartGameButtonBlock } from '@client/types'
import { getMaterialsById } from '@selectors/materialsSelectors'
import {
  embedLoaded,
  embedStarted,
  embedUnload
} from '@store/Materials/materialsActions'

import { Button } from '@meduza/ui-kit-2'

import styles from './EmbedContainer.module.css'

/*
 * Контейнер для эмбедов (игр)
 */

export interface EmbedContainerProps {
  block: StartGameButtonBlock
  skipIntro: boolean
}

export const EmbedContainer: React.FC<EmbedContainerProps> = ({
  block,
  skipIntro
}) => {
  const dispatch = useDispatch()
  const { material_url, game_id, game_url, text } = block.data

  const material = useSelector(getMaterialsById)[material_url]

  const {
    container: { intro }
  } = material as EmbedMaterialContent

  const [isStarted, setIsStarted] = useState(false)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const iframeRef = useCallback((node) => {
    if (node !== null && node.contentWindow) {
      setIframeLoaded(true)
    }
  }, [])

  const handleStartClick = () => {
    setIsStarted(true)
  }

  useEffect(() => {
    if (iframeLoaded) {
      dispatch(embedLoaded(material_url))
    }

    return () => {
      if (iframeLoaded) {
        dispatch(embedUnload(material_url))
      }
    }
  }, [dispatch, iframeLoaded, material_url])

  useEffect(() => {
    if (skipIntro || (intro && intro.skip)) {
      setIsStarted(true)
    }
  }, [intro, skipIntro])

  useEffect(() => {
    if (isStarted) {
      dispatch(embedStarted(material_url))
    }
  }, [dispatch, isStarted, material_url])

  const url =
    process.env.NODE_ENV === 'development'
      ? `https://meduza.io${game_url}`
      : game_url

  return isStarted ? (
    <div data-embed="true">
      <iframe
        title="game"
        id={game_id}
        src={url}
        ref={iframeRef}
        width="100%"
      />
    </div>
  ) : (
    <div className={styles.play}>
      <Button size="default" theme="gold" onClick={handleStartClick}>
        {text}
      </Button>
    </div>
  )
}
