import React, { memo } from 'react'
import { ExchangeRates } from '@common/ExchangeRates'

import styles from './ExchangeBlock.module.css'

const ExchangeBlockComponent = () => (
  <div className={styles.root}>
    <ExchangeRates styleContext="isInExchangeBlock" />
  </div>
)

export const ExchangeBlock = memo(ExchangeBlockComponent)
