import React from 'react'

import styles from './LoginSuccessImage.module.css'

export const LoginSuccessImage = () => (
  <div className={styles.root}>
    <div className={styles.mobile}>
      <svg width="85" height="85" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(2 2)" fill="none" fillRule="evenodd">
          <circle
            stroke="#000"
            strokeWidth="3"
            fill="#000"
            cx="40.5"
            cy="40.5"
            r="40.5"
          />
          <path
            stroke="#FFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M30 49.576h19.328M30 40.444h19.328M30 31.556h19.328"
          />
        </g>
      </svg>
    </div>
    <div className={styles.desktop}>
      <svg
        width="420"
        height="296"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="31.295%" id="bookmarkC">
            <stop stopColor="#F7F7F7" stopOpacity="0" offset="0%" />
            <stop stopColor="#F7F7F7" offset="100%" />
          </linearGradient>
          <filter
            x="-10.2%"
            y="-11.9%"
            width="120.3%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="bookmarkA"
          >
            <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="10"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M0 8.002A8 8 0 018 0h304a8 8 0 018 8.002v214.996a8 8 0 01-8 8.002H8a8 8 0 01-8-8.002V8.002z"
            id="bookmarkB"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(50)">
            <g transform="translate(0 40)">
              <use
                fill="#000"
                filter="url(#bookmarkA)"
                xlinkHref="#bookmarkB"
              />
              <use fill="#FFF" xlinkHref="#bookmarkB" />
            </g>
            <path fill="#E0E0E0" d="M20 155h280v1H20z" />
            <text
              opacity=".3"
              fontFamily="ProximaNova-Bold, Proxima Nova"
              fontSize="18"
              fontWeight="bold"
              fill="#000"
              transform="translate(0 40)"
            >
              <tspan x="20" y="31">
                pochta@gmail.com
              </tspan>
            </text>
            <text
              opacity=".3"
              fontFamily="ProximaNova-Regular, Proxima Nova"
              fontSize="18"
              fill="#000"
              transform="translate(0 40)"
            >
              <tspan x="20" y="89">
                Профиль
              </tspan>
            </text>
            <text
              fontFamily="ProximaNova-Regular, Proxima Nova"
              fontSize="18"
              fill="#000"
              transform="translate(0 40)"
            >
              <tspan x="20" y="148">
                Закладки
              </tspan>
            </text>
            <path fill="#000" opacity=".3" d="M20 94h280v3H20z" />
            <path d="M300 173h-14v24h14z" />
            <path
              stroke="#E6E6E6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M288.5 176l9 9-9 9"
            />
            <circle fill="#000" cx="160.5" cy="22.5" r="22.5" />
          </g>
          <path
            d="M220.332 44.38c-.284-.359-.63-1.093-1.243-1.984-.348-.504-1.212-1.453-1.469-1.935-.223-.426-.199-.617-.146-.97.094-.628.739-1.117 1.426-1.05.519.048.959.391 1.355.715.239.195.533.574.71.788.163.196.203.277.377.51.23.306.302.458.214.12-.071-.496-.187-1.343-.355-2.092-.128-.568-.16-.657-.281-1.093-.13-.464-.195-.789-.316-1.28-.084-.349-.235-1.06-.276-1.46-.057-.547-.087-1.439.264-1.849.275-.32.906-.418 1.297-.22.512.26.803 1.003.936 1.3.239.534.387 1.151.516 1.961.164 1.031.466 2.462.476 2.763.024-.369-.068-1.146-.004-1.5.058-.32.328-.694.666-.795.286-.085.62-.116.916-.055.313.064.642.288.765.5.363.623.37 1.898.385 1.83.086-.376.07-1.229.284-1.584.14-.234.496-.445.686-.479.295-.052.656-.068.965-.008.249.05.586.345.677.487.217.344.341 1.317.378 1.658.015.141.074-.392.293-.736.406-.639 1.843-.763 1.898.64.025.653.02.623.02 1.063 0 .517-.012.828-.04 1.202-.03.4-.116 1.304-.241 1.742-.086.301-.371.978-.652 1.384 0 0-1.074 1.25-1.191 1.813-.118.562-.08.566-.102.965-.023.398.12.922.12.922s-.801.104-1.233.035c-.391-.063-.875-.84-1-1.079-.172-.328-.54-.265-.683-.023-.225.383-.708 1.07-1.05 1.113-.668.084-2.054.031-3.14.02 0 0 .186-1.01-.226-1.358-.305-.259-.83-.784-1.144-1.06l-.832-.92z"
            fill="#FFF"
          />
          <path
            d="M220.332 44.38c-.284-.359-.63-1.093-1.243-1.984-.348-.504-1.212-1.453-1.469-1.935-.223-.426-.199-.617-.146-.97.094-.628.739-1.117 1.426-1.05.519.048.959.391 1.355.715.239.195.533.574.71.788.163.196.203.277.377.51.23.306.302.458.214.12-.071-.496-.187-1.343-.355-2.092-.128-.568-.16-.657-.281-1.093-.13-.464-.195-.789-.316-1.28-.084-.349-.235-1.06-.276-1.46-.057-.547-.087-1.439.264-1.849.275-.32.906-.418 1.297-.22.512.26.803 1.003.936 1.3.239.534.387 1.151.516 1.961.164 1.031.466 2.462.476 2.763.024-.369-.068-1.146-.004-1.5.058-.32.328-.694.666-.795.286-.085.62-.116.916-.055.313.064.642.288.765.5.362.623.37 1.898.385 1.83.086-.376.07-1.229.284-1.584.14-.234.496-.445.686-.479.295-.052.656-.068.965-.008.249.05.586.345.677.487.217.344.341 1.317.378 1.658.015.141.074-.392.293-.736.406-.639 1.843-.763 1.898.64.025.653.02.623.02 1.063 0 .517-.012.828-.04 1.202-.03.4-.116 1.304-.241 1.742-.086.301-.371.978-.652 1.384 0 0-1.074 1.25-1.191 1.813-.118.562-.08.566-.102.965-.023.398.12.922.12.922s-.801.104-1.233.035c-.391-.063-.875-.84-1-1.079-.172-.328-.54-.265-.683-.023-.225.383-.708 1.07-1.05 1.113-.668.084-2.054.031-3.14.02 0 0 .186-1.01-.226-1.358-.305-.259-.83-.784-1.144-1.06l-.832-.92z"
            stroke="#000"
            strokeWidth=".75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#000"
            strokeWidth=".75"
            strokeLinecap="round"
            d="M228.566 44.734v-3.459M226.551 44.746l-.016-3.473M224.555 41.305l.021 3.426"
          />
          <g
            transform="translate(205 16)"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <circle cx="6" cy="3.5" r="3.5" />
            <path d="M12 13a6 6 0 10-12 0" />
          </g>
          <path fill="url(#bookmarkC)" d="M0 198h420v92H0z" />
        </g>
      </svg>
    </div>
  </div>
)
