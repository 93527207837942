export const generateGradient = (
  color: string,
  steps: 5 | 10,
  angle: number
) => {
  const gradientSteps = {
    10: [
      [0, 7],
      [0.08, 12],
      [0.16, 17],
      [0.22, 21],
      [0.32, 26],
      [0.42, 32],
      [0.52, 38],
      [0.62, 47],
      [0.72, 57],
      [0.82, 65]
    ],

    5: [
      [0, 10],
      [0.2, 20],
      [0.5, 30],
      [0.67, 40],
      [0.8, 50],
      [1, 58]
    ]
  }

  const gradient = gradientSteps[steps].map(
    (i) => `rgba(${color}, ${i[0]}) ${i[1]}%`
  )

  return `linear-gradient(${angle}deg, ${gradient})`
}
