import React, { TouchEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLang } from '@client/selectors/currentUserSelectors'
import { AudioPanel } from '@common/AudioPlayer/AudioPanel'
import { AudioPanelTypes, PlayerStatus } from '@common/AudioPlayer/constants'
import { Link } from '@common/Link'
import { RenderBlocks } from '@common/RenderBlocks'
import { getPlayingEpisodeProps } from '@selectors/audioPlayerSelectors'
import { requestClose } from '@store/AudioPlayer/audioPlayerActions'
import { makeClassName } from '@utils/makeClassName'

import styles from './AudioPlayerMobile.module.css'

/*
 * Плеер для мобилы
 */

interface AudioPlayerMobileProps {
  url: string
}

export const AudioPlayerMobile: React.FC<AudioPlayerMobileProps> = ({
  url
}) => {
  const dispatch = useDispatch()
  const playingEpisode = useSelector(getPlayingEpisodeProps)
  const lang = useSelector(getLang)

  const [open, setOpen] = useState(false)
  const [clientYStart, setClientYStart] = useState(0)

  if (
    !playingEpisode ||
    playingEpisode.data.status === PlayerStatus.CLOSED ||
    playingEpisode.episodeUrl === url
  ) {
    return <div />
  }

  const {
    data: { image, blocks, audio },
    episodeUrl
  } = playingEpisode

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    if (!event.changedTouches || !event.changedTouches[0]) {
      return
    }

    const { changedTouches } = event
    setClientYStart(changedTouches[0].clientY)
  }

  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    const { changedTouches } = event

    if (!changedTouches || !changedTouches[0]) {
      return
    }

    const currentY = changedTouches[0].clientY
    const distance =
      clientYStart < currentY
        ? -clientYStart + currentY
        : clientYStart - currentY

    if (distance > 40) {
      setOpen(false)
    }
  }

  const handleCloseClick = () => {
    setOpen(false)
    dispatch(requestClose({ episodeUrl }))
  }

  const coverImage =
    image.mobile_cover ||
    audio?.coverUrl ||
    image.normal_retina_url ||
    image.base_urls?.is1to4

  return (
    <div className={styles.root}>
      <button
        className={styles.control}
        onClick={() => setOpen(true)}
        type="button"
      >
        <img src={coverImage} alt={audio.title} />
        <div className={styles.animation}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
      </button>

      <div
        className={makeClassName([
          [[styles.container], true],
          [[styles.isVisible], open]
        ])}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={styles.overlay}
          onClick={() => setOpen(false)}
          tabIndex={-1}
          role="button"
        />

        <div
          className={styles.player}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <button
            className={styles.dismiss}
            onClick={() => setOpen(false)}
            type="button"
          />

          <div className={styles.image}>
            <img src={coverImage} alt={audio.title} />
          </div>
          <div className={styles.blocks}>
            {blocks &&
              blocks.map((block) => (
                <RenderBlocks
                  key={block.id}
                  block={block}
                  styleContext={[
                    'isInDynamicBlock',
                    'isInPodcastPlayer',
                    'simple'
                  ]}
                />
              ))}
          </div>
          <AudioPanel
            episodeUrl={episodeUrl}
            lang="ru"
            type={AudioPanelTypes.INSTANT}
            styleContext="isInInstantPlayer"
            image={coverImage}
          />

          <div className={styles.footer}>
            <div>
              <Link to={`/${episodeUrl}`} onClick={() => setOpen(false)}>
                {lang === 'ru' ? 'Открыть материал' : 'Go to story'}
              </Link>
            </div>
            <div>
              <button
                className={styles.button}
                onClick={handleCloseClick}
                type="button"
              >
                {lang === 'ru' ? 'Выключить подкаст' : 'Close'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AudioPlayerMobile.whyDidYouRender = true
