/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { ImageUrls } from '@client/types'
import { makeClassName } from '@utils/makeClassName'
import { validateEmail } from '@utils/validateEmail'

import { SignalBanner } from './SignalBanner'

import styles from './SignalBlock.module.css'

interface BriefSubscriptionProps {
  correlation?: keyof ImageUrls
  handleSubscribeToDaily: any
  daily: any
  email: any
  handleInput: any
  error: any
}

export const SignalBlock: React.FC<BriefSubscriptionProps> = ({
  correlation,
  handleSubscribeToDaily,
  daily,
  email,
  handleInput,
  error
}) => {
  const [isRendered, setIsRendered] = useState(false)
  const [theme, setTheme] = useState('#F08D54')

  useEffect(() => {
    if (isRendered) {
      const colors = ['#F08D54', '#F5B940', '#EE785B', '#5B8EF7', '#FDEE77']

      const selected = Math.floor(Math.random() * colors.length)

      setTheme(colors[selected])
    }
  }, [setTheme, isRendered])

  useEffect(() => {
    setIsRendered(true)
  }, [])

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles.isSuccess], email && daily.subscribed],
        [
          !!correlation && styles[correlation],
          !!correlation && !!styles[correlation]
        ]
      ])}
    >
      <div className={styles.content}>
        <div className={styles.image}>
          <SignalBanner />
        </div>

        <div className={styles.text}>
          <p>
            Мы&nbsp;посылаем вам &laquo;Сигнал&raquo;. Имейл-рассылка
            &laquo;Сигнал&raquo; объясняет понятия и&nbsp;штампы, которыми люди
            думают о&nbsp;войне и&nbsp;мире, власти и&nbsp;самих себе.
            Подпишитесь сейчас и&nbsp;получите свежий выпуск.{' '}
            <span>
              Защита от&nbsp;спама reCAPTCHA.{' '}
              <a
                href="https://policies.google.com/privacy?hl=ru"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Конфиденциальность
              </a>{' '}
              и&nbsp;
              <a
                href="https://policies.google.com/terms?hl=ru"
                target="_blank"
                rel="noreferrer"
              >
                условия использования
              </a>
            </span>
          </p>
        </div>

        <form className={styles.subscribe} onSubmit={handleSubscribeToDaily}>
          <div className={styles.form}>
            <div
              className={makeClassName([
                [[styles.field], true],
                [styles.invalid, (email && error) || (email && daily.error)]
              ])}
            >
              <label htmlFor="signal-email">
                <span>Ваш e-mail</span>
              </label>
              <input
                className={styles.input}
                type="email"
                defaultValue={email}
                onInput={handleInput}
                id="signal-email"
                placeholder="Ваш e-mail"
              />
            </div>

            <button
              className={styles.button}
              disabled={!validateEmail(email)}
              type="submit"
              aria-label="Подписаться"
            >
              <span style={{ backgroundColor: theme }} />
              Подписаться
            </button>
          </div>

          {email && error && (
            <span className={styles.message}>Ошибка! Попробуйте еще раз</span>
          )}

          {email && !error && daily.error && (
            <div className={styles.messageContainer}>
              <span className={styles.message}>
                Что-то пошло не&nbsp;так. <br /> Подпишитесь{' '}
                <a
                  href="https://meduza.us10.list-manage.com/subscribe?u=ff4a009ba1f59d865f0301f85&id=af6c651550"
                  target="_blank"
                  rel="noreferrer"
                >
                  здесь
                </a>{' '}
                или напишите нам на&nbsp;
                <a href="mailto:info@getsignal.news">info@getsignal.news</a>,
                и&nbsp;мы&nbsp;подпишем вас
              </span>
            </div>
          )}

          {email && daily.subscribed && (
            <div className={styles.success}>
              Мы отправили ссылку в письме на почту <strong>{email}</strong>.
              <br />
              Пожалуйста, перейдите по этой ссылке.
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
