import React from 'react'
import { useSelector } from 'react-redux'
import { getListenedState } from '@client/selectors/audioPlayerSelectors'
import { RootState, ScreenDocument } from '@client/types'
import { getHistory, getLang } from '@selectors/currentUserSelectors'

import {
  DocumentItemsCount,
  Meta,
  MetaItem,
  MetaItemLive,
  SvgSymbol,
  Timestamp
} from '@meduza/ui-kit-2'

import i18n from './i18n'

interface BlockMetaContainerProps {
  block: ScreenDocument
}

export const BlockMetaContainer: React.FC<BlockMetaContainerProps> = ({
  block: {
    podcast,
    audio,
    video,
    live,
    datetime,
    layout,
    chapters,
    broadcast,
    url,
    datetime_hide
  }
}) => {
  const lang = useSelector(getLang)
  const history = useSelector(getHistory)

  const isListened = useSelector((state: RootState) =>
    getListenedState(state, url)
  )

  const isLive = live && live.live_now
  const count = podcast ? podcast.episodes_count : chapters && chapters.count

  const isRead = !audio && history && Object.keys(history).indexOf(url) !== -1
  const shouldShowTimestamp = datetime && !isLive && !podcast && !datetime_hide

  return (
    <Meta>
      {audio && (
        <MetaItem bullets>
          <SvgSymbol icon="podcast" size="unset" />
          <span>{audio.mp3_duration_in_words}</span>
        </MetaItem>
      )}

      {video && video.duration_in_words && (
        <MetaItem bullets>
          <SvgSymbol icon="podcast" size="small" />
          {video.duration_in_words}
        </MetaItem>
      )}

      {count && (
        <MetaItem bullets>
          <DocumentItemsCount
            items={count}
            type={layout as 'podcast' | 'card'}
            lang={lang}
          >
            <SvgSymbol icon="card" size="unset" />
          </DocumentItemsCount>
        </MetaItem>
      )}

      {shouldShowTimestamp && (
        <MetaItem bullets>
          <Timestamp publishedAt={datetime} type="fromNow" locale={lang} />
        </MetaItem>
      )}

      {isLive && (
        <MetaItem>
          <MetaItemLive />
          {i18n[lang].broadcast}
        </MetaItem>
      )}

      {broadcast && (
        <MetaItem bullets={!broadcast.with_icon}>
          {broadcast.with_icon && <MetaItemLive />}
          {broadcast.label}
        </MetaItem>
      )}

      {shouldShowTimestamp && (isRead || isListened) && (
        <MetaItem bullets>
          <SvgSymbol icon={isListened ? 'listened' : 'read'} size="unset" />
        </MetaItem>
      )}
    </Meta>
  )
}
