import { BookmarkDocument } from '@client/types'
import * as api from '@store/api'
import { fetchBookmarks } from '@store/Bookmarks/bookmarksActions'
import { handleError } from '@utils/handleError'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { BookmarksResponse } from 'types/apiTypes'

/*
 Запрашивает закладки в апи, потом форматирует их и кладет в localStorage
 (данные о закладках хранятся в firebase, а сами закладки в апи и мы
 не перезапрашиваем их из апи, если данные из firebase и localStorage совпадают)
 */

export function* fetchBookmarksSaga({
  payload
}: ReturnType<typeof fetchBookmarks.request>) {
  try {
    const keys = Object.keys(payload)
    const {
      data: { documents }
    }: BookmarksResponse = yield call(api.fetchBookmarks, keys)

    const bookmarksWithTime = Object.keys(documents).reduce<
      Record<string, BookmarkDocument>
    >((acc, key) => {
      if (payload[key]) {
        acc[key] = {
          ...documents[key],
          timeAdded: payload[key].seconds
        }
      }
      return acc
    }, {})

    yield call(
      [localStorage, localStorage.setItem],
      'bookmarks',
      JSON.stringify(bookmarksWithTime)
    )

    yield put(fetchBookmarks.success(bookmarksWithTime))
  } catch (err) {
    yield call(handleError, err, fetchBookmarks.failure.type)
    yield put(fetchBookmarks.failure())
  }
}

export default function* appSaga() {
  yield all([takeEvery(fetchBookmarks.request.type, fetchBookmarksSaga)])
}
