import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ScreenDocument } from '@client/types'
import { trackBannerBlock } from '@store/Analytics/analyticsActions'

/*
хук для трекинга партнерских блоков
*/

const TAGS = ['спецпроект', 'реклама', 'партнерский материал', 'атлас', 'промо']

export const useBannerTrack = (block: ScreenDocument, origin: string) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const shouldTrack = block.tag && TAGS.indexOf(block.tag.name) > -1

  const [visible, setVisible] = useState(false)
  const [tracked, setTracked] = useState(false)

  const [ref, inView] = useInView({ triggerOnce: true })

  useEffect(() => {
    if (shouldTrack) {
      setVisible(inView)
    }
  }, [shouldTrack, inView])

  useEffect(() => {
    if (!tracked && visible) {
      setTracked(true)

      const location = `https://meduza.io${pathname}`

      dispatch(trackBannerBlock({ origin, document: block, location }))
    }
  }, [visible, tracked, dispatch, block, origin, pathname])

  return ref
}
