import React from 'react'

interface VideoElementProps {
  video: {
    width: number
    height: number
    id: string
  }
}

export const VideoElement: React.FC<VideoElementProps> = ({ video }) => {
  const youtubeUrl = `https://www.youtube.com/watch?v=${video.id}`

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<embed-component ratio='${
          video.width / video.height
        }' media-url='${youtubeUrl}'></embed-component>`
      }}
    />
  )
}
