import { REQUEST_STATUS } from '@client/enums'
import { RootState, ScreenDocument } from '@client/types'
import { getScreenDocuments } from '@selectors/screenDocumentsSelectors'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'

export const getSearchStatus = (state: RootState) => state.search.status
export const getLastQuery = (state: RootState) => state.search.lastQuery
export const getLastResult = (state: RootState) =>
  state.search.results[state.search.lastQuery.trim()]

type Section = {
  timestamp: number
  documents: ScreenDocument[]
}

type ChronologyDocuments = {
  hasNext: boolean
  isFetching: boolean
  items: {
    timestamp: number
    documents: ScreenDocument[]
  }[]
}

export const getSearchDocuments = createSelector(
  [getSearchStatus, getLastResult, getScreenDocuments],
  (status, result, screenDocuments): ChronologyDocuments => {
    const documents = result.ids.map(
      (url: string) => screenDocuments.byId[url]
    ) as ScreenDocument[]
    let lastSection: Section

    return {
      hasNext: result.has_next,
      isFetching: status === REQUEST_STATUS.LOADING,
      items: documents.reduce((acc: Section[], d: ScreenDocument) => {
        if (!lastSection) {
          lastSection = {
            timestamp: d.datetime,
            documents: [d]
          }

          acc.push(lastSection)

          return acc
        }

        const lastTimestamp = dayjs(lastSection.timestamp * 1000)

        const duration = lastTimestamp.diff(d.datetime * 1000, 'hour')

        if (duration >= 24) {
          lastSection = {
            timestamp: d.datetime,
            documents: [d]
          }

          acc.push(lastSection)
        } else {
          lastSection.documents.push(d)
        }

        return acc
      }, [])
    }
  }
)
