import { MDZ_DEFAULT_FIREBASE_TOPIC, MDZ_PN_TOKEN_KEY } from '@client/constants'
import {
  callToPushNotifications,
  setFirebasePushToken,
  setFirebaseTopicStatus
} from '@store/CurrentUser/currentUserActions'
import { Dispatch } from 'redux'

import { ENV_PUBLIC } from './envPublic'

export const setupFirebaseMessaging = (dispatch: Dispatch) => {
  // MESSAGING
  // first we check if we have notifications
  // then we check if we have a token in local storage
  // if we have it - we just update current_user's state
  // if we do not have it - we get it from firebase and after that
  // put it in localstorage and dispatch to current_user and subscribe a user to notifications channel breaking_news
  // const { currentUser } = getState()
  if (window.firebase.messaging.isSupported()) {
    const localStorageToken = localStorage.getItem(MDZ_PN_TOKEN_KEY)
    const localStorageTopicStatus =
      localStorage.getItem(
        `mdzFirebasePushChannel:${MDZ_DEFAULT_FIREBASE_TOPIC}`
      ) || 'unsubscribed'

    // TESTING LOCALY
    // dispatch(setFirebasePushToken('test:localStorageToken'))
    // dispatch(
    //   setFirebaseTopicStatus({
    //     topic: MDZ_DEFAULT_FIREBASE_TOPIC,
    //     status: localStorageTopicStatus
    //   })
    // )

    if (localStorageToken) {
      dispatch(setFirebasePushToken(localStorageToken))
      dispatch(
        setFirebaseTopicStatus({
          topic: MDZ_DEFAULT_FIREBASE_TOPIC,
          status: localStorageTopicStatus
        })
      )
    } else {
      console.log('getting token')
      const messaging = window.firebaseApp.messaging()
      messaging
        .getToken({ vapidKey: ENV_PUBLIC.PUBLIC_FIREBASE_VAPID_KEY })
        .then((currentToken: string) => {
          if (currentToken) {
            localStorage.setItem(MDZ_PN_TOKEN_KEY, currentToken)
            dispatch(setFirebasePushToken(currentToken))

            dispatch(
              callToPushNotifications.request({
                token: currentToken,
                topic: MDZ_DEFAULT_FIREBASE_TOPIC,
                action: 'subscribe'
              })
            )
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            )
          }
        })
        .catch((err: Error) => {
          console.log(err.message)
        })
    }
  } else {
    console.log('no firebase messaging')
  }
}
