import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeClassName } from '@utils/makeClassName'

/*
 * Стилизованный Link
 */
import styles from './Link.module.css'

interface LinkProps {
  to: string
  onClick?: () => void
  styleContext?: string
  className?: string
  tabIndex?: number
  ariaLabel?: string
  children: React.ReactNode
}

export const Link: React.FC<LinkProps> = ({
  to,
  onClick,
  styleContext,
  className,
  children,
  tabIndex,
  ariaLabel
}) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={
      className ||
      makeClassName([
        [styles.root, true],
        [
          !!styleContext && styles[styleContext],
          !!styleContext && !!styles[styleContext]
        ]
      ])
    }
    tabIndex={tabIndex}
    aria-label={ariaLabel}
  >
    {children}
  </NavLink>
)
