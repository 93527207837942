import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './FormItem.module.css'

interface FormItemProps {
  styleContext?: string
  children: React.ReactNode
}

export const FormItem: React.FC<FormItemProps> = ({
  children,
  styleContext
}) => (
  <div
    className={makeClassName([
      [[styles.root], true],
      [
        !!styleContext && [styles[styleContext]],
        !!styleContext && styles[styleContext]
      ]
    ])}
  >
    {children}
  </div>
)
