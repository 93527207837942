import { createReducer } from '@reduxjs/toolkit'
import { hideTooltip, showTooltip } from '@store/Tooltip/tooltipActions'

export interface TooltipState {
  target: HTMLButtonElement
}

const initialState = {} as TooltipState

export const tooltipsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showTooltip, (state, { payload }) => payload)
    .addCase(hideTooltip, () => initialState)
})
