import React from 'react'
import { EpisodeCoverBlock } from '@client/types'
import { Link } from '@common/Link'
import { RenderBlocks } from '@common/RenderBlocks'
import { generateGradient } from '@utils/generateGradient'
import { makeClassName } from '@utils/makeClassName'

import { Image } from '@meduza/ui-kit-2'

import styles from './EpisodeCover.module.css'

/*
 * Обложка эпизода для материалов
 */

export interface EpisodeCoverProps {
  block: EpisodeCoverBlock
  styleContext: string | string[]
}

export const EpisodeCover: React.FC<EpisodeCoverProps> = ({
  block,
  styleContext
}) => {
  const {
    url,
    blocks,
    cover: {
      urls,
      ambiance,
      gradients,
      urls: { blured_background_jpg: bluredBackground }
    }
  } = block.data

  const backgroundStyle: { backgroundColor?: string } = {}
  const gradientStyle: { backgroundImage?: string } = {}
  let theme = 'dark'

  if (ambiance && gradients) {
    theme = gradients.text_rgb === '0,0,0' ? 'dark' : 'light'
    backgroundStyle.backgroundColor = `rgb(${gradients.bg_rgb})`
    gradientStyle.backgroundImage = generateGradient(gradients.bg_rgb, 5, -180)
  }

  return (
    <div
      className={makeClassName(
        [
          [styles.root, true],
          [styles.withAmbiance, !!ambiance],
          [styles[theme], true]
        ].concat(
          Array.isArray(styleContext)
            ? styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
            : [[styles[styleContext], !!styleContext && !!styles[styleContext]]]
        )
      )}
      style={backgroundStyle}
    >
      <Link className={styles.hiddenLink} to={`/${url}`}>
        {bluredBackground && (
          <div
            className={styles.background}
            style={{ backgroundImage: `url(${bluredBackground})` }}
          />
        )}
      </Link>

      {ambiance && (
        <div className={styles.ambiance}>
          <Image optimized={ambiance} ratio={1.2688172043} display="narrow" />
          <div className={styles.gradientBackground} style={gradientStyle} />
        </div>
      )}

      <div className={styles.image}>
        <Image optimized={urls} ratio={1} display="narrow" />
      </div>
      <div className={styles.body}>
        {blocks.map((block) => (
          <RenderBlocks
            key={block.id}
            block={block}
            styleContext={styleContext}
          />
        ))}
      </div>
    </div>
  )
}
