import { RootStateOrAny } from 'react-redux'
import { rootReducer } from '@store/rootReducer'
import { applyMiddleware, createStore, Middleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

const middlewares: Middleware[] = [sagaMiddleware]

const dev = process.env.NODE_ENV === 'development'

export default function configureStore(initialState?: RootStateOrAny) {
  if (dev && process.env.PLATFORM === 'web') {
    middlewares.push(createLogger({ collapsed: true }))
  }

  const store = createStore(
    rootReducer,
    initialState || {},
    composeWithDevTools(applyMiddleware(...middlewares))
  )

  return { ...store, runSaga: sagaMiddleware.run }
}
