import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { TransitionGroup } from 'react-transition-group'
import { AlertTemplateProps } from '@common/AlertTemplate/AlertTemplate'
import { groupBy } from '@utils/groupBy'

import { POSITIONS, TRANSITIONS, TYPES } from './constants'
import { Context as DefaultContext } from './Context'
import Transition from './Transition'
import { Alert, AlertContext, AlertOptions } from './types'
import Wrapper from './Wrapper'

export interface ProviderProps {
  offset?: string
  position?: typeof POSITIONS
  timeout?: number
  type?: typeof TYPES
  transition?: typeof TRANSITIONS[keyof typeof TRANSITIONS]
  containerStyle?: Record<string, string | number>
  template: React.FC<AlertTemplateProps>
  context?: {
    Provider: React.Provider<AlertContext>
    Consumer: React.Consumer<AlertContext>
  }
  children: React.ReactNode
}

export const Provider: React.FC<ProviderProps> = ({
  children,
  offset = '10px',
  position = POSITIONS.BOTTOM_CENTER,
  timeout = 4000,
  type = TYPES.INFO,
  transition = TRANSITIONS.FADE_IN,
  containerStyle = {
    zIndex: 100
  },
  template: AlertComponent,
  context: Context = DefaultContext,
  ...props
}) => {
  const root = useRef<HTMLDivElement>()
  const alertContext = useRef<AlertContext>()
  const timersId = useRef<NodeJS.Timeout[]>([])
  const [alerts, setAlerts] = useState<Alert[]>([])

  useEffect(() => {
    root.current = document.createElement('div')
    root.current.id = '__alert__'
    document.body.appendChild(root.current)
    const timersIdRef = timersId.current

    return () => {
      timersIdRef.forEach(clearTimeout)
      if (root.current) document.body.removeChild(root.current)
    }
  }, [])

  const remove = useCallback((alert: Alert) => {
    setAlerts((currentAlerts) => {
      const lengthBeforeRemove = currentAlerts.length
      const filteredAlerts = currentAlerts.filter((a) => a.id !== alert.id)

      if (lengthBeforeRemove > filteredAlerts.length && alert.options.onClose) {
        alert.options.onClose()
      }

      return filteredAlerts
    })
  }, [])

  const removeAll = useCallback(() => {
    if (alertContext.current) {
      alertContext.current.alerts.forEach(remove)
    }
  }, [remove])

  const show = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (message = '', options: any) => {
      const id = Math.random().toString(36).substr(2, 9)

      const alertOptions: AlertOptions = {
        position: options.position || position,
        timeout,
        type,
        ...options
      }

      const alert: Alert = {
        id,
        message,
        options: alertOptions,
        close: () => remove(alert)
      }

      if (alert.options.timeout) {
        const timerId = setTimeout(() => {
          remove(alert)

          timersId.current.splice(timersId.current.indexOf(timerId), 1)
        }, alert.options.timeout)

        timersId.current.push(timerId)
      }

      setAlerts((state) => state.concat(alert))
      if (alert.options.onOpen) alert.options.onOpen()

      return alert
    },
    [position, remove, timeout, type]
  )

  const success = useCallback(
    (
      message = '',
      options = {
        type: TYPES.SUCCESS
      }
    ) => show(message, options),
    [show]
  )

  const error = useCallback(
    (
      message = '',
      options = {
        type: TYPES.ERROR
      }
    ) => show(message, options),
    [show]
  )

  const info = useCallback(
    (
      message = '',
      options = {
        type: TYPES.INFO
      }
    ) => show(message, options),
    [show]
  )

  alertContext.current = {
    alerts,
    show,
    remove,
    removeAll,
    success,
    error,
    info
  }

  const alertsByPosition = groupBy(
    alerts,
    (alert: Alert) => alert.options.position
  )

  return (
    <Context.Provider value={alertContext.current}>
      {children}
      {root.current &&
        createPortal(
          <>
            {Object.keys(POSITIONS).map((key) => {
              const currentPosition = POSITIONS[key]
              if (alertsByPosition[currentPosition]) {
                return (
                  <TransitionGroup
                    appear
                    key={currentPosition}
                    options={{ position: currentPosition, containerStyle }}
                    component={Wrapper}
                    {...props}
                  >
                    {alertsByPosition[currentPosition].map((alert: Alert) => (
                      <Transition type={transition} key={alert.id}>
                        <AlertComponent
                          style={{ margin: offset, pointerEvents: 'all' }}
                          {...alert}
                        />
                      </Transition>
                    ))}
                  </TransitionGroup>
                )
              } else {
                return null
              }
            })}
          </>,
          root.current
        )}
    </Context.Provider>
  )
}
