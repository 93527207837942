import React from 'react'
import { useSelector } from 'react-redux'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { getLang } from '@selectors/currentUserSelectors'
import { makeClassName } from '@utils/makeClassName'
import { Block } from 'types/Blocks'

import { Timestamp } from '@meduza/ui-kit-2'

import styles from './Slide.module.css'

/*
 * Слайд для SlideMaterial и LiveMaterial
 */

interface SlideProps {
  url: string
  blocks?: Block[]
  meta?: {
    important: boolean
    published_at: number
    author?: string
  }
  styleContext?: string
  authors?: boolean
  isInBookmarks?: boolean
  index?: number
}

export const Slide: React.FC<SlideProps> = ({
  url,
  blocks,
  styleContext,
  authors,
  meta,
  index,
  isInBookmarks
}) => {
  const lang = useSelector(getLang)
  const isImportant = !!meta && meta.important
  const lightBox = useLightbox()

  return (
    <div
      className={makeClassName([
        [[styles.slide], true],
        [[styles.important], isImportant],
        [
          !!styleContext && [styles[styleContext]],
          !!styleContext && styles[styleContext]
        ]
      ])}
    >
      {meta && (
        <div className={styles.meta}>
          <Timestamp
            publishedAt={meta.published_at}
            type="fromNow"
            locale={lang}
          />

          {authors && <span className={styles.author}>{meta.author}</span>}
        </div>
      )}

      <div className={styles.body}>
        {blocks &&
          blocks.map((block) => (
            <RenderBlocks
              url={url}
              key={block.id || index}
              block={block}
              styleContext={['isInSlide', 'slide']}
              isInBookmarks={isInBookmarks}
              lightBox={lightBox}
            />
          ))}
      </div>
    </div>
  )
}
