import React from 'react'

const BackgroundMobile = () => (
  <svg width="100%">
    <defs>
      <mask id="modalMask" x="0" y="0" width="100%" height="100%">
        <rect fill="white" y="0" width="100%" height="100%" x="0" />
        <svg x="50%" overflow="visible" width="12" height="12">
          <g transform="translate(-12 0)">
            <path d="M4.61852778e-14,9.76996262e-15 L9.84597532,5.80270273 C11.0992017,6.54128882 12.6546044,6.54128882 13.9078308,5.80270273 L23.7538061,9.76996262e-15 L4.61852778e-14,9.76996262e-15 Z" />
          </g>
        </svg>
      </mask>
    </defs>
    <rect
      fill="currentColor"
      id="base-mask"
      mask="url(#modalMask)"
      x="0"
      y="0"
      width="100%"
      height="100%"
    />
  </svg>
)

export default BackgroundMobile
