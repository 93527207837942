import { EpisodeData } from '@client/types'
import { createAction } from '@reduxjs/toolkit'

export const requestPlay = createAction<{
  episodeUrl: string
  episodeData: EpisodeData
}>('@AUDIO_PLAYER/REQUEST_PLAY')

export const requestPause = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/REQUEST_PAUSE')

export const play = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/PLAY')

export const pause = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/PAUSE')

export const finish = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/FINISH')

export const error = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/ERROR')

export const close = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/CLOSE')

export const rewindBackward = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/REWIND_BACKWARD')

export const rewindForward = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/REWIND_FORWARD')

export const timeUpdate = createAction<{
  episodeUrl: string
  time: number
  duration?: number
}>('@AUDIO_PLAYER/TIME_UPDATE')

export const requestRewind = createAction<{
  episodeUrl: string
  progress: number
}>('@AUDIO_PLAYER/REQUEST_REWIND')

export const requestClose = createAction<{
  episodeUrl: string
}>('@AUDIO_PLAYER/REQUEST_CLOSE')

export const rewind = createAction<{
  episodeUrl: string
  time: number
}>('@AUDIO_PLAYER/REWIND')

export const setPlaybackRate = createAction<{
  episodeUrl: string
  playbackRate: number
}>('@AUDIO_PLAYER/SET_PLAYBACK_RATE')
