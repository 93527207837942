import { MDZ_FIREBASE_USER_DATA } from '@client/constants'
import { emailLogin } from '@store/CurrentUser/currentUserActions'
import { Dispatch } from 'redux'

export const handleEmailLogin = (dispatch: Dispatch) => {
  // Check for sign in via email
  // this code executes when user clicks on
  // email sing-in link
  if (window.firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
    const email = window.localStorage.getItem('emailForSignIn')

    if (!email) {
      window.prompt('Пожалуйста, укажите свой email для подтверждения')
    } else {
      window.firebaseApp
        .auth()
        .signInWithEmailLink(email, window.location.href)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((result: any) => {
          window.localStorage.removeItem('emailForSignIn')

          dispatch(emailLogin(result.user!))

          if (typeof localStorage !== 'undefined') {
            localStorage.setItem(
              MDZ_FIREBASE_USER_DATA,
              JSON.stringify(result.user)
            )
          }
        })
        .catch((error: Error) => {
          console.log('auth error', { error })
        })
    }
  }
}
