export const generateCardChapters = (data) => {
  const chapterData = []

  data.forEach((card) => {
    const result = card.blocks.find((block) => block.type === 'card_title')
    if (result) {
      chapterData.push({
        type: 'card_title',
        data: result.data.index,
        chapter: result.data.text,
        id: result.id,
        index: result.data.index
      })
    }
  })
  return chapterData
}
