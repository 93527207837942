import React from 'react'

import styles from './Affiliate.module.css'

export interface AffiliateProps {
  logo: string
  link: string
}

/*
 * Плашка партнерского материала
 */

export const Affiliate: React.FC<AffiliateProps> = ({ logo, link }) => (
  <div className={styles.panel}>
    {/* eslint-disable-next-line react/jsx-no-target-blank */}
    <a className={styles.root} href={link} target="_blank">
      <img
        className={styles.logo}
        src={logo.replace('/ads/', '/partner/')}
        alt=""
      />
      <span className={styles.text}>Партнерский материал</span>
    </a>
  </div>
)
