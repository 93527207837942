export const locale = {
  ru: {
    hasLogo: true,
    title: null,
    tag: 'Рассылка',
    text: `<p>Подпишитесь на&nbsp;&laquo;Сигнал&raquo;&nbsp;&mdash; имейл-рассылку от&nbsp;создателей &laquo;Медузы&raquo;. <br/>Она помогает понимать новости и&nbsp;будет работать до&nbsp;тех пор, пока в&nbsp;России есть интернет. <br/><span>Защита от спама reCAPTCHA. <a href="https://policies.google.com/privacy?hl=ru" target="_blank" rel="noreferrer">Конфиденциальность</a> и&nbsp;<a href="https://policies.google.com/terms?hl=ru" target="_blank" rel="noreferrer">условия использования</a>.</span></p> <p><a href="https://getsignal.news/?utm_source=meduza&utm_medium=form&utm_campaign=footer" target="_blank">Подробнее про «Сигнал»</a></p>`,
    success_message:
      'Мы&nbsp;отправили ссылку в&nbsp;письме на&nbsp;почту <strong>{USER_EMAIL}</strong>.<br/>Пожалуйста, перейдите по&nbsp;этой ссылке.',
    wrong_email_message: 'Ошибка! Попробуйте еще раз',
    something_wrong:
      'Что-то пошло не&nbsp;так. Подпишитесь <a href="https://meduza.us10.list-manage.com/subscribe?u=ff4a009ba1f59d865f0301f85&id=af6c651550" target="_blank" rel="noreferrer">здесь</a> или напишите нам на&nbsp;<a href="mailto:info@getsignal.news">info@getsignal.news</a>, и&nbsp;мы&nbsp;подпишем вас',
    cta: 'Подписаться'
  },

  en: {
    hasLogo: false,
    tag: 'Newsletters',
    title: 'The Real Russia. Today.',
    text: `<p>Subscribe to Meduza’s newsletter and don’t miss the next major event <br/>in the post-Soviet region. Available everywhere with an Internet connection.<br/> <span>Protected&nbsp;by&nbsp;reCAPTCHA&nbsp;and&nbsp;the&nbsp;Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span></p>`,
    success_message: 'Excellent! Now you’ve got to verify that email address.',
    wrong_email_message: 'Something went wrong! Try again',
    something_wrong: `Something went wrong! Please contact us at <a href="mailto:newsletter@meduza.io">newsletter@meduza.io</a>, and we'll get you signed up for our newsletter.`,
    cta: 'Subscribe'
  },

  new: {
    hasLogo: true,
    tag: 'Newsletters',
    title: null,
    text: `<p> Underreported stories. Fresh perspectives. From&nbsp;Budapest&nbsp;to&nbsp;Bishkek.<br/> <span>Protected&nbsp;by&nbsp;reCAPTCHA&nbsp;and&nbsp;the&nbsp;Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span></p>`,
    success_message: 'Excellent! Now you’ve got to verify that email address.',
    wrong_email_message: 'Something went wrong! Try again',
    something_wrong: `Something went wrong! Please contact us at <a href="mailto:newsletter@meduza.io">newsletter@meduza.io</a>, and we'll get you signed up for our newsletter.`,
    cta: 'Subscribe'
  }
}
