import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './PodcastCover.module.css'

/*
 * Обложка подкаста для материала
 */

interface PodcastCoverProps {
  image?: string
  styleContext: string
  correlation?: string
}

export const PodcastCover: React.FC<PodcastCoverProps> = ({
  image,
  styleContext,
  correlation
}) => {
  const style = {
    backgroundImage: `url(${image})`
  }

  const classNames = [
    [styles.root, true],
    [styles[styleContext], !!styleContext && styles[styleContext]]
  ]

  if (correlation) {
    classNames.push([styles[correlation], !!correlation && styles[correlation]])
  }

  return (
    <div className={makeClassName(classNames)}>
      <div className={styles.wrapper}>
        <div className={styles.image} style={style} />
      </div>
    </div>
  )
}
