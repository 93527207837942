import React, { CSSProperties, useMemo } from 'react'

import { POSITIONS } from './constants'

type PositionsKeys = keyof typeof POSITIONS

export const getStyles = (
  position: typeof POSITIONS[PositionsKeys]
): CSSProperties => {
  const initialStyles: CSSProperties = {
    left: 0,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'none'
  }

  switch (position) {
    case POSITIONS.TOP_LEFT:
      return {
        ...initialStyles,
        top: 0,
        alignItems: 'flex-start'
      }
    case POSITIONS.TOP_CENTER:
      return {
        ...initialStyles,
        top: 0
      }
    case POSITIONS.TOP_RIGHT:
      return {
        ...initialStyles,
        top: 0,
        alignItems: 'flex-end'
      }
    case POSITIONS.MIDDLE_LEFT:
      return {
        ...initialStyles,
        top: '50%',
        alignItems: 'flex-start'
      }
    case POSITIONS.MIDDLE: {
      return {
        ...initialStyles,
        top: '50%'
      }
    }
    case POSITIONS.MIDDLE_RIGHT:
      return {
        ...initialStyles,
        top: '50%',
        alignItems: 'flex-end'
      }

    case POSITIONS.BOTTOM_LEFT:
      return {
        ...initialStyles,
        bottom: 0,
        alignItems: 'flex-start'
      }
    case POSITIONS.BOTTOM_CENTER:
      return {
        ...initialStyles,
        bottom: 0
      }
    case POSITIONS.BOTTOM_RIGHT:
      return {
        ...initialStyles,
        bottom: 0,
        alignItems: 'flex-end'
      }

    default: {
      return initialStyles
    }
  }
}

export interface WrapperProps {
  children: React.ReactNode
  options: {
    position: typeof POSITIONS[PositionsKeys]
    containerStyle: Record<string, string | number>
  }
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  options: { position, containerStyle },
  ...props
}) => {
  const styles = useMemo(() => getStyles(position), [position])

  return (
    <div style={{ ...styles, ...containerStyle }} {...props}>
      {children}
    </div>
  )
}

export default Wrapper
