import { RootState } from '@client/types'
import { createSelector } from 'reselect'

export const getScreenDocuments = (state: RootState) => state.screenDocuments
export const getScreenDocumentsById = (state: RootState) =>
  state.screenDocuments.byId

export const getScreenRotationDocuments = createSelector(
  getScreenDocumentsById,
  (documents) =>
    Object.keys(documents)
      .filter(
        (key) => key.startsWith('rotation/') || key.startsWith('en/rotation/')
      )
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: documents[key]
        }
      }, {})
)
