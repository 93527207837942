import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { EmbedMaterialContent, RootState } from '@client/types'
import { ErrorComponent } from '@common/Error/Error'
import { Skeleton } from '@common/Skeleton'
import { Layout } from '@components/Layout/Layout'
import { Meta } from '@components/Layout/Meta'
import { useBridge } from '@hooks/useBridge'
import { usePageViewAnalytics } from '@hooks/usePageViewAnalytics'
import { getApp } from '@selectors/appSelectors'
import { getLang } from '@selectors/currentUserSelectors'
import { getMaterial } from '@selectors/materialsSelectors'
import { hideFootnote, showFootnote } from '@store/Footnote/footnoteActions'
import { fetchMaterial } from '@store/Materials/materialsActions'
import { showModal } from '@store/Modal/modalActions'
import { fetchUnderTheSun } from '@store/Screens/screensActions'
import { getSelectedText } from '@utils/getSelectedText'
import equal from 'fast-deep-equal/es6'

import { Affiliate } from './MaterialComponents/Affiliate'
import { TopBar } from './MaterialComponents/TopBar'
import { UnderTheSun } from './MaterialComponents/UnderTheSun'
import { EmbedMaterial } from './EmbedMaterial'

import styles from './Material.module.css'

interface FootnoteElement extends HTMLElement {
  dataset: {
    id: string
    highlight: string
    body: string
    title: string
  }
}

interface MaterialParams {
  lang?: string
  material?: string
  year?: string
  month?: string
  day?: string
  slug?: string
}

export const GameMaterial: React.FC<
  RouteConfigComponentProps<MaterialParams>
> = ({
  location: { pathname },
  route,
  match: {
    params: { lang: paramsLang, material: materialName, year, month, day, slug }
  }
}) => {
  useBridge()
  const dispatch = useDispatch()
  const containerRoot = useRef<HTMLDivElement>(null)

  const { isEmbedded, isInApp } = useSelector(getApp)

  const url = [paramsLang, materialName, year, month, day, slug]
    .filter((part) => !!part)
    .join('/')

  const lang = useSelector(getLang)

  const material: EmbedMaterialContent = useSelector(
    (state: RootState) => getMaterial(state, url),
    equal
  )

  const hideUnderTheSun =
    material && (!material.under_the_sun || !material.under_the_sun.show)

  usePageViewAnalytics({
    pathname,
    title: material && material.og.title,
    tag: material && material.tag && material.tag.name
  })

  const [showUnderTheSun, setShowUnderTheSun] = useState(true)

  const [isBrokenGame, setIsBrokenGame] = useState(null)

  useEffect(() => {
    dispatch(fetchMaterial.request(url))
    dispatch(fetchUnderTheSun.request(lang))
    setShowUnderTheSun(!(isEmbedded || isInApp || hideUnderTheSun))

    window.scrollTo({ top: 0 })
  }, [dispatch, url, lang, isEmbedded, isInApp, hideUnderTheSun])

  useEffect(() => {
    if (material) {
      const checkBrokenGame = async (url: string) => {
        const response = await fetch(url, { method: 'HEAD' })
        if (response.status < 200 || response.status >= 400) {
          setIsBrokenGame('true')
        } else {
          setIsBrokenGame('false')
        }
      }
      const gameUrl = (material?.content?.blocks.filter(
        (block) => block.type === 'start_game_btn'
      )[0] as { data: { game_url: string } })?.data?.game_url
      checkBrokenGame(gameUrl)
    } else {
      setIsBrokenGame('false')
    }
  }, [material])

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement

      const activeFootnote: HTMLElement | null = window.document.querySelector(
        '[data-highlight="true"]'
      )

      if (activeFootnote) {
        activeFootnote.dataset.highlight = 'false'
        dispatch(hideFootnote())
      }

      if (target.classList && target.classList.contains('FootnoteLink')) {
        const footnoteLink = event.target as FootnoteElement

        if (activeFootnote?.dataset?.id === footnoteLink.dataset.id) {
          return
        }

        const targetRect = footnoteLink.getBoundingClientRect()
        const rect = {
          top:
            targetRect.top +
            (document.body.scrollTop || document.documentElement.scrollTop) -
            8,
          left: targetRect.right
        }

        dispatch(
          showFootnote({
            id: footnoteLink.dataset.id,
            title: footnoteLink.dataset.title,
            body: footnoteLink.dataset.body,
            originRect: rect,
            containerRect: containerRoot.current.getBoundingClientRect()
          })
        )
      }
    },
    [dispatch]
  )

  const handleCtrlEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
        const selection = getSelectedText()

        if (selection) {
          dispatch(
            showModal({
              type: 'textError',
              props: {
                ...selection
              }
            })
          )
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    window.addEventListener('click', handleClick)
    window.addEventListener('keydown', handleCtrlEnter)

    return () => {
      window.removeEventListener('click', handleClick)
      window.removeEventListener('keydown', handleCtrlEnter)
    }
  }, [handleClick, handleCtrlEnter])

  return (
    <>
      <Meta material={material} lang={lang} />

      {isBrokenGame === null && <Skeleton styleContext="isInMaterial" />}
      {isBrokenGame === 'true' && <ErrorComponent type="brokenGame" />}
      {isBrokenGame === 'false' && (
        <Layout
          pathname={pathname}
          routeName={route && route.name}
          type="material"
          materialName={materialName}
        >
          {!material ? (
            <Skeleton styleContext="isInMaterial" />
          ) : (
            <div className={styles.root} ref={containerRoot}>
              {material.affiliate && (
                <Affiliate
                  logo={material.affiliate.image_url}
                  link={material.affiliate.sponsored_url}
                />
              )}
              <div className={styles.container}>
                <TopBar material={material} lang={lang} showContent={false} />
                <EmbedMaterial material={material} />
              </div>
              {material.affiliate && (
                <Affiliate
                  logo={material.affiliate.image_url}
                  link={material.affiliate.sponsored_url}
                />
              )}
              {showUnderTheSun && (
                <UnderTheSun material={material} lang={lang} />
              )}
            </div>
          )}
        </Layout>
      )}
    </>
  )
}

GameMaterial.whyDidYouRender = true
