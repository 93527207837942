import React, { CSSProperties } from 'react'
import { Transition } from 'react-transition-group'

const duration = 300

const defaultStyle: CSSProperties = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'fixed',
  zIndex: 10000,
  pointerEvents: 'none'
}

const transitionStyles: Record<string, CSSProperties> = {
  entering: { opacity: 1, position: 'fixed' },
  entered: { opacity: 1, position: 'fixed' },
  exiting: { opacity: 0, position: 'fixed' },
  exited: { opacity: 0, position: 'static' },
  unmounted: { opacity: 0, position: 'static' }
}

interface FadeProps {
  visible: boolean
  children: React.ReactNode
}

export const Fade: React.FC<FadeProps> = ({ visible, children }) => (
  <Transition in={visible} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}
      >
        {children}
      </div>
    )}
  </Transition>
)
