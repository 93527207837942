import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './FormTextarea.module.css'

interface FormTextareaProps {
  styleContext: string
  disabled?: boolean
  placeholder: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  id: string
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  styleContext,
  disabled,
  placeholder,
  value,
  onChange,
  id
}) => {
  return (
    <textarea
      className={makeClassName([
        [[styles.root], true],
        [[styles[styleContext]], !!styleContext && styles[styleContext]]
      ])}
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}
