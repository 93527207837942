import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsMobile } from '@selectors/appSelectors'
import { getFootnote } from '@selectors/footnoteSelectors'
import { hideFootnote } from '@store/Footnote/footnoteActions'
import { makeClassName } from '@utils/makeClassName'
import { viewportSize } from '@utils/viewportSize'

import { Footnote, Popover } from '@meduza/ui-kit-2'

import styles from './FootnoteContainer.module.css'

/*
 * Контейнер для сноски (сноска находится в ките)
 * при появлении сноски ставится дата-атрибут на сноску, чтобы подсвечивать ее
 */

export const FootnoteContainer = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)
  const footnote = useSelector(getFootnote)
  const isFootnoteExists = Object.keys(footnote).length > 0
  const [{ left, top }, setState] = useState({ left: 0, top: 0 })
  const [footnoteStyle, setFootnoteStyle] = useState<{
    left: string
    top: string
  }>({ left: '0', top: '0' })

  const footnoteRef = useRef<HTMLElement | null>(null)

  const { title, body, originRect } = footnote

  useEffect(() => {
    if (originRect) {
      setFootnoteStyle({
        left: `${left || originRect.left}px`,
        top: `${top || originRect.top}px`
      })
    }
  }, [left, top, originRect])

  useEffect(() => {
    if (isFootnoteExists) {
      const { id, containerRect } = footnote

      footnoteRef.current = document.querySelector(`[data-id="${id}"]`)

      if (footnoteRef.current) {
        footnoteRef.current.dataset.highlight = 'true'
      }

      const { width: viewportWidth } = viewportSize()

      const maxWidth = 400
      let left = viewportWidth - maxWidth
      const top = originRect.top

      if (viewportWidth >= 1024) {
        left = containerRect.left + containerRect.width - maxWidth
      }

      setState({ left, top })
    }

    return () => {
      if (footnoteRef.current) {
        footnoteRef.current.dataset.highlight = 'false'
      }
    }
  }, [footnote, isFootnoteExists, originRect])

  const handleContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const hide = () => {
    dispatch(hideFootnote())
  }

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles.active], isFootnoteExists]
      ])}
      onClick={hide}
    >
      <div className={styles.container} style={footnoteStyle}>
        {isFootnoteExists && (
          <div className={styles.footnote} onClick={handleContainerClick}>
            {isMobile ? (
              <Popover onClose={hide}>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Popover>
            ) : (
              <Footnote onClose={hide}>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Footnote>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
