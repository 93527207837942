import React, { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/enums'
import { Newsletter } from '@client/types'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import {
  resetStatuses,
  subscribeToDaily
} from '@store/CurrentUser/currentUserActions'
import { makeClassName } from '@utils/makeClassName'
import { validateEmail } from '@utils/validateEmail'

import { locale } from './locale'
import { SignalLogo } from './SignalLogo'

import styles from './EmailSubscription.module.css'

/*
 * Форма подписки в материале
 */

export interface EmailSubscriptionProps {
  styleContext: string | string[]
  type: 'signal_subscription' | 'brief_subscription'
}

export type BlockLocale = {
  captcha?: string
  action: string
  label?: string
  title?: string
  second_title: string
  placeholder: string
  success_message: string
  wrong_email_message: string
  something_wrong: string
  theme: string
}

export const EmailSubscription: React.FC<EmailSubscriptionProps> = ({
  styleContext,
  type
}) => {
  const dispatch = useDispatch()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { lang, daily, subscribeToDailyStatus } = useSelector(getCurrentUser)

  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const {
    captcha,
    placeholder,
    action,
    label,
    title,
    second_title,
    success_message,
    wrong_email_message,
    something_wrong,
    theme
  } = locale[type] as BlockLocale

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setEmail(e.target.value)
  }

  const handleSubscribeToDaily = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      if (!executeRecaptcha) {
        return
      }

      const token = await executeRecaptcha('homepage')

      if (token) {
        if (validateEmail(email)) {
          setError(false)
          dispatch(
            subscribeToDaily.request({
              token: token,
              email,
              lang: `daily/${lang}` as Newsletter
            })
          )
        } else {
          setError(true)
        }
      }
    },
    [dispatch, email, executeRecaptcha, lang]
  )

  useEffect(() => {
    if (
      subscribeToDailyStatus === REQUEST_STATUS.SUCCESS ||
      subscribeToDailyStatus === REQUEST_STATUS.FAILURE
    ) {
      dispatch(resetStatuses())
    }
  }, [dispatch, subscribeToDailyStatus])

  const renderSuccessMessage = (message: string, email: string) => {
    const success = email ? message.replace('{USER_EMAIL}', email) : message

    return <span dangerouslySetInnerHTML={{ __html: success }} />
  }

  return (
    <div
      className={makeClassName(
        [
          [styles.root, true],
          [styles.isSuccess, daily.subscribed],
          [styles[theme], theme && !!styles[theme]]
        ].concat(
          Array.isArray(styleContext)
            ? styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
            : [[styles[styleContext], !!styleContext && !!styles[styleContext]]]
        )
      )}
    >
      <div className={styles.body}>
        {theme === 'isSignal' && (
          <div className={styles.logo}>
            <SignalLogo />
          </div>
        )}

        <div className={styles.content}>
          {title && (
            <h3
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          <div className={styles.text}>
            <p
              dangerouslySetInnerHTML={{
                __html: second_title
              }}
            />

            {daily.subscribed && theme === 'isDaily' && (
              <p
                dangerouslySetInnerHTML={{
                  __html: success_message
                }}
              />
            )}
          </div>
        </div>
        <form className={styles.subscribe} onSubmit={handleSubscribeToDaily}>
          <div className={styles.form}>
            <div
              className={makeClassName([
                [styles.field, true],
                [styles.invalid, error || daily.error]
              ])}
            >
              <label htmlFor="email-subscription">
                <span>{label}</span>
              </label>
              <input
                className={styles.input}
                type="email"
                defaultValue={email}
                onInput={handleInput}
                id="email-subscription"
                placeholder={placeholder}
              />
            </div>

            <button
              className={styles.button}
              type="submit"
              aria-label={action}
              disabled={!validateEmail(email)}
            >
              {action}
            </button>
          </div>

          {error && (
            <span
              className={styles.message}
              dangerouslySetInnerHTML={{
                __html: wrong_email_message
              }}
            />
          )}

          {daily.error && (
            <span
              className={styles.message}
              dangerouslySetInnerHTML={{
                __html: something_wrong
              }}
            />
          )}

          {!error && !daily.error && captcha && (
            <div className={styles.description}>
              <p dangerouslySetInnerHTML={{ __html: captcha }} />
            </div>
          )}

          {theme === 'isSignal' && daily.subscribed && (
            <div className={styles.success}>
              {renderSuccessMessage(success_message, email)}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
