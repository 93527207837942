import { createReducer } from '@reduxjs/toolkit'
import { hideFootnote, showFootnote } from '@store/Footnote/footnoteActions'

export interface FootnoteState {
  id: string
  title: string
  body: string
  originRect: {
    top: number
    left: number
  }
  containerRect: {
    x: number
    y: number
    width: number
    height: number
    top: number
    right: number
    bottom: number
    left: number
  }
}

const initialState = {} as FootnoteState

export const footnotesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showFootnote, (state, { payload }) => payload)
    .addCase(hideFootnote, () => initialState)
})
