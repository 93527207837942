import { ScreenDocumentWithMobile } from '@client/types'
import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import { ScreenResponse } from 'types/apiTypes'

export interface ScreenDocumentsState {
  byId: Record<string, ScreenDocumentWithMobile>
  allIds: string[]
}

const initialState: ScreenDocumentsState = {
  byId: {},
  allIds: []
}

const isSuccessScreenAction = (
  action: AnyAction
): action is PayloadAction<{ response: ScreenResponse }> => {
  return action.type.includes('@SCREEN') && action.type.endsWith('SUCCESS')
}

export const screensDocumentsReducer = createReducer(
  initialState,
  (builder) => {
    builder.addMatcher(
      isSuccessScreenAction,
      (
        state,
        {
          payload: {
            response: {
              data: { documents }
            }
          }
        }
      ) => {
        Object.keys(documents).forEach((key) => {
          const storedDocument = state.byId[key]
          const document = documents[key]

          state.allIds.push(key)

          if (!storedDocument || document.version > storedDocument.version) {
            state.byId[key] = document
          }
        })
      }
    )
  }
)
