import React, { useEffect, useRef, useState } from 'react'
import { Lang } from '@client/types'
import { Link } from '@common/Link'
import { makeClassName } from '@utils/makeClassName'
import firebase from 'firebase/compat/app'

import { SvgSymbol } from '@meduza/ui-kit-2'

import i18n from './i18n'

import styles from './Header.module.css'

interface UserDropdownProps {
  lang: Lang
  handleAuthClick: () => void
  handleLogoutClick: () => void
  handleCloseNavClick: () => void
  handleMessageClick: () => void
  isNotify?: boolean
  firebaseUser: firebase.User | null
}

export const UserDropdown: React.FC<UserDropdownProps> = ({
  handleAuthClick,
  lang,
  handleLogoutClick,
  handleCloseNavClick,
  handleMessageClick,
  isNotify,
  firebaseUser
}) => {
  const [profileActive, setProfileActive] = useState(false)
  const [isRendered, setIsRendered] = useState(false)
  const profileRef = useRef<HTMLDivElement>(null)

  const handleProfileClick = () => {
    setProfileActive(!profileActive)
  }

  const handleClickOutside = (event: Event) => {
    if (!profileRef.current) return

    if (event.target instanceof Node) {
      const clickInside =
        profileRef.current.contains(event.target) ||
        profileRef.current === event.target

      setProfileActive(clickInside)
    }
  }

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useEffect(() => {
    if (profileActive && firebaseUser) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }
    return () => document.removeEventListener('click', handleClickOutside)
  }, [profileActive, firebaseUser])

  return isRendered && firebaseUser ? (
    <div
      className={makeClassName([
        [styles.item, true],
        [styles.itemProfile, !!firebaseUser],
        [styles.dropdownIsActive, profileActive]
      ])}
      ref={profileRef}
    >
      <button
        className={styles.profileControl}
        onClick={() => handleProfileClick()}
        aria-label={i18n[lang].profile}
      >
        <SvgSymbol icon="user" size="unset" />
        {isNotify && <span className={styles.notify} />}
      </button>

      <div className={styles.dropdownContainer}>
        <ul className={styles.dropdown}>
          <li className={styles.dropdownItem}>
            <span className={styles.email}>{firebaseUser.email}</span>
          </li>
          <li className={styles.dropdownItem}>
            <Link
              to="/account"
              onClick={handleCloseNavClick}
              styleContext="isInProfile"
            >
              {i18n[lang].profile}
              {isNotify && <span className={styles.notify} />}
            </Link>
          </li>

          <li className={styles.dropdownItem}>
            <Link
              to="/bookmarks"
              onClick={handleCloseNavClick}
              styleContext="isInProfile"
            >
              <>{i18n[lang].bookmarks}</>
            </Link>
          </li>

          <li className={styles.dropdownItem}>
            <button
              className={styles.button}
              onClick={handleMessageClick}
              type="button"
            >
              {i18n[lang].contact}
            </button>
          </li>

          <li className={styles.dropdownItem}>
            <button
              className={styles.button}
              onClick={handleLogoutClick}
              type="button"
            >
              {i18n[lang].logout}
            </button>
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <button
      className={makeClassName([
        [styles.item, true],
        [styles.itemAuth, true]
      ])}
      type="button"
      onClick={handleAuthClick}
      aria-label={i18n[lang].auth}
    >
      {i18n[lang].login}
    </button>
  )
}
