/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToolbarButtonTypes } from '@client/types'
import { StyleContext } from '@client/types'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { getCurrentMaterial } from '@selectors/materialsSelectors'
import { callToAction } from '@store/App/appActions'
import { toggleBookmark } from '@store/CurrentUser/currentUserActions'
import { showModal } from '@store/Modal/modalActions'

import { Toolbar, ToolbarButton, ToolbarItem } from '@meduza/ui-kit-2'

import { MagicButton } from '../MagicButton'

import styles from './ToolbarContainer.module.css'

/*
 * Тулбар с кнопками шеров и кнопкой закладки, который появляется при
 * скролле материала сверху (в TopBar)
 * и есть внизу каждого материала
 */

export type ShareButtonsType = {
  type: ToolbarButtonTypes
  label?: string
}

export interface ToolbarContainerProps {
  url?: string
  place: string
  buttons: {
    type: ToolbarButtonTypes
    label?: string
  }[]
  lang: 'ru' | 'en'
  pdf?: string
  styleContext?: StyleContext
}

export const ToolbarContainerButtons: React.FC<ToolbarContainerProps> = ({
  url,
  buttons,
  lang,
  styleContext,
  place
}) => {
  const dispatch = useDispatch()
  const { firebase_user, bookmarks } = useSelector(getCurrentUser)
  const currentUrl = useSelector(getCurrentMaterial)

  const handleAuth = (url: string) => {
    localStorage.setItem('saved_bookmark', url)

    dispatch(
      showModal({
        type: 'loginBookmark',
        coordinates: window.scrollY
      })
    )
  }

  const handleBookmarkClick = () => {
    if (url) {
      if (firebase_user) {
        dispatch(toggleBookmark(url))
      } else {
        handleAuth(url)
      }
    }
  }

  const isInBookmarks = bookmarks && currentUrl && bookmarks[currentUrl]
  const isDark = styleContext && styleContext.indexOf('dark') > -1

  const theme = isDark ? 'dark' : 'unset'

  return (
    <Toolbar styleContext={styleContext}>
      <>
        {buttons.map((button) => {
          switch (button.type) {
            case 'bookmark': {
              return (
                <ToolbarItem key={button.type}>
                  <ToolbarButton
                    type={button.type}
                    onClick={handleBookmarkClick}
                    theme={theme}
                    icon="shareBookmark"
                    lang={lang}
                    isActive={!!isInBookmarks}
                  />
                </ToolbarItem>
              )
            }

            case 'unblock': {
              return (
                <React.Fragment key={button.type}>
                  {place === 'bottom' && <li className={styles.expand}></li>}
                  <ToolbarItem>
                    <MagicButton url={url} />
                  </ToolbarItem>
                </React.Fragment>
              )
            }

            default: {
              return (
                <ToolbarItem key={button.type}>
                  <ToolbarButton
                    type={button.type}
                    lang={lang}
                    onClick={() =>
                      dispatch(
                        callToAction({
                          place,
                          service: button.type
                        })
                      )
                    }
                    theme={theme}
                  />
                </ToolbarItem>
              )
            }
          }
        })}
      </>
    </Toolbar>
  )
}
