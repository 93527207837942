import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import scheme from './scheme.json'

import styles from './Skeleton.module.css'

type Item = {
  type: string
  items: number
  display: string
  id: string
}

interface SkeletonProps {
  styleContext?: string
  desktopOnly?: boolean
}

export const Skeleton: React.FC<SkeletonProps> = ({
  styleContext = 'isInFeed',
  desktopOnly = false
}) => {
  const renderAnimation = (count: number) => {
    const blocks = []

    for (let i = 0; i < count; i += 1) {
      blocks.push(
        <div className={styles.animation} key={`animation-${i}`}>
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      )
    }

    return blocks
  }

  const renderCard = (i: number) => (
    <div className={styles.card} key={`card-${i}`}>
      {renderAnimation(1)}
    </div>
  )

  const renderSection = (item: Item) => {
    const { type, items, display, id } = item

    const correlation = `is1to${items}`
    const cards = []

    for (let i = 0; i < items; i += 1) {
      cards.push(renderCard(i))
    }

    return (
      <div
        className={makeClassName([
          [[styles.section], true],
          [[styles[type]], !!styles[type]],
          [[styles[correlation]], !!styles[correlation]],
          [[styles[display]], !!styles[display]]
        ])}
        key={id}
      >
        {cards}
      </div>
    )
  }

  const renderFeed = (items: Item[]) => items.map((item) => renderSection(item))

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles[styleContext]], !!styleContext && !!styles[styleContext]],
        [[styles.desktopOnly], !!desktopOnly]
      ])}
    >
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {styleContext === 'isInFeed' &&
              renderFeed(desktopOnly ? scheme.desktop : scheme.root)}

            {styleContext === 'isInChronology' && renderFeed(scheme.chronology)}

            {styleContext === 'isInMaterial' && renderAnimation(2)}
          </div>
        </div>
      </div>
    </div>
  )
}
