import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteUserModal } from '@common/Modal/DeleteUserModal'
import { LoginModal } from '@common/Modal/LoginModal'
import { PostAuthModal } from '@common/Modal/PostAuthModal'
import { ReactionModal } from '@common/Modal/ReactionModal'
import { SearchModal } from '@common/Modal/SearchModal'
import { SendMessageModal } from '@common/Modal/SendMessageModal'
import { TextErrorModal } from '@common/Modal/TextErrorModal'
import { getIsMobile } from '@selectors/appSelectors'
import { getModal } from '@selectors/modalSelectors'
import { hideModal } from '@store/Modal/modalActions'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'

import BackgroundMobile from './assets/bg'

import styles from './Modal.module.css'

/*
 * Универсальный компонент модалки
 * Забирает из стейта внутренний компонент (контейнер) модалки и пропсы и рендерит его с этими пропсами
 */

export const Modal = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)

  const modalState = useSelector(getModal)
  const { type } = modalState

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dispatch(hideModal())
      }
    },
    [dispatch]
  )

  useEffect(() => {
    document.body.dataset.modal = type

    return () => {
      document.body.dataset.modal = 'false'
    }
  }, [type])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    if (isMobile) {
      window.scrollTo(0, 0)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isMobile, handleKeyDown])

  const handleCloseClick = () => {
    dispatch(hideModal())
  }

  const modalName =
    modalState.type === 'loginHeader' || modalState.type === 'loginBookmark'
      ? 'isAuth'
      : `is${capitalize(type)}`

  const renderModalContainer = () => {
    switch (modalState.type) {
      case 'idea':
        return <ReactionModal />
      case 'loginBookmark':
        return (
          <LoginModal
            type={modalState.type}
            coordinates={modalState.coordinates}
          />
        )
      case 'loginHeader':
        return (
          <LoginModal
            type={modalState.type}
            coordinates={modalState.coordinates}
          />
        )
      case 'postAuth':
        return <PostAuthModal coordinates={modalState.coordinates} />
      case 'sendMessage':
        return <SendMessageModal />
      case 'search':
        return <SearchModal />
      case 'deleteUser':
        return <DeleteUserModal />
      case 'textError':
        return <TextErrorModal {...modalState.props} />
    }
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.active, true],
        [styles[modalName], !!styles[modalName]]
      ])}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.dismissOverlay}
        tabIndex={-1}
        onClick={handleCloseClick}
        role="button"
      />

      <div className={styles.content}>
        <div className={styles.background}>
          <BackgroundMobile />
        </div>

        <button
          className={styles.dismiss}
          type="button"
          onClick={handleCloseClick}
        >
          <SvgSymbol icon="cross" size="unset" />
        </button>
        {renderModalContainer()}
      </div>
    </div>
  )
}
