export const popupWindow = (
  url: string,
  title: string,
  w: number,
  h: number
): Window | null => {
  const left = window.screen.width / 2 - w / 2
  const top = window.screen.height / 2 - h / 2
  const options = [
    'toolbar=no',
    'location=no',
    'directories=no',
    'status=no',
    'menubar=no',
    'scrollbars=no',
    'resizable=no',
    'copyhistory=no',
    `width=${w}`,
    `height=${h}`,
    `top=${top}`,
    `left=${left}`
  ]

  return window.open(url, title, options.join(', '))
}
