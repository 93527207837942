import React from 'react'
import { Transition as AlertTransition } from 'react-transition-group'

import { TRANSITIONS } from './constants'

const duration = 250

const defaultStyle = {
  [TRANSITIONS.FADE]: {
    transition: `opacity ${duration}ms ease`,
    opacity: 0
  },
  [TRANSITIONS.FADE_IN]: {
    transition: `all ${duration}ms ease`,
    transform: 'translateY(0)',
    opacity: 0
  },
  [TRANSITIONS.SCALE]: {
    transform: 'scale(1)',
    transition: `all ${duration}ms ease-in-out`
  }
}

const transitionStyles = {
  [TRANSITIONS.FADE]: {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: {},
    exited: {},
    unmounted: {}
  },
  [TRANSITIONS.FADE_IN]: {
    entering: {
      opacity: 0,
      height: 0,
      transform: 'translateY(-48px)'
    },
    entered: { opacity: 1, height: '55px', transform: 'translateY(-48px)' },
    exiting: {},
    exited: {},
    unmounted: {}
  },
  [TRANSITIONS.SCALE]: {
    entering: { transform: 'scale(0)' },
    entered: { transform: 'scale(1)' },
    exiting: { transform: 'scale(0)' },
    exited: { transform: 'scale(1)' },
    unmounted: {}
  }
}

export interface TransitionProps {
  children: React.ReactNode
  type: typeof TRANSITIONS[keyof typeof TRANSITIONS]
}

const Transition: React.FC<TransitionProps> = ({
  children,
  type,
  ...props
}) => (
  <AlertTransition {...props} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle[type],
          ...transitionStyles[type][state]
        }}
      >
        {children}
      </div>
    )}
  </AlertTransition>
)

export default Transition
