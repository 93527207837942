import React, { memo, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { ExchangeRatesData } from '@client/types'
import { getExchangeRates } from '@selectors/appSelectors'
import { fetchExchangeRates } from '@store/App/appActions'
import { makeClassName } from '@utils/makeClassName'
import equal from 'fast-deep-equal/es6'

import { SvgSymbol } from '@meduza/ui-kit-2'

import styles from './ExchangeRates.module.css'

/*
 * Курс валют (используется в InfoPanel - десктоп и в ExchangeBlock - мобила
 */

type ExchangeRatesKeys = 'usd' | 'eur' | 'brent'

const exchangeRatesKeys: ExchangeRatesKeys[] = ['usd', 'eur']

interface ExchangeRatesProps {
  styleContext?: string
}

export const ExchangeRatesComponent: React.FC<ExchangeRatesProps> = ({
  styleContext
}) => {
  const dispatch = useDispatch()

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  const exchangeRates: ExchangeRatesData | undefined = useSelector(
    getExchangeRates,
    equal
  )

  useEffect(() => {
    if (!exchangeRates && inView) {
      dispatch(fetchExchangeRates.request())
    }
  }, [dispatch, exchangeRates, inView])

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [
          !!styleContext && styles[styleContext],
          !!styleContext && !!styles[styleContext]
        ]
      ])}
      ref={ref}
    >
      {exchangeRates &&
        exchangeRatesKeys.map((key) => (
          <div
            key={key}
            className={makeClassName([
              [styles.item, true],
              [styles[key], !!styles[key]]
            ])}
          >
            {key === 'brent' && <SvgSymbol icon="brent" size="unset" />}

            {exchangeRates[key].current.toFixed(2).replace('.', ',')}
          </div>
        ))}
    </div>
  )
}

export const ExchangeRates = memo(ExchangeRatesComponent)

ExchangeRates.whyDidYouRender = true
