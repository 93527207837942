import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CallToActions, StyleContext } from '@client/types'
import { AudioPanel } from '@common/AudioPlayer/AudioPanel'
import { LightboxContext } from '@common/Lightbox/types'
import { EpisodeCover } from '@common/RenderBlocks/EpisodeCover'
import { Episodes } from '@common/RenderBlocks/Episodes'
import { StudioStub } from '@common/RenderBlocks/StudioStub'
import { ToolbarContainer } from '@common/ToolbarContainer'
import { getLang } from '@selectors/currentUserSelectors'
import { callToAction } from '@store/App/appActions'
import { Block } from 'types/Blocks'

import { RawHtmlBlock } from '@meduza/ui-kit-2'

import { Chapters } from '../Chapters'

import { PodcastSeason } from './PodcastSeason/PodcastSeason'
import { BeetSubscription } from './BeetSubscription'
import { EmailSubscription } from './EmailSubscription'
import { EmbedContainer } from './EmbedContainer'

/*
 * Компонент, который рендерит блоки для материалов
 */

export interface RenderBlocksProps {
  block: Block
  url?: string
  isRead?: boolean
  isListened?: boolean
  isInBookmarks?: boolean
  styleContext: StyleContext
  lightBox?: LightboxContext | null
  gameSkipIntro?: boolean
}

export const RenderBlocks: React.FC<RenderBlocksProps> = ({
  url,
  block,
  isRead,
  isListened,
  isInBookmarks,
  styleContext,
  lightBox,
  gameSkipIntro
}) => {
  const dispatch = useDispatch()
  const lang = useSelector(getLang)

  switch (block.type) {
    case 'donation':
      return null

    case 'audio': {
      return (
        <AudioPanel
          key={block.id}
          episodeUrl={block.data.url || block.data.audio.url}
          block={block}
          lang={lang}
          theme={block.data.theme}
          styleContext={styleContext as string}
        />
      )
    }

    case 'chapter': {
      return (
        <Chapters
          block={block?.data}
          contents={block?.tags}
          key={block.id}
          display={block?.display}
          lang={lang}
        />
      )
    }

    case 'episodes': {
      return (
        <Episodes
          key={block.id}
          data={block.data}
          shouldUsePagination={true}
          pagination={block.pagination}
          lang={lang}
        />
      )
    }

    case 'podcast_seasons': {
      return <PodcastSeason key={block.id} data={block.data} lang={lang} />
    }

    case 'episode_cover': {
      return (
        <EpisodeCover
          key={block.id}
          block={block}
          styleContext={styleContext}
        />
      )
    }

    case 'share': {
      const buttons = block.data

      return (
        <ToolbarContainer
          url={url}
          place="bottom"
          buttons={buttons}
          lang={lang}
          styleContext={styleContext}
        />
      )
    }

    case 'start_game_btn': {
      return (
        <EmbedContainer
          key={block.id}
          block={block}
          skipIntro={gameSkipIntro || false}
        />
      )
    }

    case 'tr_studio_stub': {
      return <StudioStub block={block.data} key={block.id} />
    }

    case 'beet_subscription': {
      return <BeetSubscription />
    }

    case 'brief_subscription':
    case 'signal_subscription': {
      return <EmailSubscription styleContext={styleContext} type={block.type} />
    }

    default: {
      return (
        <RawHtmlBlock
          key={block.id}
          block={block}
          styleContext={styleContext}
          isRead={isRead}
          isListened={isListened}
          isInBookmarks={isInBookmarks}
          bookmarkAction={(service: CallToActions, place: string) =>
            dispatch(callToAction({ service, place }))
          }
          lightBox={lightBox}
          lang={lang}
        />
      )
    }
  }
}
