import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ScreenViewType } from '@client/constants'
import { changeScreenView } from '@store/App/appActions'
import { localStorageService } from '@utils/localStorageService'

import { ListViewSwitcher } from '@meduza/ui-kit-2'

import styles from './ViewSwitcherContainer.module.css'

interface ViewSwitcherContainerProps {
  theme?: 'light' | 'dark'
  size: 'sm' | 'md'
  label?: string
}

export const ViewSwitcherContainer: React.FC<ViewSwitcherContainerProps> = ({
  size,
  label
}) => {
  const dispatch = useDispatch()
  const [type, setType] = useState(
    localStorageService.get('screenViewType') || ScreenViewType.GRID
  )

  const handleChange = () => {
    const newType =
      type === ScreenViewType.LIST ? ScreenViewType.GRID : ScreenViewType.LIST
    localStorageService.set('screenViewType', newType)
    dispatch(changeScreenView({ screenViewType: newType }))
    setType(newType)
  }

  return (
    <div className={styles.root}>
      {label && (
        <button className={styles.label} onClick={handleChange}>
          {label}
        </button>
      )}
      <ListViewSwitcher
        enabled={type === ScreenViewType.LIST}
        size={size}
        onChange={handleChange}
      />
    </div>
  )
}
