import React from 'react'
import { ImageUrls, ScreenDocument } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import { TopicBlockItem } from './TopicBlockItem'

import styles from './TopicBlock.module.css'

interface TopicBlockProps {
  correlation: keyof ImageUrls
  blocks: ScreenDocument[]
}

export const TopicBlock: React.FC<TopicBlockProps> = ({
  blocks,
  correlation
}) => {
  const [first, ...rest] = blocks
  const items = correlation === 'is1to1' ? rest : blocks

  const hasTag = first && first.tag

  return (
    <section
      className={makeClassName([
        [styles.root, true],
        [styles.hasTag, !!hasTag],
        [styles[correlation], !!styles[correlation]]
      ])}
    >
      {correlation === 'is1to1' && (
        <div className={styles.featured}>
          <TopicBlockItem block={first} correlation={correlation} featured />
        </div>
      )}
      <ul className={styles.list}>
        {items.map((d) => (
          <li key={d.url} className={styles.item}>
            <TopicBlockItem block={d} correlation={correlation} />
          </li>
        ))}
      </ul>
    </section>
  )
}
