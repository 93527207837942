import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setIsEmbedded, setIsInApp, setIsPreview } from '@store/App/appActions'

/*
хук для статуса из queryString
эти параметры могут приходить в строке например если сайт открыт в webview
сделано для того чтобы убирать/добавлять какие-то элементы сайта
*/

export const useSetAppParams = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()

  const urlParams = new URLSearchParams(search)

  if (urlParams.has('inapp')) {
    dispatch(setIsInApp())
  }

  if (urlParams.has('embedded')) {
    dispatch(setIsEmbedded())
  }

  if (urlParams.has('preview')) {
    dispatch(setIsPreview())
  }
}
