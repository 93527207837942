import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MediaQuerySizes } from '@client/constants'
import { getIsMobile } from '@selectors/appSelectors'
import { setIsMobile } from '@store/App/appActions'
import useResizeObserver from 'use-resize-observer/polyfilled'

/*
хук для того, чтобы определять мобила или нет
*/

export const useWatchIsMobile = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)

  const sizeHandler = useCallback(
    (size: { width: number | undefined; height: number | undefined }) => {
      if (size.width && size.width < MediaQuerySizes.LANDSCAPE_TABLET) {
        if (!isMobile) dispatch(setIsMobile(true))
        return
      } else if (size.width && size.width >= MediaQuerySizes.LANDSCAPE_TABLET) {
        if (isMobile) dispatch(setIsMobile(false))
        return
      }
    },
    [dispatch, isMobile]
  )

  const onResize = useMemo(() => sizeHandler, [sizeHandler])

  const { ref } = useResizeObserver({ onResize })

  return ref
}
