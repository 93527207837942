import React from 'react'
import { Lang } from '@client/types'

import { SvgSymbol } from '@meduza/ui-kit-2'

import i18n from './i18n'

import styles from './GDPRPanel.module.css'

interface GDPRPanelProps {
  lang: Lang
  closeGdrp: () => void
}

export const GDPRPanel: React.FC<GDPRPanelProps> = ({ lang, closeGdrp }) => (
  <div className={styles.root}>
    <div className={styles.text}>
      {i18n[lang].text}{' '}
      <a href={i18n[lang].link} target="_blank" rel="noopener noreferrer">
        {i18n[lang].linkText}
      </a>
    </div>
    <button
      type="button"
      className={styles.dismiss}
      onClick={closeGdrp}
      aria-label={i18n[lang].label}
    >
      <SvgSymbol icon="cross" size="medium" />
    </button>
  </div>
)
