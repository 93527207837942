import React, { ChangeEvent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Chronology } from '@routes/Screen/Chronology'
import { getLang } from '@selectors/currentUserSelectors'
import { getLastQuery, getLastResult } from '@selectors/searchSelectors'
import { hideModal } from '@store/Modal/modalActions'
import { fetchSearch, search } from '@store/Search/searchActions'

import i18n from './i18n'

import styles from './SearchModal.module.css'

export const SearchModal = () => {
  const dispatch = useDispatch()
  const lang = useSelector(getLang)
  const query = useSelector(getLastQuery)
  const result = useSelector(getLastResult)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
  }, [])

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(search(e.target.value))
  }

  const handleFetchNext = () => {
    if (result.has_next) {
      dispatch(
        fetchSearch.request({ query, page: result.page, lang, loadPage: false })
      )
    }
  }

  const handleItemClick = () => {
    dispatch(hideModal())
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.search}>
          <input
            className={styles.field}
            type="text"
            ref={inputRef}
            value={query}
            onChange={handleSearchInput}
          />
        </div>
      </div>

      <div className={styles.result}>
        {result && result.requested && result.ids.length === 0 && (
          <div
            className={styles.message}
            dangerouslySetInnerHTML={{ __html: i18n[lang].nothing }}
          />
        )}
        {query && result && result.requested && result.ids.length > 0 && (
          <Chronology
            lang={lang}
            fetchNext={handleFetchNext}
            styleContext="isInSearch"
            handleItemClick={handleItemClick}
          />
        )}
      </div>
    </div>
  )
}
