import { createReducer } from '@reduxjs/toolkit'
import { fetchMaterial } from '@store/Materials/materialsActions'
import { fetchScreen, fetchSpecials } from '@store/Screens/screensActions'

export interface BannersState {
  top?: boolean
  left?: boolean
  right?: boolean
  bottom?: boolean
}

const initialState: BannersState = {}

export const bannersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchScreen.success,
      (
        state,
        {
          payload: {
            response: {
              data: { banners }
            }
          }
        }
      ) => banners
    )
    .addCase(
      fetchSpecials.success,
      (
        state,
        {
          payload: {
            response: {
              data: { banners }
            }
          }
        }
      ) => banners
    )
    .addCase(
      fetchMaterial.success,
      (
        state,
        {
          payload: {
            response: {
              data: {
                root: { banners }
              }
            }
          }
        }
      ) => banners
    )
})
