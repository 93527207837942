import React from 'react'
import { useDispatch } from 'react-redux'
import { LoginSuccessImage } from '@common/Modal/LoginSuccessImage'
import { hideModal } from '@store/Modal/modalActions'

import { Button } from '@meduza/ui-kit-2'

import styles from './Modal.module.css'

interface PostAuthModalProps {
  coordinates: number
}

export const PostAuthModal: React.FC<PostAuthModalProps> = ({
  coordinates
}) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    window.scrollTo(0, coordinates)
    dispatch(hideModal())
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Ура!</h3>
        <div className={styles.subtitle}>
          Вы&nbsp;успешно вошли в&nbsp;профиль.
          <br />
          Теперь закладки будут доступны на&nbsp;всех ваших устройствах.
        </div>
      </div>
      <LoginSuccessImage />
      <Button theme="gold" onClick={handleClick}>
        Продолжить читать
      </Button>
    </div>
  )
}
