import { utmMedium, utmSource } from '@client/constants'
import { MaterialContent, ScreenContent, ShareServices } from '@client/types'
import { popupWindow } from '@utils/popupWindow'

const targets: Record<
  string,
  {
    counter?: string
    share: string
    response?: string
  }
> = {
  fb: {
    share: '//www.facebook.com/sharer/sharer.php?u={{href}}'
  },

  tw: {
    share: '//twitter.com/intent/tweet?text={{text}}&url={{href}}&via={{via}}'
  },

  tg: {
    share:
      '//telegram.me/share/url?url={{href}}&text=Канал%20«Медузы»:%20@meduzalive'
  },

  reddit: {
    share: 'http://www.reddit.com/submit?url={{href}}&title={{text}}'
  },

  linkedin: {
    share: 'https://www.linkedin.com/feed/?shareActive=true&shareUrl={{href}}'
  }
}

export const sharePopupLegacy = (
  service: ShareServices,
  material: MaterialContent | ScreenContent
) => {
  if (service === 'pdf') {
    const path = window.location.origin + material.og.pdf?.standard.path

    window.open(path, 'Download')

    return
  }

  const { url: materialUrl, title, second_title } = material as MaterialContent

  if (service === 'email') {
    const text = `${title} ${second_title ? ` ${second_title}` : ''}`

    const email = `mailto:?subject=Meduza&body=${text} ${window.location.href}`
    window.open(email, 'Email')
    return
  }

  if (service === 'copy') {
    navigator.clipboard.writeText(window.location.href)
    return
  }

  const isEng = materialUrl.startsWith('en/')

  const via = isEng ? 'meduza_en' : 'meduzaproject'

  const [origin] = window.location.href.split('?')
  let href = origin

  href += `?utm_source=${utmSource[service]}`
  href += `&utm_medium=${utmMedium[service]}`
  href += '&utm_campaign=share'

  let url = targets[service].share.replace(
    /\{\{href\}\}/,
    window.encodeURIComponent(href)
  )

  url = url.replace(/\{\{text\}\}/, window.encodeURIComponent(document.title))
  url = url.replace(/\{\{via\}\}/, via)

  popupWindow(url, 'Share', 480, 320)
}
