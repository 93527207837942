import React from 'react'
import { Block, BookLandingMaterialContent } from '@client/types'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { useTrackScroll } from '@hooks/useTrackScroll'
import { makeClassName } from '@utils/makeClassName'
import { tryPushBanner } from '@utils/tryPushBanner'

import styles from './BookLandingMaterial.module.css'

export interface BookLandingMaterialProps {
  material: BookLandingMaterialContent
}

export const BookLandingMaterial: React.FC<BookLandingMaterialProps> = ({
  material
}) => {
  const lightBox = useLightbox()

  const {
    content,
    layout,
    container: { display, mods = [] },
    banners,
    image,
    url,
    book
  } = material

  const { ref } = useTrackScroll(url)

  const theme = book.theme.text_color === '0,0,0' ? 'isDark' : 'isLight'

  const modifiers: string[] = [layout, theme]

  if (display) modifiers.push(display)

  if (mods) modifiers.splice(mods.length, 0, ...mods)

  const classes = [[styles.root, true]].concat(
    Array.isArray(modifiers)
      ? modifiers.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[modifiers], !!modifiers && !!styles[modifiers]]]
  )

  const cssProps = {
    '--bgColor': `rgb(${book.theme.background_color || '0, 0, 0'})`
  } as React.CSSProperties

  return (
    <div className={makeClassName(classes)} ref={ref}>
      <div className={styles.container}>
        <div className={styles.head} style={cssProps}>
          <div className={styles.materialHeader}>
            <div className={styles.image}>
              <img src={image.small_url} alt={material.title} />
            </div>

            {content.head.map((block: Block) => {
              return (
                <RenderBlocks
                  url={material.url}
                  key={block.id}
                  block={block}
                  styleContext={['rich', 'isCustom']}
                />
              )
            })}

            <div className={styles.cta}>
              <a
                href={book.button.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                {book.button.text}
              </a>
            </div>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.article}>
            {content.blocks.map((block) => (
              <RenderBlocks
                url={url}
                key={block.id}
                block={block as Block}
                styleContext={modifiers}
                lightBox={lightBox}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
