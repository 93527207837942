import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './FormInput.module.css'

interface FormInputProps {
  size: 'small' | 'medium'
  styleContext?: string
  disabled?: boolean
  placeholder: string
  type?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  invalid: boolean
  value: string
}

export const FormInput: React.FC<FormInputProps> = ({
  size = 'medium',
  styleContext,
  disabled = false,
  placeholder,
  type = 'text',
  invalid = false,
  onChange,
  onKeyPress,
  value
}) => {
  return (
    <input
      className={makeClassName([
        [[styles.root], true],
        [[styles[size]], !!size],
        [[styles.invalid], invalid],
        [!!styleContext && styles[styleContext], !!styleContext]
      ])}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onKeyPress={onKeyPress && onKeyPress}
      value={value}
    />
  )
}
