import React, { useState } from 'react'
import { useNewsletterSubscription } from '@client/hooks/useNewsletterSubscription'
import { makeClassName } from '@utils/makeClassName'
import { validateEmail } from '@utils/validateEmail'

import { BeetLogo } from './BeetLogo'
import { locale } from './locale'

import styles from './BeetSubscription.module.css'

/*
 * Форма подписки на рассылку Beet в материале
 */

export type BlockLocale = {
  captcha: string
  action: string
  label?: string
  title: string
  second_title: string
  placeholder: string
  success_message: string
  wrong_email_message: string
  something_wrong: string
}

export const BeetSubscription = () => {
  const [email, setEmail] = useState('')

  const {
    handleSubscribe,
    daily,
    error,
    setError
  } = useNewsletterSubscription()

  const {
    captcha,
    placeholder,
    action,
    label,
    title,
    second_title,
    success_message,
    wrong_email_message,
    something_wrong
  } = locale.beet_subscription as BlockLocale

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setEmail(e.target.value)
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.isSuccess, daily.subscribed]
      ])}
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.tag}>Monthly newsletter</span>
            <div className={styles.logo}>
              <BeetLogo />
            </div>
          </div>

          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className={styles.text}>
            <p
              dangerouslySetInnerHTML={{
                __html: second_title
              }}
            />
          </div>
        </div>
        <form
          className={styles.subscribe}
          onSubmit={(event) => handleSubscribe(email, event)}
        >
          <div
            className={makeClassName([
              [styles.form, true],
              [styles.invalid, error || daily.error]
            ])}
          >
            {daily.subscribed && (
              <p
                className={styles.success}
                dangerouslySetInnerHTML={{
                  __html: success_message
                }}
              />
            )}
            <label htmlFor="beet-subscription">
              <span>{label}</span>
            </label>
            <input
              className={styles.input}
              type="email"
              defaultValue={email}
              onInput={handleInput}
              id="beet-subscription"
              placeholder={placeholder}
            />
            <button
              className={styles.button}
              type="submit"
              aria-label={action}
              disabled={!validateEmail(email)}
            >
              {action}
            </button>
          </div>

          <div className={styles.footer}>
            {(error || daily.error) && (
              <span
                className={styles.message}
                dangerouslySetInnerHTML={{
                  __html: error ? wrong_email_message : something_wrong
                }}
              />
            )}

            <div className={styles.description}>
              <p dangerouslySetInnerHTML={{ __html: captcha }} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
