import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { EmbedMaterialContent } from '@client/types'
import { RenderBlocks } from '@common/RenderBlocks'
import { Spinner } from '@common/Spinner'
import { getMaterialEmbedById } from '@selectors/materialsSelectors'
import { makeClassName } from '@utils/makeClassName'

import styles from './EmbedMaterial.module.css'

export interface EmbedMaterialProps {
  material: EmbedMaterialContent
}

export const EmbedMaterial: React.FC<EmbedMaterialProps> = ({ material }) => {
  const {
    location: { search }
  } = useHistory()

  const skipIntro = search.indexOf('skip_intro') > -1
  const embed = useSelector(getMaterialEmbedById)[material.url]

  const {
    content,
    layout,
    container,
    url,
    container: { display }
  } = material

  const state =
    (embed && embed.started) ||
    skipIntro ||
    (container.intro && container.intro.skip)
      ? 'ingame'
      : 'intro'

  const data = container[state].desktop

  const classes = [
    [styles.root, true],
    [[styles[state]], true],
    [[styles.loaded], embed && embed.loaded]
  ].concat(
    Array.isArray(data.mods)
      ? data.mods.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[data.mods], !!data.mods && !!styles[data.mods]]]
  )

  const backgroundStyles: { backgroundImage?: string } = {}

  if (data.image && data.image.url) {
    backgroundStyles.backgroundImage = `url(${data.image.url})`
  }

  return (
    <div className={makeClassName(classes)}>
      {data.image && data.image.url && (
        <div className={styles.background} style={backgroundStyles} />
      )}
      <div className={styles.container}>
        {content.blocks.map((block) => (
          <RenderBlocks
            url={url}
            key={block.id}
            block={block}
            gameSkipIntro={skipIntro}
            styleContext={[display, layout]}
          />
        ))}

        {embed && !embed.loaded && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
