import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/enums'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import {
  resetStatuses,
  subscribeToDaily
} from '@store/CurrentUser/currentUserActions'
import { validateEmail } from '@utils/validateEmail'

/*
  Хук для подписки на рассылку
  WIP: сейчас работает только для рассылки Beet
*/

export const useNewsletterSubscription = () => {
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [error, setError] = useState(false)

  const { subscribeToDailyStatus, daily } = useSelector(getCurrentUser)

  const handleSubscribe = async (email: string, event: React.FormEvent) => {
    event.preventDefault()

    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('homepage')

    if (token) {
      if (validateEmail(email)) {
        setError(false)
        dispatch(
          subscribeToDaily.request({
            token: token,
            email,
            lang: 'beet'
          })
        )
      } else {
        setError(true)
      }
    }
  }

  useEffect(() => {
    if (
      subscribeToDailyStatus === REQUEST_STATUS.SUCCESS ||
      subscribeToDailyStatus === REQUEST_STATUS.FAILURE
    ) {
      dispatch(resetStatuses())
    }
  }, [dispatch, subscribeToDailyStatus])

  return {
    handleSubscribe,
    daily,
    error,
    setError
  }
}
