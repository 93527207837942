export default {
  ru: {
    search: 'Поиск',
    nothing:
      '<strong>Ничего не нашлось</strong> Попробуйте как-нибудь по-другому'
  },

  en: {
    search: 'Search',
    nothing: '<strong>Nothing was found</strong> Try something else'
  }
}
