export enum REQUEST_STATUS {
  SUCCESS = 'success',
  LOADING = 'loading',
  FAILURE = 'failure',
  IDLE = 'idle'
}

export enum COLLECTIONS {
  HISTORY = 'history',
  EPISODE_HISTORY_SHELF = 'episode_history_shelf',
  BOOKMARKS = 'bookmarks'
}
