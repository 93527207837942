import React from 'react'
import loadable, { LoadableComponent } from '@loadable/component'
import { CareProps } from '@routes/Specials/Care/Care'

export const specialComponents: Record<
  string,
  | LoadableComponent<{ children: React.ReactNode }>
  | LoadableComponent<CareProps>
> = {
  cometogether: loadable(() => import('./Cometogether')),
  daily: loadable(() => import('./Daily')),
  care: loadable(() => import('./Care')),
  blog: loadable(() => import('./Blog')),
  'games-revival': loadable(() => import('./GamesRevival'))
}
