/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { StudioStubData } from '@client/types'

import styles from './StudioStub.module.css'

interface StudioStabProps {
  block: StudioStubData
}

export const StudioStub: React.FC<StudioStabProps> = ({ block }) => {
  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <img
          src="https://meduza.io/image/attachments/images/006/805/142/original/IHNx3FtSrR3Ay0kgui8O9A.png"
          alt="Техника Речи"
        />
      </div>
      <a className={styles.button} href={block.link} target="_blank">
        Перейти на сайт
      </a>
    </div>
  )
}
