import React from 'react'
import { BrandImageData, BrandMaterialContent } from '@client/types'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { makeClassName } from '@utils/makeClassName'

import styles from './BrandMaterial.module.css'

export interface BrandMaterialProps {
  material: BrandMaterialContent
}

export const BrandMaterial: React.FC<BrandMaterialProps> = ({ material }) => {
  const lightBox = useLightbox()

  const renderBrandImage = (
    image: BrandImageData,
    responsive: boolean,
    device: string
  ) => {
    const style: { paddingBottom?: string } = {}

    if (responsive) {
      style.paddingBottom = `${
        (80 / image.original_width) * image.original_height
      }%`
    }

    let show = 'min-width: 512px'
    let hide = 'max-width: 512px'

    if (device === 'mobile') {
      show = 'max-width: 512px'
      hide = 'min-width: 512px'
    }

    return (
      <picture
        className={makeClassName([
          [[styles.image], true],
          [[styles[device]], true],
          [[styles.responsive], responsive]
        ])}
        style={style}
        key={`banner-${image.retina_url}-${device}`}
      >
        {device === 'desktop' && (
          <source media={`(${show})`} srcSet={` ${image.retina_url} `} />
        )}

        {device === 'mobile' && (
          <source
            media={`(${show})`}
            srcSet={`  ${image.url} 1x, ${image.retina_url} 2x `}
          />
        )}

        <source
          media={`(${hide})`}
          srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
        />
        <img src={image.url} alt="" />
      </picture>
    )
  }

  const renderBrandBanner = (
    source: {
      mobile: BrandImageData
      desktop: BrandImageData
      url?: string
    },
    name: string,
    responsive: boolean
  ) => {
    const brandImages = []

    if (source.mobile) {
      brandImages.push(renderBrandImage(source.mobile, responsive, 'mobile'))
    }

    if (source.desktop) {
      brandImages.push(renderBrandImage(source.desktop, responsive, 'desktop'))
    }

    /* eslint-disable react/jsx-no-target-blank  */
    if (source.url) {
      return (
        <a
          className={makeClassName([
            [[styles.banner], true],
            [[styles[name]], true]
          ])}
          href={source.url}
          target="_blank"
        >
          {brandImages}
        </a>
      )
    }
    /* eslint-enable react/jsx-no-target-blank  */

    return (
      <div
        className={makeClassName([
          [[styles.banner], true],
          [[styles[name]], true]
        ])}
      >
        {brandImages}
      </div>
    )
  }

  const {
    content,
    layout,
    url,
    container: {
      display,
      background: { color, top, bottom },
      mods
    }
  } = material

  const modifiers = Array.isArray(mods) ? mods.slice() : mods

  modifiers.push(display)
  modifiers.push(layout)

  const classes = [[styles.root, true]].concat(
    Array.isArray(modifiers)
      ? modifiers.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[modifiers], !!modifiers && !!styles[modifiers]]]
  )

  return (
    <div className={makeClassName(classes)} style={{ backgroundColor: color }}>
      {top && renderBrandBanner(top, 'top', true)}

      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.materialHeader}>
            {content.head.map((block) => (
              <RenderBlocks
                url={url}
                key={block.id}
                block={block}
                lightBox={lightBox}
                styleContext={modifiers}
              />
            ))}
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.article}>
            {content.blocks.map((block) => (
              <RenderBlocks
                url={url}
                key={block.id}
                block={block}
                lightBox={lightBox}
                styleContext={modifiers}
              />
            ))}
          </div>
        </div>
      </div>

      {bottom && renderBrandBanner(bottom, 'bottom', false)}
      <div className={styles.footer}>
        <a href="https://prodano.media/" target="_blank" rel="noreferrer">
          Сделано в «Продано!»
        </a>
      </div>
    </div>
  )
}
