import React, { useEffect, useState } from 'react'
import { useIsRendered } from '@client/hooks/useIsRendered'
import { getRandomInt } from '@client/utils/getRandomInt'

import { DFPBannerData } from './DFPBanner.data'

import styles from './DFPBanner.module.css'

export const DFPBanner = () => {
  const { isRendered } = useIsRendered()
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    const randomId = getRandomInt(0, DFPBannerData.length - 1)

    setBanner(DFPBannerData[randomId])
  }, [isRendered])

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {banner && (
          <a
            className={styles.link}
            href={banner.href}
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.image} src={banner.image} alt="" />
          </a>
        )}
      </div>
    </div>
  )
}
