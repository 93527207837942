import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { BookmarkDocument, ScreenDocument } from '@client/types'
import { Layout } from '@components/Layout'
import { ChronologyItem } from '@routes/Screen/Chronology'
import { getBookmarks } from '@selectors/bookmarksSelectors'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { fetchBookmarks } from '@store/Bookmarks/bookmarksActions'
import { arraysMatch } from '@utils/arraysMatch'
import { makeClassName } from '@utils/makeClassName'

import styles from './Bookmarks.module.css'
import { Helmet } from 'react-helmet-async'

export const Bookmarks: React.FC<RouteConfigComponentProps> = ({
  location: { pathname },
  route
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { bookmarks: userBookmarks, firebase_user } =
    useSelector(getCurrentUser)

  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useEffect(() => {
    if (!firebase_user) {
      history.push('/')
    }
  }, [history, firebase_user])

  const storageBookmarkDocuments =
    isRendered && localStorage.getItem('bookmarks')
  const stateBookmarkDocuments = useSelector(getBookmarks)

  const bookmarkDocuments: Record<string, BookmarkDocument> =
    storageBookmarkDocuments
      ? JSON.parse(storageBookmarkDocuments)
      : stateBookmarkDocuments

  useEffect(() => {
    if (
      bookmarkDocuments &&
      !arraysMatch(Object.keys(userBookmarks), Object.keys(bookmarkDocuments))
    ) {
      dispatch(fetchBookmarks.request(userBookmarks))
    }
  }, [dispatch, bookmarkDocuments, userBookmarks])
  const hasBookmarks = Object.keys(bookmarkDocuments).length > 0

  return (
    <Layout
      pathname={pathname}
      routeName={route && route.name}
      type="bookmarks"
    >
      <Helmet titleTemplate="%s">
        <title>Закладки</title>
      </Helmet>

      <div className={makeClassName([[styles.root, true]])}>
        <h2 className={styles.title}>Закладки</h2>

        {hasBookmarks ? (
          <div className={styles.wrapper}>
            {Object.keys(bookmarkDocuments)
              .sort(
                (a, b) =>
                  bookmarkDocuments[b].timeAdded -
                  bookmarkDocuments[a].timeAdded
              )
              .map((key) => {
                const document = bookmarkDocuments[key]
                return (
                  <div className={styles.item} key={document.url}>
                    <ChronologyItem document={document as ScreenDocument} />
                  </div>
                )
              })}
          </div>
        ) : (
          <div className={styles.empty}>
            Похоже, у вас пока что нет закладок
          </div>
        )}
      </div>
    </Layout>
  )
}
