import { createReducer } from '@reduxjs/toolkit'
import {
  embedLoaded,
  embedStarted,
  embedUnload
} from '@store/Materials/materialsActions'

export interface MaterialEmbedState {
  byId: Record<
    string,
    {
      started: boolean
      loaded: boolean
    }
  >
}

const initialState: MaterialEmbedState = {
  byId: {}
}

export const embedReducer = createReducer(initialState, (builder) => {
  builder.addCase(embedStarted, (state, { payload }) => {
    state.byId[payload] = {
      started: true,
      loaded: false
    }
  })
  builder.addCase(embedLoaded, (state, { payload }) => {
    state.byId[payload].loaded = true
  })
  builder.addCase(embedUnload, (state, { payload }) => {
    delete state.byId[payload]
  })
})
