import Bugsnag from '@bugsnag/js'

export const handleError = (e: Error, context?: string) => {
  console.log(e)
  console.log(Bugsnag)
  Bugsnag.notify(e, (event) => {
    if (context) {
      event.context = context
    }
  })
}
