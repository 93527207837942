import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLang } from '@selectors/currentUserSelectors'
import { getMaterials } from '@selectors/materialsSelectors'
import {
  fetchTopbarAds,
  topbarAdBecomeVisible,
  topbarAdClicked
} from '@store/Materials/materialsActions'
import { makeClassName } from '@utils/makeClassName'
import dayjs from 'dayjs'

import { SvgSymbol } from '@meduza/ui-kit-2'

import 'dayjs/locale/ru'

import styles from './AdvPanel.module.css'

/*
 * Рекламная панель исключительно для для мобилы
 * Появляется раз в сутки, основываясь на переменной в localStorage
 */

export const AdvPanel: React.FC = () => {
  const dispatch = useDispatch()

  const {
    topbarAds: { url, source, title }
  } = useSelector(getMaterials)

  const lang = useSelector(getLang)

  const [withAdv, setWithAdv] = useState(false)
  const [visible, setVisible] = useState(false)
  const [closed, setClosed] = useState(false)
  const [timestampMatched, setTimestampMatched] = useState(false)
  const [adVisibilityTracked, setAdVisibilityTracked] = useState(false)
  const today = dayjs()

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const { timestamp } = JSON.parse(
      localStorage.getItem('mdzAdvPanel') || '{ "timestamp": 0 }'
    )

    if (today.unix() >= timestamp) {
      if (!timestampMatched) {
        dispatch(fetchTopbarAds.request(lang))
        setWithAdv(true)
        localStorage.removeItem('mdzAdvPanel')
        setTimestampMatched(true)
      }
    } else {
      setClosed(true)
    }
  }, [dispatch, withAdv, lang, today, timestampMatched])

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && !closed) {
        const rect = ref.current.getBoundingClientRect()

        if (rect.top <= 0 && !visible) {
          setVisible(true)
        }
        if (rect.top >= 0) {
          setVisible(false)
        }
      }
    }

    if (withAdv) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (withAdv || (withAdv && closed)) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [withAdv, visible, closed])

  const handleCloseClick = () => {
    setVisible(false)
    setClosed(true)
    setWithAdv(false)

    const timestamp = JSON.stringify({
      timestamp: today.add(1, 'day').unix()
    })

    localStorage.setItem('mdzAdvPanel', timestamp)
  }

  const handleAdClick = () => {
    if (url) {
      dispatch(topbarAdClicked(url))
      handleCloseClick()
    }
  }

  useEffect(() => {
    if (visible && !adVisibilityTracked && url) {
      setAdVisibilityTracked(true)

      dispatch(topbarAdBecomeVisible(url))
    }
  }, [dispatch, visible, adVisibilityTracked, lang, url])

  return url ? (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles.visible], visible && styles.visible]
      ])}
      ref={ref}
    >
      <div className={styles.ad}>
        <a
          className={styles.link}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleAdClick}
        >
          <span className={styles.title}>{title}</span>
          <div className={styles.label}>{source}</div>
        </a>
        <button
          type="button"
          onClick={handleCloseClick}
          className={styles.button}
        >
          <SvgSymbol icon="cross" size="medium" styleContext="isInToolbar" />
        </button>
      </div>
    </div>
  ) : (
    <div />
  )
}
