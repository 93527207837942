import React from 'react'
import { Lang } from '@client/types'

import styles from './PromoBadge.module.css'

interface PromoBadgeProps {
  isExternal?: boolean
  actionUrl?: string
  logo: {
    url: string
  }
  lang: Lang
}

export const PromoBadge: React.FC<PromoBadgeProps> = ({
  isExternal,
  actionUrl,
  logo,
  lang
}) => (
  <div>
    {logo && (
      <div className={styles.promo}>
        {logo.url && (
          <div className={styles.logo}>
            <img src={logo.url} alt="" />
          </div>
        )}
      </div>
    )}
    {isExternal && (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a className={styles.external} href={actionUrl} target="_blank">
        <span className={styles.externalLabel}>
          {lang === 'ru' ? 'Открыть в новой вкладке' : 'Open in new tab'}
        </span>
      </a>
    )}
  </div>
)
