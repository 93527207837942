import { REQUEST_STATUS } from '@client/enums'
import { InformerData, ScreenContent } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import {
  fetchScreen,
  fetchSpecials,
  fetchUnderTheSun
} from '@store/Screens/screensActions'

export interface ScreensState {
  informer?: InformerData
  mainScreens: {
    news?: ScreenContent
    podcasts?: ScreenContent
    cards?: ScreenContent
    games?: ScreenContent
    shapito?: ScreenContent
    razbor?: ScreenContent
    articles?: ScreenContent
    'under-the-sun'?: ScreenContent
  }
  specials: Record<string, ScreenContent>
  screenRequestStatus: REQUEST_STATUS
  underTheSunRequestStatus: REQUEST_STATUS
  exchangeRatesRequestStatus: REQUEST_STATUS
  specialsRequestStatus: REQUEST_STATUS
}

const initialState: ScreensState = {
  specials: {},
  mainScreens: {},
  screenRequestStatus: REQUEST_STATUS.IDLE,
  underTheSunRequestStatus: REQUEST_STATUS.IDLE,
  exchangeRatesRequestStatus: REQUEST_STATUS.IDLE,
  specialsRequestStatus: REQUEST_STATUS.IDLE
}

export const screensReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchScreen.request, (state) => {
      state.screenRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchScreen.failure, (state) => {
      state.screenRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchScreen.success,
      (
        state,
        {
          payload: {
            name,
            response: {
              data: { informer, desktop, mobile, og }
            }
          }
        }
      ) => {
        state.informer = informer
        state.mainScreens[name] = {
          desktop,
          mobile,
          og
        }
        state.screenRequestStatus = REQUEST_STATUS.SUCCESS
      }
    )
    .addCase(fetchUnderTheSun.request, (state) => {
      state.underTheSunRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchUnderTheSun.failure, (state) => {
      state.underTheSunRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchUnderTheSun.success,
      (
        state,
        {
          payload: {
            response: {
              data: { desktop, mobile, og }
            }
          }
        }
      ) => {
        state.mainScreens['under-the-sun'] = {
          desktop,
          mobile,
          og
        }
        state.underTheSunRequestStatus = REQUEST_STATUS.SUCCESS
      }
    )
    .addCase(fetchSpecials.request, (state) => {
      state.specialsRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchSpecials.failure, (state) => {
      state.specialsRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchSpecials.success,
      (
        state,
        {
          payload: {
            slug,
            response: { data }
          }
        }
      ) => {
        state.specials[slug] = data
        state.specialsRequestStatus = REQUEST_STATUS.SUCCESS
      }
    )
})
