import { EpisodeData } from '@client/types'
import {
  AudioPlayerPlaybackRates,
  PlayerStatus
} from '@common/AudioPlayer/constants'
import { createReducer } from '@reduxjs/toolkit'

import {
  close,
  error,
  finish,
  pause,
  play,
  requestPlay,
  requestRewind,
  rewind,
  setPlaybackRate,
  timeUpdate
} from './audioPlayerActions'

export interface AudioPlayerStateItem extends EpisodeData {
  status: PlayerStatus
  playbackRate: number
  time: number
  possibleProgress: number
}

export interface AudioPlayerState {
  playingEpisodeUrl: string | null
  byId: Record<string, AudioPlayerStateItem>
}

const initialState: AudioPlayerState = {
  playingEpisodeUrl: null,
  byId: {}
}

const initialAudioPlayerStateItem = {
  status: PlayerStatus.LOADING,
  playbackRate: AudioPlayerPlaybackRates.ONE,
  time: 0,
  possibleProgress: 0
}

export const audioPlayerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(requestPlay, (state, { payload: { episodeUrl, episodeData } }) => {
      if (!state.byId[episodeUrl]) {
        state.byId[episodeUrl] = {
          ...initialAudioPlayerStateItem,
          ...episodeData
        }
      }
    })
    .addCase(play, (state, { payload: { episodeUrl } }) => {
      if (state.playingEpisodeUrl && state.playingEpisodeUrl !== episodeUrl) {
        state.byId[state.playingEpisodeUrl].status = PlayerStatus.PAUSED
      }
      state.playingEpisodeUrl = episodeUrl
      state.byId[episodeUrl].status = PlayerStatus.PLAYING
    })
    .addCase(pause, (state, { payload: { episodeUrl } }) => {
      state.byId[episodeUrl].status = PlayerStatus.PAUSED
    })
    .addCase(close, (state, { payload: { episodeUrl } }) => {
      state.playingEpisodeUrl = null
      state.byId[episodeUrl].status = PlayerStatus.CLOSED
    })
    .addCase(timeUpdate, (state, { payload: { episodeUrl, time } }) => {
      state.byId[episodeUrl].time = time
    })
    .addCase(requestRewind, (state, { payload: { episodeUrl, progress } }) => {
      state.byId[episodeUrl].possibleProgress = progress
    })
    .addCase(rewind, (state, { payload: { episodeUrl, time } }) => {
      state.byId[episodeUrl].time = time
      state.byId[episodeUrl].possibleProgress = 0
    })
    .addCase(finish, (state, { payload: { episodeUrl } }) => {
      state.playingEpisodeUrl = null
      state.byId[episodeUrl].time = 0
      state.byId[episodeUrl].status = PlayerStatus.FINISHED
    })
    .addCase(error, (state, { payload: { episodeUrl } }) => {
      state.playingEpisodeUrl = null
      state.byId[episodeUrl].status = PlayerStatus.ERROR
    })
    .addCase(
      setPlaybackRate,
      (state, { payload: { episodeUrl, playbackRate } }) => {
        state.byId[episodeUrl].playbackRate = playbackRate
      }
    )
})
