export const locale = {
  captcha: `Protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.`,
  something_wrong: `Something went wrong! Please contact us at <a href="mailto:newsletter@meduza.io">newsletter@meduza.io</a>, and we'll get you signed up for our newsletter.`,
  action: 'Subscribe!',
  already_message: 'Already subscribed',
  error_message: `Something went wrong! Please contact us at <a href="mailto:newsletter@meduza.io">newsletter@meduza.io</a>, and we'll get you signed up for our newsletter.`,
  iframe_src: 'https://meduza.io/static/test/email_en.htm',
  label: 'Email address',
  second_title:
    'A digest of Russia’s investigative reports and news analysis. If it matters, we summarize it.',
  success_message:
    '<span>Excellent! </span><span>Now you’ve got to verify that email address.</span>',
  title: 'Sign up for Meduza’s daily newsletter',
  wrong_email_message: 'Wrong email address'
}
