import { createReducer } from '@reduxjs/toolkit'

import { hideModal, showModal } from './modalActions'

export interface BaseModalState {
  type: 'idea' | 'search' | 'sendMessage' | 'deleteUser'
}

interface ModalStateWithCoordinates {
  type: 'postAuth' | 'loginHeader' | 'loginBookmark'
  coordinates: number
}

interface ExpandedInterface {
  after: string
  before: string
}

interface TextErrorModalState {
  type: 'textError'
  props: {
    text: string
    expanded: ExpandedInterface
  }
}

export type ModalState =
  | BaseModalState
  | ModalStateWithCoordinates
  | TextErrorModalState

const initialState = {} as ModalState

export const modalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showModal, (state, { payload }) => payload)
    .addCase(hideModal, () => initialState)
})
