import React from 'react'
import { ImageUrls, ScreenDocument } from '@client/types'
import { Link } from '@common/Link'
import { makeClassName } from '@utils/makeClassName'

import styles from './BlockTitle.module.css'

interface BlockTitleProps {
  block: ScreenDocument
  styleContext: string[] | string
  correlation?: keyof ImageUrls
}

export const BlockTitle: React.FC<BlockTitleProps> = ({
  block,
  styleContext,
  correlation
}) => {
  const className = [
    [styles.root, true],
    [
      !!correlation && styles[correlation],
      !!correlation && !!styles[correlation]
    ]
  ]

  if (Array.isArray(styleContext)) {
    styleContext.forEach((sc) => {
      className.push([styles[sc], !!styles[sc]])
    })
  } else if (styles[styleContext]) {
    className.push([styles[styleContext], true])
  }

  return (
    <h2 className={makeClassName(className)}>
      <Link to={`/${block.url}`} styleContext="isInBlockTitle">
        <>
          <span
            className={makeClassName([
              [styles.first, !!styles.first],
              [styles.isFeatured, !!block.second_title && !!styles.isFeatured]
            ])}
          >
            {block.title}
          </span>
          {Boolean(block.second_title) && ' '}
          {Boolean(block.second_title) && (
            <span className={styles.second}>{block.second_title}</span>
          )}
        </>
      </Link>
    </h2>
  )
}
