import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { hideTooltip, showTooltip } from '@client/store/Tooltip/tooltipActions'
import { excludeTouch } from '@client/utils/excludeTouch'
import { makeClassName } from '@client/utils/makeClassName'

import styles from './MagicButton.module.css'

type onOpenTypeEvent =
  | React.PointerEvent<HTMLButtonElement>
  | React.FocusEvent<HTMLButtonElement>
  | React.MouseEvent<HTMLButtonElement>

export const MagicButtonTooltip = () => {
  const dispatch = useDispatch()

  const closeTimerRef = useRef<NodeJS.Timeout | null>(null)

  const onOpen = (event: onOpenTypeEvent) => {
    const target = event.target as HTMLButtonElement

    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current)
    }

    dispatch(showTooltip({ target }))
  }

  const onClose = () => {
    closeTimerRef.current = setTimeout(() => {
      dispatch(hideTooltip())
    }, 300)
  }

  return (
    <button
      className={makeClassName([
        [styles.button, true],
        [styles.tip, true]
      ])}
      onPointerEnter={excludeTouch(onOpen)}
      onPointerLeave={excludeTouch(onClose)}
      onFocus={(event) => onOpen(event)}
      onBlur={onClose}
      onClick={(event) => onOpen(event)}
    >
      Что-что?
    </button>
  )
}
