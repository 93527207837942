import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { getLang } from '@client/selectors/currentUserSelectors'
import { EpisodeScreenDocument, ImageUrls } from '@client/types'
import { PlayButton, Progress } from '@common/AudioPlayer'
import { AudioPanel } from '@common/AudioPlayer/AudioPanel'
import { ProgressTypeEnums } from '@common/AudioPlayer/constants'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { useBannerTrack } from '@hooks/useBannerTrack'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { generateGradient } from '@utils/generateGradient'
import { makeClassName } from '@utils/makeClassName'

import { Tag } from '@meduza/ui-kit-2'

import styles from './EpisodeBlock.module.css'

interface EpisodeBlockProps {
  correlation: keyof ImageUrls
  block: EpisodeScreenDocument
  origin: string
}

export const EpisodeBlock: React.FC<EpisodeBlockProps> = ({
  block,
  origin,
  correlation
}) => {
  const bannerTrackRef = useBannerTrack(block, origin)

  const lang = useSelector(getLang)

  const [fallback, setFallback] = useState(false)

  useEffect(() => {
    setFallback(!window.HTMLPictureElement)
  }, [])

  const {
    tag,
    audio,
    image: { base_urls: baseUrls, optimised_urls: optimisedUrls },
    desktop_cover,
    url
  } = block

  const [ref, inView, entry] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  const withAmbiance = !!desktop_cover && correlation === 'is1to1'
  const bookmarkTheme =
    correlation === 'is1to1' || correlation === 'is1to2' ? 'dark' : 'light'

  let theme = 'default'
  const style = { backgroundImage: 'none' }

  if (desktop_cover) {
    theme = desktop_cover.gradients.text_rgb === '0,0,0' ? 'dark' : 'light'
    style.backgroundImage = generateGradient(
      desktop_cover.gradients.bg_rgb,
      5,
      90
    )
  }

  const backgroundColor = block.theme?.background_color || ''

  return (
    <article
      ref={bannerTrackRef}
      className={makeClassName([
        [styles.root, true],
        [styles[correlation], !!styles[correlation]],
        [styles.withAmbiance, withAmbiance],
        [styles[theme], !!theme && styles[theme]]
      ])}
    >
      {desktop_cover && correlation === 'is1to1' && (
        <>
          <div className={styles.gradient} style={style} />
          <picture className={styles.ambiance}>
            {desktop_cover.urls.optimised_urls.is1to1 && (
              <source
                type="image/webp"
                srcSet={`
                    ${desktop_cover.urls.optimised_urls.is1to1}`}
              />
            )}
            <source srcSet={desktop_cover.urls.base_urls.is1to1} />
            <img src={desktop_cover.urls.base_urls.is1to1} alt={block.title} />
          </picture>
        </>
      )}

      <div className={styles.container} ref={ref}>
        <div className={styles.imageWrap}>
          <div
            className={styles.cover}
            style={{ backgroundColor: `rgb(${backgroundColor})` }}
          />
          {visible && (
            <>
              <div className={styles.image}>
                <picture className={styles.picture}>
                  <source
                    type="image/webp"
                    srcSet={`${optimisedUrls[correlation]} 2x`}
                  />

                  <source
                    type="image/png"
                    srcSet={`${baseUrls[correlation]} 2x`}
                  />
                  <img
                    src={
                      fallback
                        ? baseUrls[correlation]
                        : 'data:image/gif;base64,R0lGODlhAQABAPAAAPLy8gAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                    }
                    alt={block.title}
                  />
                </picture>

                {correlation !== 'is1to1' && (
                  <>
                    <Progress
                      episodeUrl={block.url}
                      duration={block.audio.mp3_duration}
                      type={ProgressTypeEnums.CIRCLE}
                      styleContext="isInEpisodeBlock"
                    />
                    <PlayButton
                      episodeUrl={block.url}
                      styleContext="isInEpisodeBlock"
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            {tag && (
              <div className={styles.tag}>
                <Tag size="small" theme="inherit">
                  {tag.name}
                </Tag>
              </div>
            )}
            <div className={styles.title}>
              <BlockTitle
                block={block}
                correlation={correlation}
                styleContext="isInEpisode"
              />
            </div>
            <div className={styles.audio}>
              {correlation === 'is1to1' && audio && audio.podcast && (
                <AudioPanel
                  episodeUrl={block.audio.url}
                  theme={block.audio.theme}
                  lang={lang}
                  withCover={false}
                  styleContext="isInEpisodeBlock"
                />
              )}
            </div>
            <div className={styles.footer}>
              <BlockMetaContainer block={block} />
            </div>
            <div className={styles.bookmark}>
              <BookmarkIcon
                theme={bookmarkTheme}
                url={url}
                styleContext="isInGrid"
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
