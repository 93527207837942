export default {
  ru: {
    more: 'Показать еще',
    loading: 'Загрузка',
    dateFormat: 'D MMMM, dddd'
  },

  en: {
    more: 'Show more',
    loading: 'Loading',
    dateFormat: 'dddd, MMMM D'
  }
}
