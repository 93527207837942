import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BriefSubscriptionContainer } from '@client/components/Common/BriefSubscriptionContainer'
import { MDZ_DEFAULT_FIREBASE_TOPIC } from '@client/constants'
import { Link } from '@common/Link'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { callToPushNotifications } from '@store/CurrentUser/currentUserActions'
import { makeClassName } from '@utils/makeClassName'

import { footerData } from './footerData'

import styles from './Footer.module.css'

export const FooterComponent = () => {
  const { lang, firebase_token, firebase_topics } = useSelector(getCurrentUser)
  const data = footerData[lang]
  const mod = lang === 'ru' ? 'expanded' : 'short'
  const dispatch = useDispatch()

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles[mod]], true]
      ])}
    >
      <div className={styles.container}>
        <div className={styles.main}>
          <h2 className={styles.hidden}>{data.heading}</h2>
          <nav className={styles.nav}>
            {data.nav.map((item) => (
              <div
                className={makeClassName([
                  [[styles.group], true],
                  [[styles[item.key]], item.key && styles[item.key]]
                ])}
                key={`footer-${item.key}`}
              >
                {item.title && (
                  <h3
                    className={styles.groupTitle}
                    dangerouslySetInnerHTML={{ __html: item.title! }}
                  />
                )}
                <ul className={styles.groupList}>
                  {item.items.map((block) => {
                    if (
                      block.type === 'notifications' &&
                      typeof firebase_token === 'string'
                    ) {
                      return (
                        <li key={block.type}>
                          {firebase_topics[MDZ_DEFAULT_FIREBASE_TOPIC] ===
                            'unsubscribed' && (
                            <button
                              className={styles.push}
                              onClick={() => {
                                dispatch(
                                  callToPushNotifications.request({
                                    token: firebase_token,
                                    topic: MDZ_DEFAULT_FIREBASE_TOPIC,
                                    action: 'subscribe'
                                  })
                                )
                              }}
                            >
                              {block.name}
                            </button>
                          )}

                          {firebase_topics[MDZ_DEFAULT_FIREBASE_TOPIC] ===
                            'subscribed' && (
                            <button
                              className={styles.push}
                              onClick={() => {
                                dispatch(
                                  callToPushNotifications.request({
                                    token: firebase_token,
                                    topic: MDZ_DEFAULT_FIREBASE_TOPIC,
                                    action: 'unsubscribe'
                                  })
                                )
                              }}
                            >
                              Отписаться
                            </button>
                          )}
                        </li>
                      )
                    }

                    if (block.type === 'switcher' && block.link) {
                      return (
                        <li key={block.type}>
                          <a
                            href={block.link}
                            className={makeClassName([
                              [styles.item, true],
                              [styles.itemLang, true]
                            ])}
                          >
                            {block.name}
                          </a>
                        </li>
                      )
                    }

                    if (block.link) {
                      return (
                        <li key={block.link}>
                          {block.type === 'internal' && (
                            <Link to={block.link}>
                              <span
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                            </Link>
                          )}

                          {block.type === 'external' && (
                            <a
                              href={block.link}
                              dangerouslySetInnerHTML={{ __html: block.name }}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          )}
                        </li>
                      )
                    }

                    return null
                  })}
                </ul>
              </div>
            ))}
          </nav>
          <div className={styles.form}>
            <BriefSubscriptionContainer variant="footer" />
          </div>
        </div>

        <div className={styles.panel}>
          {data.howto && (
            <div className={styles.howto}>
              <Link to={data.howto.url}>{data.howto.label}</Link>
            </div>
          )}

          <div className={styles.tip}>{data.tip}</div>
          <div className={styles.copyright}>
            {lang === 'ru'
              ? `© Meduza, ${new Date().getFullYear()}`
              : `© ${new Date().getFullYear()} Meduza. All rights reserved`}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Footer = memo(FooterComponent)

Footer.whyDidYouRender = true
