import { REQUEST_STATUS } from '@client/enums'
import { createReducer } from '@reduxjs/toolkit'
import { fetchSearch, search } from '@store/Search/searchActions'

interface SearchStateElement {
  has_next: boolean
  ids: string[]
  page: number
  requested: boolean
}

export interface SearchState {
  lastQuery: string
  results: Record<string, SearchStateElement>
  status: REQUEST_STATUS
}

const initialStateElement: SearchStateElement = {
  has_next: false,
  ids: [],
  page: 0,
  requested: false
}

const initialState: SearchState = {
  lastQuery: '',
  results: {},
  status: REQUEST_STATUS.IDLE
}

export const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(search, (state, { payload }) => {
      state.lastQuery = payload
    })
    .addCase(fetchSearch.request, (state, { payload: { query, loadPage } }) => {
      if (loadPage) {
        state.results[query] = initialStateElement
      }
      state.status = REQUEST_STATUS.LOADING
    })
    .addCase(fetchSearch.failure, (state) => {
      state.status = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchSearch.success,
      (
        state,
        {
          payload: {
            query,
            response: {
              data: { collection, has_next }
            }
          }
        }
      ) => {
        state.results[query].requested = true
        state.results[query].has_next = has_next
        state.results[query].page += 1
        state.results[query].ids = Array.from(
          new Set([...state.results[query].ids, ...collection])
        )
        state.status = REQUEST_STATUS.SUCCESS
      }
    )
})
