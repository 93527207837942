import { RootState, ScreenDocument } from '@client/types'
import { getScreenDocuments } from '@selectors/screenDocumentsSelectors'
import dayjs from 'dayjs'
import { createCachedSelector } from 're-reselect'

type Section = {
  timestamp: number
  documents: ScreenDocument[]
}

type ChronologyDocuments = {
  hasNext: boolean
  isFetching: boolean
  items: {
    timestamp: number
    documents: ScreenDocument[]
  }[]
}

export const getScreenChronology = (state: RootState, screenName: string) =>
  state.screensChronology[screenName]

export const getChronologyItem = (state: RootState, id: string) =>
  state.screensChronology[id]

export const getChronologyDocuments = createCachedSelector(
  [getChronologyItem, getScreenDocuments],
  (chronology, screenDocuments): ChronologyDocuments => {
    const documents = chronology.ids.map(
      (url: string) => screenDocuments.byId[url]
    ) as ScreenDocument[]
    let lastSection: Section

    return {
      hasNext: chronology.has_next,
      isFetching: chronology.is_fetching,
      items: documents.reduce((acc: Section[], d: ScreenDocument) => {
        if (!lastSection) {
          lastSection = {
            timestamp: d.datetime,
            documents: [d]
          }

          acc.push(lastSection)

          return acc
        }

        const lastTimestamp = dayjs(lastSection.timestamp * 1000)

        const duration = lastTimestamp.diff(d.datetime * 1000, 'hour')
        const startOfTheDay = lastTimestamp.startOf('day')
        const startOfTheDayDuration = lastTimestamp.diff(startOfTheDay, 'hour')

        if (duration >= startOfTheDayDuration) {
          lastSection = {
            timestamp: d.datetime,
            documents: [d]
          }

          acc.push(lastSection)
        } else {
          lastSection.documents.push(d)
        }

        return acc
      }, [])
    }
  }
)((_, id) => id)
