import { LiveDocument, RootState } from '@client/types'
import { createCachedSelector } from 're-reselect'

export const getLive = (state: RootState, id: string): LiveDocument =>
  state.materialLive.byId[id]

export const getPendingItemsIds = createCachedSelector(getLive, (live) =>
  live.new_items_ids_pending
    .map((id) => live.items[id])
    .filter((item) => item.status === 'published')
)((_, id) => id)

export const getNewItemIdsRendered = createCachedSelector(getLive, (live) =>
  live.new_items_ids
    .map((id) => live.items[id])
    .filter((item) => item.status === 'published')
)((_, id) => id)

export const getNewItemIds = createCachedSelector(getLive, (live) =>
  live.new_items_ids
    .filter((itemId) => live.items[itemId].status === 'published')
    .sort((a, b) => 0 - (a > b ? 1 : -1))
)((_, id) => id)

export const getItemIds = createCachedSelector(
  getLive,
  (state, url, reversed) => reversed,
  (live, reversed) => {
    const itemIds = live.items_ids
      .filter((itemId) => live.new_items_ids.indexOf(itemId) === -1)
      .filter((itemId) => live.items[itemId].status === 'published')
      .sort((a, b) => 0 - (a > b ? 1 : -1))

    return reversed ? itemIds.reverse() : itemIds
  }
)((_, id) => id)
