import { RouteConfig } from 'react-router-config'
import { LoadableAccount } from '@routes/Account'
import { LoadableBookmarks } from '@routes/Bookmarks'
import { Latest } from '@routes/Latest'
import { LoadableGameMaterial, LoadableMaterial } from '@routes/Material'
import { LoadableScreen } from '@routes/Screen'
import { LoadableSpecial } from '@routes/Specials'

// TODO: Update router and types
type RouteConfigOveride = Omit<RouteConfig, 'component'>

export const Routes: RouteConfigOveride[] = [
  {
    name: 'screen',
    exact: true,
    path:
      '/:lang(en)?/:screenName(articles|cards|razbor|games|shapito|podcasts|under-the-sun)?',
    component: LoadableScreen,
    getUrl: (name = 'news', lang = 'ru') => `api/w5${lang}/screens/${name}`
  },
  {
    name: 'material',
    exact: true,
    path: [
      '/:lang(en)?/:material(news|feature|lion|shapito|short|brief|signal|live|paragraph|episodes|video|galleries|fun|book|book_landing)/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:slug',
      '/:lang(en)?/:material(cards|slides|podcasts|brand|pages)/:slug'
    ],
    component: LoadableMaterial
  },
  {
    name: 'material',
    exact: true,
    path: ['/:lang(en)?/:material(games|quiz|survey|)/:slug'],
    component: LoadableGameMaterial
  },
  {
    name: 'account',
    exact: true,
    path: '/:lang(en)?/account',
    component: LoadableAccount
  },
  {
    name: 'bookmarks',
    exact: true,
    path: '/:lang(en)?/bookmarks',
    component: LoadableBookmarks
  },
  {
    name: 'special',
    exact: true,
    path: '/specials/:slug',
    component: LoadableSpecial
  },
  {
    name: 'latest',
    exact: true,
    path: '/en/latest/:slug',
    component: Latest
  },
  {
    name: '404',
    path: '*',
    error: true
  }
]
