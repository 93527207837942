import {
  AudioData,
  EpisodeMaterialContent,
  EpisodeScreenDocument,
  NewAudioData
} from '@client/types'
import { PlayerStatus } from '@common/AudioPlayer/constants'
import { getEpisodeDataById } from '@selectors/audioPlayerSelectors'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { getMaterials } from '@selectors/materialsSelectors'
import { getScreenDocuments } from '@selectors/screenDocumentsSelectors'
import { createAudioChannel } from '@store/audio'
import { AudioPlayerStateItem } from '@store/AudioPlayer/audioPlayerReducer'
import { CurrentUserState } from '@store/CurrentUser/currentUserReducer'
import { MaterialsState } from '@store/Materials/materialsReducer'
import { ScreenDocumentsState } from '@store/Screens/screensDocumentsReducer'
import { EventChannel } from 'redux-saga'
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import * as firebase from '../firebase'

import {
  close,
  error,
  finish,
  pause,
  play,
  requestClose,
  requestPause,
  requestPlay,
  requestRewind,
  rewind,
  rewindBackward,
  rewindForward,
  setPlaybackRate,
  timeUpdate
} from './audioPlayerActions'

interface AudioEvent {
  message: string
  episodeUrl: string
  time: number
  error: Event
}

const audioInstance: HTMLAudioElement = new Audio()
let audioChannel: EventChannel<unknown>
let playingEpisodeUrl: string
let audioData: AudioData | NewAudioData

function* requestPlaySaga({
  payload: { episodeUrl, episodeData }
}: ReturnType<typeof requestPlay>) {
  const { episode_history_shelf }: CurrentUserState = yield select(
    getCurrentUser
  )
  const { audio } = episodeData

  const currentEpisodes: Record<string, AudioPlayerStateItem> = yield select(
    getEpisodeDataById
  )

  const currentEpisodeProgress = episode_history_shelf[episodeUrl] || 0

  audioData = audio

  if (
    playingEpisodeUrl !== episodeUrl ||
    currentEpisodes[episodeUrl].status === PlayerStatus.ERROR
  ) {
    if (audioChannel) {
      yield audioChannel.close()
    }

    playingEpisodeUrl = episodeUrl
    audioInstance.src = `https://meduza.io${audio.mp3_url}`

    audioInstance.autoplay = true

    audioInstance.currentTime = audio.mp3_duration * currentEpisodeProgress

    audioChannel = yield call(createAudioChannel, audioInstance, episodeUrl)
    yield call(watchAudioChannel)
  }

  yield audioInstance.play()

  if (currentEpisodes[episodeUrl]) {
    audioInstance.currentTime = currentEpisodes[episodeUrl].time
  }
}

function* watchAudioChannel() {
  while (true) {
    const data: AudioEvent = yield take(audioChannel)
    switch (data.message) {
      case 'play': {
        yield put(play({ episodeUrl: data.episodeUrl }))
        break
      }
      case 'pause': {
        yield put(pause({ episodeUrl: data.episodeUrl }))
        break
      }
      case 'timeupdate': {
        yield put(timeUpdate({ episodeUrl: data.episodeUrl, time: data.time }))
        break
      }
      case 'finish': {
        yield put(finish({ episodeUrl: data.episodeUrl }))
        break
      }
      case 'error': {
        yield put(error({ episodeUrl: data.episodeUrl }))
        break
      }
    }
  }
}

function* requestRewindSaga({
  payload: { episodeUrl, progress }
}: ReturnType<typeof requestRewind>) {
  yield delay(500)
  yield pause({ episodeUrl })

  const materials: MaterialsState = yield select(getMaterials)
  const screenDocuments: ScreenDocumentsState = yield select(getScreenDocuments)
  const currentEpisodes: Record<string, AudioPlayerStateItem> = yield select(
    getEpisodeDataById
  )

  let material

  if (screenDocuments.byId[episodeUrl]) {
    material = screenDocuments.byId[episodeUrl] as EpisodeScreenDocument
  }

  if (materials.byId[episodeUrl]) {
    material = materials.byId[episodeUrl] as EpisodeMaterialContent
  }

  const duration = material
    ? material.audio!.mp3_duration
    : audioData.mp3_duration

  const time = (duration * progress) / 100

  yield put(
    rewind({
      episodeUrl,
      time
    })
  )

  yield put(
    timeUpdate({
      episodeUrl,
      time,
      duration
    })
  )

  const isPlaying =
    currentEpisodes[episodeUrl] &&
    currentEpisodes[episodeUrl].status === PlayerStatus.PLAYING

  if (audioInstance && episodeUrl === playingEpisodeUrl && isPlaying) {
    audioInstance.currentTime = time
    yield audioInstance.play()
  }
}

function* setPlaybackRateSaga({
  payload: { playbackRate }
}: ReturnType<typeof setPlaybackRate>) {
  audioInstance.playbackRate = playbackRate
}

function* requestPauseSaga() {
  yield audioInstance.pause()
}

function* rewindBackwardSaga() {
  audioInstance.currentTime = Math.max(0, audioInstance.currentTime - 15)
}

function* rewindForwardSaga() {
  audioInstance.currentTime = Math.max(0, audioInstance.currentTime + 15)
}

function* requestCloseSaga({
  payload: { episodeUrl }
}: ReturnType<typeof requestClose>) {
  if (!audioInstance.paused) {
    audioInstance.pause()
  }
  yield put(close({ episodeUrl }))
}

function* timeUpdateSaga({
  payload: { episodeUrl, time, duration }
}: ReturnType<typeof timeUpdate>) {
  const currentUser: CurrentUserState = yield select(getCurrentUser)
  const { firebase_user, episode_history_shelf } = currentUser

  if (firebase_user) {
    const byId: Record<string, AudioPlayerStateItem> = yield select(
      getEpisodeDataById
    )
    const episodeData = byId[episodeUrl]
    const currentDuration =
      episodeData.audio && episodeData.audio.mp3_duration
        ? episodeData.audio.mp3_duration
        : duration

    const progress = currentDuration
      ? Math.floor((100 / currentDuration) * time) / 100
      : undefined

    if (progress && episode_history_shelf[episodeUrl] !== progress) {
      yield call(
        firebase.episodeTimeUpdate,
        firebase_user.uid,
        episodeUrl,
        progress
      )
    }
  }
}

export default function* audioPlayerSaga() {
  yield all([
    takeEvery(requestPlay.type, requestPlaySaga),
    takeEvery(requestPause.type, requestPauseSaga),
    takeEvery(requestClose.type, requestCloseSaga),
    takeLatest(requestRewind.type, requestRewindSaga),
    takeEvery(rewindBackward.type, rewindBackwardSaga),
    takeEvery(rewindForward.type, rewindForwardSaga),
    takeEvery(setPlaybackRate.type, setPlaybackRateSaga),
    takeEvery(timeUpdate.type, timeUpdateSaga)
  ])
}
