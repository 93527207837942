import { Lang } from '@client/types'
import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from '@utils/createAsyncAction'
import { SearchResponse } from 'types/apiTypes'

export const search = createAction<string>('@SEARCH/SEARCH')

export const fetchSearch = createAsyncAction<
  { query: string; page: number; lang: Lang; loadPage: boolean },
  { response: SearchResponse; query: string },
  string
>('@SCREEN/FETCH_SEARCH')
