import React, { CSSProperties } from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './AlertTemplate.module.css'

/*
 * Шаблон для алерта
 */

export interface AlertTemplateProps {
  style: CSSProperties
  options: {
    position?: string
    timeout?: number
    type: string
  }
  message: string
}

export const AlertTemplate: React.FC<AlertTemplateProps> = ({
  style,
  options,
  message
}) => (
  <div
    className={makeClassName([
      [styles.root, true],
      [styles[options.type], !!options.type]
    ])}
    style={style}
  >
    {message}
  </div>
)
