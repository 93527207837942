import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { trackMaterialScroll } from '@store/Analytics/analyticsActions'
import { markMaterialRead } from '@store/CurrentUser/currentUserActions'

/*
хук для трекинга скролла и отправки этих значений в аналитику
используется в материалах
*/

export const useTrackScroll = (url: string) => {
  const dispatch = useDispatch()
  const { history } = useSelector(getCurrentUser)
  const ref = useRef<HTMLDivElement>(null)

  const trackedScroll25 = useRef(false)
  const trackedScroll50 = useRef(false)
  const trackedScroll75 = useRef(false)
  const trackedScroll90 = useRef(false)

  const [isRead, setIsRead] = useState(false)

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const elRect = ref.current.getBoundingClientRect()
      const bodyRect = document.body.getBoundingClientRect()
      const elTopOffset = elRect.top - bodyRect.top
      const scrollTop = Math.abs(
        window.scrollY ||
          window.pageYOffset ||
          document.body.scrollTop ||
          document.documentElement.scrollTop
      )

      if (scrollTop < elTopOffset) {
        return
      }

      const percentage =
        ((scrollTop - elTopOffset) * 100) / (elRect.height - elTopOffset)

      if (percentage >= 25 && !trackedScroll25.current) {
        dispatch(trackMaterialScroll({ url, percentage: 25 }))
        trackedScroll25.current = true
      } else if (percentage >= 50 && !trackedScroll50.current) {
        dispatch(trackMaterialScroll({ url, percentage: 50 }))
        trackedScroll50.current = true
      } else if (percentage >= 75 && !trackedScroll75.current) {
        dispatch(trackMaterialScroll({ url, percentage: 75 }))
        trackedScroll75.current = true
        if (!isRead) {
          setIsRead(true)
          dispatch(markMaterialRead(url))
        }
      } else if (percentage >= 90 && !trackedScroll90.current) {
        dispatch(trackMaterialScroll({ url, percentage: 90 }))
        trackedScroll90.current = true
      }
    }
  }, [dispatch, isRead, url])

  useEffect(() => {
    if (Object.keys(history).indexOf(url) !== -1) {
      setIsRead(true)
    }
  }, [history, url])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return { ref, isRead }
}
