import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUnblockLink } from '@client/selectors/materialsSelectors'
import { makeClassName } from '@client/utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'

import { MagicButtonTooltip } from './MagicButtonTooltip'

import styles from './MagicButton.module.css'

const content = {
  default: 'Magic link',
  success: 'Cсылка скопирована',
  error: 'Не вышло, попробуйте позднее'
}

export type MagicButtonState = 'default' | 'success' | 'error'

export const MagicButton = ({ url }) => {
  const [state, setState] = useState<MagicButtonState>('default')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const unblockLink = useSelector(getUnblockLink)

  const handleClick = async (url: string) => {
    try {
      const response = await fetch(`/unblock/${url}`)
      const result = await response.json()

      textareaRef.current.value = result.url
      textareaRef.current.select()

      // Используем setTimeout для отложенного выполнения document.execCommand('copy')
      // Это нужно для того, чтобы в Safari произошло копирование
      setTimeout(() => {
        document.execCommand('copy')
        textareaRef.current.value = ''
        setState('success')
      }, 0)
    } catch (err) {
      setState('error')
    } finally {
      setTimeout(() => setState('default'), 2000)
    }
  }

  if (!unblockLink) {
    return null
  }
  return (
    <div className={styles.root}>
      <button
        className={makeClassName([
          [styles.button, true],
          [styles[state], !!styles[state]]
        ])}
        type="button"
        onClick={(): Promise<void> => handleClick(url)}
      >
        <div className={styles.icon}>
          <SvgSymbol size="medium" icon="magic" styleContext="isInToolbar" />
        </div>
        <span className={styles.text}>{content[state]}</span>
      </button>
      <textarea ref={textareaRef} className={styles.hiddenTextArea} readOnly />

      {state === 'default' && <MagicButtonTooltip />}
    </div>
  )
}
