import { EpisodesData } from '@client/types'

import { ButtonStates } from '@meduza/ui-kit-2/dist/Button/Button.types'

interface loadMoreEpisodesPodcastsParams {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  episodes: EpisodesData[]
  setEpisodes: React.Dispatch<React.SetStateAction<EpisodesData[]>>
  setState: React.Dispatch<React.SetStateAction<ButtonStates>>
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
  podcasts: string
}

// Функция для загрузки дополнительных эпизодов подкастов при пагинации
export const loadMoreEpisodesPodcasts = async ({
  page,
  setPage,
  episodes,
  setEpisodes,
  setState,
  setIsError,
  podcasts
}: loadMoreEpisodesPodcastsParams) => {
  try {
    const url = `/api/w5/${podcasts}/episodes/${page + 1}`
    setState('isLoading')
    const response = await fetch(url)
    const result = await response.json()
    const resultEpisodes = [...episodes, ...result.episodes]
    setEpisodes(resultEpisodes)
    setPage((prev) => prev + 1)
    setState('isDefault')
    setIsError(false)
  } catch (error) {
    console.error(error)
    setState('isDefault')
    setIsError(true)
  }
}
