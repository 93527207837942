import React, { memo } from 'react'
import { DesktopItem } from '@client/types'
import { Section } from '@routes/Screen/Section'
import { makeClassName } from '@utils/makeClassName'

import styles from './Grid.module.css'

interface GridProps {
  containers: DesktopItem[]
  styleContext?: string[]
  screenType?: string
  currentUrl?: string
}

/*
 * Сетка блоков для экранов десктопа
 */

const GridComponent: React.FC<GridProps> = ({
  containers,
  styleContext = [],
  currentUrl
}) => {
  return (
    <div
      className={makeClassName(
        [[styles.root, true]].concat(
          styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
        )
      )}
    >
      {containers.map((container) => {
        return (
          <section className={styles.container} key={container.uuid}>
            {container.title && (
              <header className={styles.header}>
                <h2>{container.title}</h2>
              </header>
            )}
            {container.sections &&
              container.sections.map((section) => (
                <Section
                  blocks={section.blocks}
                  currentUrl={currentUrl}
                  key={section.uuid}
                />
              ))}
          </section>
        )
      })}
    </div>
  )
}

export const Grid = memo(GridComponent)

Grid.whyDidYouRender = true
