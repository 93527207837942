export const locale = {
  signal_subscription: {
    action: 'Подписаться',
    label: 'Подписаться',
    placeholder: 'Ваш e-mail',
    second_title:
      'Подпишитесь на&nbsp;&laquo;Сигнал&raquo;&nbsp;&mdash; новое медиа от&nbsp;создателей &laquo;Медузы&raquo;. Эта имейл-рассылка действительно помогает понимать новости. Она будет работать до&nbsp;тех пор, пока в&nbsp;России есть интернет. <span>Защита от&nbsp;спама reCAPTCHA. <a href="https://policies.google.com/privacy?hl=ru" target="_blank" rel="noreferrer"> Конфиденциальность</a> и&nbsp;<a href="https://policies.google.com/terms?hl=ru" target="_blank" rel="noreferrer">условия использования</a></span>',
    success_message:
      'Мы&nbsp;отправили ссылку в&nbsp;письме на&nbsp;почту <strong>{USER_EMAIL}</strong>. Пожалуйста, перейдите по&nbsp;этой ссылке.',
    wrong_email_message: 'Ошибка! Попробуйте еще раз',
    something_wrong:
      'Что-то пошло не&nbsp;так. Подпишитесь <a href="https://meduza.us10.list-manage.com/subscribe?u=ff4a009ba1f59d865f0301f85&id=af6c651550" target="_blank" rel="noreferrer">здесь</a> или напишите нам на&nbsp;<a href="mailto:info@getsignal.news">info@getsignal.news</a>, и&nbsp;мы&nbsp;подпишем вас',
    theme: 'isSignal'
  },

  brief_subscription: {
    captcha: `Protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.`,
    action: 'Subscribe',
    label: 'Subscribe',
    title: 'Sign up for Meduza’s daily newsletter',
    placeholder: 'Email address',
    second_title:
      'A digest of Russia’s investigative reports and news analysis. If it matters, we summarize it.',
    success_message: 'Excellent! Now you’ve got to verify that email address.',
    wrong_email_message: 'Something went wrong! Try again',
    something_wrong: `Something went wrong! Please contact us at <a href="mailto:newsletter@meduza.io">newsletter@meduza.io</a>, and we'll get you signed up for our newsletter.`,
    theme: 'isDaily'
  }
}
