import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { InformerData } from '@client/types'
import {
  informerBecomeVisible,
  informerClicked
} from '@store/Materials/materialsActions'

import { Informer } from './Informer'

/*
 * Информер - элемент в InfoPanel - находится посередине (например информация про коронавирус)
 */

interface InformerContainerProps {
  informer: InformerData
}

export const InformerContainer: React.FC<InformerContainerProps> = ({
  informer
}) => {
  const dispatch = useDispatch()
  const tracked = useRef(false)
  const [ref, inView] = useInView()

  useEffect(() => {
    if (!tracked.current) {
      if (inView) {
        dispatch(informerBecomeVisible(informer.url))
        tracked.current = true
      }
    }
  }, [inView, dispatch, informer])

  const handleClick = () => {
    dispatch(informerClicked(informer.url))
  }

  return (
    <Informer icon={informer.icon_url}>
      <a
        href={informer.url}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        onClick={handleClick}
      >
        {informer.text}
      </a>
    </Informer>
  )
}
