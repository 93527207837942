import { RootState } from '@client/types'

export const getCurrentUser = (state: RootState) => state.currentUser
export const getLang = (state: RootState) => state.currentUser.lang
export const getFirebaseUser = (state: RootState) =>
  state.currentUser.firebase_user
export const getHistory = (state: RootState) => state.currentUser.history
export const getBookmarks = (state: RootState) => state.currentUser.bookmarks
export const getLastOpenedMaterial = (state: RootState) =>
  state.currentUser.last_opened_material
    ? state.currentUser.last_opened_material.url
    : null
