import React from 'react'

import styles from './Spinner.module.css'

export const Spinner: React.FC = () => (
  <div className={styles.root}>
    <svg viewBox="0 0 90 90">
      <path
        d="M37.9027773,50.7969338 L54.4791838,56.072 L54.4791838,25.1345494 L54.4791838,14.6529617 L65.1423692,14.6529617 L65.1423692,50.7969338 L81.6218376,56.072 L92.3819609,41.4190383 L81.718776,41.4190383 L81.718776,5.76349826 L65.2393072,0 L54.4791838,12.6992334 L54.4791838,5.76349826 L37.9997154,0 L27.2395919,12.6992334 L27.2395919,5.76349826 L10.7601235,0 L0,14.6529617 L10.6631854,14.6529617 L10.6631854,50.7969338 L27.2395919,56.072 L27.2395919,14.6529617 L37.9027773,14.6529617 L37.9027773,50.7969338 Z"
        fill="#B78B5C"
      />
    </svg>
  </div>
)
