import { Block } from '@client/types'

const predicate: string[] = ['p', 'h2', 'h3', 'ul', 'ol', 'blockquote']

const injectBanner = (blocks: Block[], position: number): Block[] => [
  ...blocks.slice(0, position),
  {
    type: 'banner',
    id: `banner-${position}`,
    data: {
      id: `banner-${position}`
    }
  },
  ...blocks.slice(position)
]

// Ищем все доступные места для вставки баннера
const findInsertPositions = (blocks: Block[]) => {
  let length = 0
  const insertPositions: number[] = []

  blocks.forEach((block, i) => {
    if (predicate.indexOf(block.type) > -1) {
      if (length > 1500) {
        insertPositions.push(i)
        length = 0
      } else {
        if ('length' in block) {
          length += block.length ? block.length : 0
        }
      }
    } else {
      length = 0
    }
  })

  return insertPositions
}

export const tryPushBanner = (
  blocks: Block[],
  withBanners: boolean
): Block[] => {
  let preparedBlocks: Block[] = blocks

  if (!withBanners) {
    return preparedBlocks
  }

  const insertPositions: number[] = findInsertPositions(blocks) || []
  const positionsLength: number = insertPositions.length

  for (let i = 0; i < positionsLength; i++) {
    preparedBlocks = injectBanner(preparedBlocks, insertPositions[i])
  }

  return preparedBlocks
}
