import { BroadcastItem, LiveItem } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import {
  connectLive,
  disconnectLive,
  fetchMaterial,
  liveInitialItems,
  liveNewItems,
  liveUpdateItems,
  reverseLive,
  showPendingLive
} from '@store/Materials/materialsActions'

interface LiveStateItem {
  items_ids: string[]
  new_items_ids: string[]
  new_items_ids_pending: string[]
  items: Record<string, LiveItem>
  reversed: boolean
  connected: boolean
  connection_error: boolean
  has_next: boolean
  page: number
}

export interface MaterialLiveState {
  byId: Record<string, LiveStateItem>
}

export const createLiveStateItem = (
  broadcast: BroadcastItem
): LiveStateItem & BroadcastItem => ({
  ...broadcast,
  new_items_ids: [],
  new_items_ids_pending: [],
  reversed: false,
  connected: false,
  connection_error: false,
  page: 0
})

const initialState: MaterialLiveState = {
  byId: {}
}

export const createLiveReducer = (state = initialState) =>
  createReducer(state, (builder) => {
    builder
      .addCase(
        liveInitialItems,
        (state, { payload: { url, items, items_ids } }) => {
          state.byId[url].items = { ...state.byId[url].items, ...items }
          state.byId[url].items_ids = [
            ...state.byId[url].items_ids,
            ...items_ids
          ]
        }
      )
      .addCase(
        liveNewItems,
        (state, { payload: { url, items, items_ids } }) => {
          state.byId[url].items = { ...state.byId[url].items, ...items }
          state.byId[url].new_items_ids_pending = [
            ...state.byId[url].new_items_ids_pending,
            ...items_ids
          ]
        }
      )
      .addCase(liveUpdateItems, (state, { payload: { url, items } }) => {
        state.byId[url].items = { ...state.byId[url].items, ...items }
      })
      .addCase(showPendingLive, (state, { payload: url }) => {
        state.byId[url].items_ids = state.byId[url].items_ids = Array.from(
          new Set([
            ...state.byId[url].new_items_ids,
            ...state.byId[url].items_ids
          ])
        )
        state.byId[url].new_items_ids = state.byId[url].new_items_ids_pending
        state.byId[url].new_items_ids_pending = []
      })
      .addCase(reverseLive, (state, { payload: url }) => {
        state.byId[url].reversed = !state.byId[url].reversed
      })
      .addCase(connectLive.success, (state, { payload: url }) => {
        state.byId[url].connected = true
        state.byId[url].connection_error = false
      })
      .addCase(connectLive.failure, (state, { payload: url }) => {
        state.byId[url].connected = false
        state.byId[url].connection_error = true
        state.byId[url].new_items_ids = []
        state.byId[url].new_items_ids_pending = []
      })
      .addCase(disconnectLive.success, (state, { payload: url }) => {
        state.byId[url].connected = false
      })
      .addCase(
        fetchMaterial.success,
        (
          state,
          {
            payload: {
              response: {
                data: { root }
              }
            }
          }
        ) => {
          if (root.layout === 'live' && !state.byId[root.url]) {
            const {
              content: { broadcast }
            } = root
            state.byId[root.url] = createLiveStateItem(broadcast)
          }
        }
      )
  })

export const liveReducer = createLiveReducer()
