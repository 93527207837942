import React from 'react'

import styles from './NativeBanner.module.css'

export const NativeBanner = ({ banner }) => {
  const bgColor = (banner && banner.bg) || '#000'

  if (!banner) {
    return <div></div>
  }

  return (
    <div className={styles.root} style={{ backgroundColor: bgColor }}>
      <a
        className={styles.block}
        href={banner.url}
        target="_blank"
        rel="noreferrer"
      >
        <picture className={styles.picture}>
          <source
            type="image/png"
            media="(max-width: 999px)"
            srcSet={banner.mobile}
          />

          <source
            type="image/png"
            media="(min-width: 1000px)"
            srcSet={banner.desktop}
          />

          <img
            src={
              'data:image/gif;base64,R0lGODlhAQABAPAAAPLy8gAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
            }
            alt={banner.alt}
          />
        </picture>
      </a>
    </div>
  )
}
