import { MDZ_FIREBASE_USER_DATA } from '@client/constants'
import { userIsLoggedIn } from '@store/CurrentUser/currentUserActions'
// import { User as FirebaseUser } from 'firebase/auth'
import { Dispatch } from 'redux'

// We check if there is data in localstorage for the user
// and then update current_users'state

export const checkUserData = (dispatch: Dispatch) => {
  const userData = localStorage.getItem(MDZ_FIREBASE_USER_DATA)
  if (userData) {
    // TODO: FIREBASE
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const user: any = JSON.parse(userData)

    dispatch(userIsLoggedIn(user))
  }
}
