import React, { useEffect } from 'react'
import { useWatchIsMobile } from '@client/hooks/useWatchIsMobile'
import { useSetAppParams } from '@hooks/useSetAppParams'

/*
 *Обертка для всего приложения
 */

type Props = {
  children?: React.ReactNode
  callback?: () => void
}

export const App: React.FC<Props> = ({ children, callback }) => {
  useSetAppParams()
  const ref = useWatchIsMobile()

  useEffect(() => {
    if (callback) {
      callback()
    }
  }, [callback])

  return <div ref={ref}>{children}</div>
}
