import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { TransitionGroup } from 'react-transition-group'
import {
  ReactZoomPanPinchHandlers,
  ReactZoomPanPinchProps
} from 'react-zoom-pan-pinch'
import { ImageData } from '@client/types'
import { Fade } from '@common/Lightbox/Fade'
import { LightboxContext } from '@common/Lightbox/types'
import loadable from '@loadable/component'
import { makeClassName } from '@utils/makeClassName'

import Context from './Context'

import styles from './Lightbox.module.css'

const ReactZoomPanPinch = loadable.lib(() => import('react-zoom-pan-pinch'))

type Props = {
  children?: React.ReactNode
}

const Provider: React.FC<Props> = ({ children }) => {
  const root = useRef<HTMLDivElement>()

  const lightboxContext = useRef<LightboxContext>()

  const [visible, setVisible] = useState(false)
  const [image, setImage] = useState<ImageData['optimized'] | null>()
  const [alt, setAlt] = useState<ImageData['alt']>('')

  const [isScaled, setIsScaled] = useState(false)

  useEffect(() => {
    root.current = document.createElement('div')
    root.current.id = '__lightbox__'
    document.body.appendChild(root.current)

    return () => {
      if (root.current) document.body.removeChild(root.current)
    }
  }, [])

  const show = useCallback(
    (image: ImageData['optimized'], alt?: ImageData['alt']) => {
      setImage(image)
      if (alt) setAlt(alt)
      setVisible(true)
    },
    []
  )

  const hide = useCallback(() => {
    setVisible(false)
    setImage(null)
  }, [])

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hide()
      }
    },
    [hide]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction)
    document.body.dataset.lightbox = visible ? 'true' : 'false'
    return () => {
      document.removeEventListener('keydown', escFunction)
      document.body.dataset.lightbox = 'false'
    }
  }, [visible, escFunction])

  lightboxContext.current = {
    visible,
    show,
    hide
  }

  const handleZoomChange: ReactZoomPanPinchProps['onTransformed'] = ({
    state
  }) => setIsScaled(state.scale > 1)

  const wheelOptions = {
    wheelDisabled: true
  }

  return (
    <Context.Provider value={lightboxContext.current}>
      {children}
      {root.current &&
        createPortal(
          <>
            <TransitionGroup>
              {
                <Fade visible={visible}>
                  <div
                    className={makeClassName([
                      [styles.root, true],
                      [styles.visible, visible],
                      [styles.scaled, isScaled]
                    ])}
                  >
                    <div
                      className={styles.dismiss}
                      onClick={() => hide()}
                      onKeyDown={() => hide()}
                      role="button"
                      tabIndex={-1}
                    />
                    <div className={styles.container}>
                      {image && (
                        <ReactZoomPanPinch>
                          {({ TransformWrapper, TransformComponent }) => (
                            <TransformWrapper
                              initialScale={1}
                              wheel={wheelOptions}
                              doubleClick={{
                                mode: isScaled ? 'reset' : 'zoomIn'
                              }}
                              onTransformed={handleZoomChange}
                            >
                              {({
                                zoomIn,
                                zoomOut
                              }: ReactZoomPanPinchHandlers) => (
                                <>
                                  <button
                                    className={styles.zoom}
                                    onClick={() =>
                                      isScaled ? zoomOut() : zoomIn()
                                    }
                                  />

                                  <TransformComponent>
                                    <div className={styles.lightbox}>
                                      <div className={styles.image}>
                                        <img src={image.original} alt={alt} />
                                      </div>
                                    </div>
                                  </TransformComponent>
                                </>
                              )}
                            </TransformWrapper>
                          )}
                        </ReactZoomPanPinch>
                      )}
                      <div className={styles.control}>
                        <button onClick={() => hide()} type="button" />
                      </div>
                    </div>
                  </div>
                </Fade>
              }
            </TransitionGroup>
          </>,
          root.current
        )}
    </Context.Provider>
  )
}

export default Provider
