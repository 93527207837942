/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ImageUrls } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './SupportBlock.module.css'

interface Images {
  is1to1: string
  is1to2: string
  is1to3: string
  is1to4: string
  isMobile: string
}

interface HoverImages {
  is1to1: string
  is1to2: string
  is1to3: string
  is1to4: string
}

interface BlockData {
  link: string
  title: string
  second_title?: string
  images: Images
  hover?: HoverImages
}

interface SupportBlockProps {
  block: any
  correlation: keyof ImageUrls
}

export const SupportBlock: React.FC<SupportBlockProps> = ({
  block,
  correlation
}) => {
  const { action_url, title, images, appearance } = block

  const [ref, inView, entry] = useInView({
    threshold: 0.25,
    triggerOnce: true
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  let imageHover: string | undefined

  const data: BlockData = {
    link: action_url,
    title: title,
    images: {
      is1to1: `https://meduza.io${images.is1to1.static.webp}`,
      is1to2: `https://meduza.io${images.is1to2.static.webp}`,
      is1to3: `https://meduza.io${images.is1to3.static.webp}`,
      is1to4: `https://meduza.io${images.is1to4.static.webp}`,
      isMobile: `https://meduza.io${images.isMobile.static.webp}`
    }
  }

  if (images.is1to1.hover && images.is1to1.hover.webp) {
    data.hover = {
      is1to1: `https://meduza.io${images.is1to1.hover.webp}`,
      is1to2: `https://meduza.io${images.is1to2.hover.webp}`,
      is1to3: `https://meduza.io${images.is1to3.hover.webp}`,
      is1to4: `https://meduza.io${images.is1to4.hover.webp}`
    }
    imageHover = data.hover[correlation]
  }

  if (block.second_title) {
    data.second_title = block.second_title
  }

  const image = data.images[correlation]

  const backgroundCoverColor =
    correlation === 'isMobile'
      ? appearance.mobile.background_cover_color
      : appearance.desktop.background_cover_color

  const rootBackgroundStyle = {
    backgroundColor: `rgb(${backgroundCoverColor})`
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles[correlation], !!correlation && !!styles[correlation]],
        [
          styles.rootWithHover,
          images.is1to1.hover !== undefined && correlation !== 'isMobile'
        ]
      ])}
      style={rootBackgroundStyle}
      ref={ref}
    >
      <a href={data.link} target="blank" rel="noreferrer">
        <h2 className={styles.title}>{data.title}</h2>
        {data.second_title && (
          <h3 className={styles.secondTitle}>{data.second_title}</h3>
        )}

        {visible && image && (
          <div className={styles.imageContainer}>
            <img
              src={image}
              alt="rich_promo_image"
              className={styles.richPromoImage}
            />
            {correlation !== 'isMobile' && images.is1to1.hover && (
              <img
                src={imageHover}
                alt="rich_promo_hover"
                className={styles.richPromoHover}
              />
            )}
          </div>
        )}
      </a>
    </div>
  )
}
