import React from 'react'
import { useSelector } from 'react-redux'
import {
  CardScreenDocument,
  ImageUrls,
  PodcastDocument,
  RichScreenDocument,
  ScreenImage
} from '@client/types'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { PodcastCover } from '@common/PodcastCover'
import { useBannerTrack } from '@hooks/useBannerTrack'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { PromoBadge } from '@routes/Screen/PromoBadge'
import { getLang } from '@selectors/currentUserSelectors'
import { capitalize } from '@utils/capitalize'
import { generateGradient } from '@utils/generateGradient'
import { makeClassName } from '@utils/makeClassName'

import { Tag } from '@meduza/ui-kit-2'

import { ImageElement } from './ImageElement'

import styles from './RichBlock.module.css'

interface RichBlockProps {
  block: RichScreenDocument | CardScreenDocument | PodcastDocument
  correlation: keyof ImageUrls
  origin: string
}

export const RichBlock: React.FC<RichBlockProps> = ({
  block,
  correlation,
  origin
}) => {
  const lang = useSelector(getLang)
  const bannerTrackRef = useBannerTrack(block, origin)

  const {
    layout,
    mobile_layout: mobileLayout,
    tag,
    image,
    image: { base_urls: baseUrls },
    podcast,
    theme,
    footer,
    url
  } = block

  const layoutMod = `is${capitalize(layout)}`

  const shouldRenderPicture =
    correlation === 'isMobile' && layout !== 'podcast' && layout !== 'card'

  let blockTheme
  const style: React.CSSProperties = {}
  const rootStyle: React.CSSProperties = {}

  const withFullImage = correlation === 'is1to1' || correlation === 'is1to2'

  if (image.gradients && withFullImage) {
    blockTheme = image.gradients.text_rgb === '0,0,0' ? 'dark' : 'light'

    style.backgroundImage = generateGradient(image.gradients.bg_rgb, 10, -180)
  }

  const bookmarkTheme =
    withFullImage || layout === 'card' || layout === 'podcast'
      ? 'dark'
      : 'light'

  if (theme) {
    rootStyle.backgroundColor = `rgb(${theme.background_color})`
  }

  const withTheme = layout === 'card' && theme?.background_color

  if (layout === 'card') {
    rootStyle['--bgColor'] = `rgb(${theme?.background_color || '26, 26, 26'})`
    rootStyle['--textColor'] = `rgb(${theme?.text_color || '255, 255, 255'})`
    rootStyle['--metaColor'] = `rgba(${
      theme?.text_color || '255, 255, 255'
    }, 0.7)`
  }

  return (
    <article
      className={makeClassName([
        [styles.root, true],
        [styles[correlation], !!correlation && !!styles[correlation]],
        [
          !!blockTheme && styles[blockTheme],
          !!blockTheme && !!styles[blockTheme]
        ],
        [styles[layoutMod], !!styles[layoutMod]],
        [styles.withTheme, withTheme]
      ])}
      ref={bannerTrackRef}
      style={rootStyle}
    >
      {shouldRenderPicture ? (
        <ImageElement image={image as ScreenImage} layout={mobileLayout} />
      ) : (
        <div className={styles.imageWrap}>
          {podcast && (
            <div className={styles.cover}>
              <PodcastCover
                image={baseUrls && baseUrls[correlation]}
                styleContext="isInRichBlock"
                correlation={correlation}
              />
            </div>
          )}

          {!podcast && image.optimised_urls && (
            <picture className={styles.picture}>
              {image.optimised_urls[correlation] && (
                <source
                  type="image/webp"
                  srcSet={`
                    ${image.optimised_urls[correlation]}`}
                />
              )}
              <source
                srcSet={`
                  ${
                    baseUrls[correlation] ||
                    image.small_url ||
                    image[correlation]
                  }`}
              />
              <img
                src={(baseUrls && baseUrls[correlation]) || image[correlation]}
                alt=""
              />
            </picture>
          )}
        </div>
      )}
      <div className={styles.contentWrap}>
        <div className={styles.content} style={style}>
          {tag && (
            <div className={styles.tag}>
              <Tag
                size={
                  correlation === 'is1to1' && layout === 'rich'
                    ? 'large'
                    : 'small'
                }
                theme="inherit"
              >
                {tag.name}
              </Tag>
            </div>
          )}
          <div className={styles.title}>
            <BlockTitle
              block={block}
              correlation={correlation}
              styleContext={`isIn${capitalize(layout)}`}
            />
          </div>
          <div className={styles.meta}>
            <BlockMetaContainer block={block} />
          </div>
          <div className={styles.bookmark}>
            <BookmarkIcon
              theme={bookmarkTheme}
              url={url}
              styleContext="isInGrid"
            />
          </div>
          {footer && (
            <PromoBadge
              logo={footer.logo}
              lang={lang}
              actionUrl={footer.action_url}
              isExternal={footer.is_external}
            />
          )}
        </div>
      </div>
    </article>
  )
}
