import { SEARCH_DELAY } from '@client/constants'
import { Lang } from '@client/types'
import { getLang } from '@selectors/currentUserSelectors'
import { fetchSearch, search } from '@store/Search/searchActions'
import { handleError } from '@utils/handleError'
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'

import * as api from '../api'

function* searchSaga({ payload }: ReturnType<typeof search>) {
  const lang: Lang = yield select(getLang)
  const trimmedWord = payload.trim()

  yield delay(SEARCH_DELAY)

  if (trimmedWord.length > 1) {
    yield put(
      fetchSearch.request({
        query: trimmedWord,
        page: 0,
        lang,
        loadPage: true
      })
    )
  }
}

function* fetchSearchSaga({ payload }: ReturnType<typeof fetchSearch.request>) {
  try {
    const response = yield call(api.fetchSearch, payload)
    yield put(fetchSearch.success({ response, query: payload.query }))
  } catch (err) {
    handleError(err, fetchSearch.failure.type)
    yield put(fetchSearch.failure(err))
  }
}

export default function* appSaga() {
  yield all([
    takeLatest(search.type, searchSaga),
    takeLatest(fetchSearch.request.type, fetchSearchSaga)
  ])
}
