import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PodcastsListDocument } from '@client/types'
import { useBannerTrack } from '@hooks/useBannerTrack'
import { getLang } from '@selectors/currentUserSelectors'

import {
  DocumentItemsCount,
  Meta,
  MetaItem,
  SvgSymbol,
  Timestamp
} from '@meduza/ui-kit-2'

import styles from './PodcastListBlock.module.css'

interface PodcastListBlockProps {
  block: PodcastsListDocument
  origin: string
}

export const PodcastListBlock: React.FC<PodcastListBlockProps> = ({
  block,
  origin
}) => {
  const lang = useSelector(getLang)
  const bannerTrackRef = useBannerTrack(block, origin)
  const { podcasts } = block

  return (
    <div className={styles.root} ref={bannerTrackRef}>
      <div className={styles.container}>
        {podcasts.map((item) => (
          <div className={styles.block} key={item.id}>
            <Link className={styles.item} to={`/${item.url}`}>
              <picture className={styles.image}>
                <source type="image/webp" srcSet={item.image.optimised_url} />
                <source srcSet={item.image.base_url} />
                <img alt={item.title} src={item.image.base_url} />
              </picture>
              <div className={styles.body}>
                <h3 className={styles.title}>{item.title}</h3>
                <div className={styles.meta}>
                  <Meta>
                    <MetaItem bullets>
                      <DocumentItemsCount
                        items={item.episodes_count}
                        type="podcast"
                        lang={lang}
                      >
                        <SvgSymbol icon="card" size="unset" />
                      </DocumentItemsCount>
                    </MetaItem>
                    <MetaItem bullets>
                      <Timestamp
                        publishedAt={item.datetime}
                        type="fromNow"
                        locale={lang}
                      />
                    </MetaItem>
                  </Meta>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
