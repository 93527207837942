import React from 'react'

export const IconGoogle = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '8px', marginTop: '-2px', verticalAlign: 'middle' }}
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M14.684 7.676c0-.508-.041-1.02-.13-1.52H7.504v2.88h4.038a3.46 3.46 0 01-1.494 2.272v1.868h2.409c1.415-1.302 2.228-3.225 2.228-5.5z"
        fill="#4285F4"
      />
      <path
        d="M7.503 14.98c2.016 0 3.717-.661 4.956-1.804l-2.41-1.868c-.67.456-1.535.714-2.544.714-1.95 0-3.604-1.316-4.197-3.085H.82v1.926a7.478 7.478 0 006.682 4.118z"
        fill="#34A853"
      />
      <path
        d="M3.305 8.937a4.478 4.478 0 010-2.863V4.148H.82a7.483 7.483 0 000 6.715l2.484-1.926z"
        fill="#FBBC04"
      />
      <path
        d="M7.503 2.986a4.063 4.063 0 012.868 1.121l2.134-2.134A7.186 7.186 0 007.503.027 7.475 7.475 0 00.82 4.148l2.484 1.926c.59-1.772 2.247-3.088 4.198-3.088z"
        fill="#EA4335"
      />
    </g>
  </svg>
)
