import { generateCardChapters } from './generateCardChapters'
import { generateFeatureChapters } from './generateFeatureChapters'

export const generateChapters = (data = null, layout, content = null) => {
  switch (layout) {
    case 'card': {
      return generateCardChapters(data)
    }
    case 'rich': {
      return generateFeatureChapters(content)
    }
    default:
      break
  }
}
