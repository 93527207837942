import React from 'react'
import { BriefSubscriptionContainer } from '@client/components/Common/BriefSubscriptionContainer'
import { ImageUrls } from '@client/types'

interface BriefSubscriptionProps {
  correlation: keyof ImageUrls
}

export const BriefSubscriptionBlock: React.FC<BriefSubscriptionProps> = ({
  correlation
}) => <BriefSubscriptionContainer correlation={correlation} variant="screen" />
