import React from 'react'

import styles from './Informer.module.css'

export interface InformerProps {
  icon: string
  children: React.ReactNode
}

export const Informer: React.FC<InformerProps> = ({ icon, children }) => (
  <div className={styles.root}>
    {icon && (
      <span className={styles.icon}>
        <img src={icon} alt="icon" width="15" height="15" role="presentation" />
      </span>
    )}
    <span className={styles.text}>{children}</span>
  </div>
)
