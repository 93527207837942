export default {
  ru: {
    text: 'Мы используем куки!',
    linkText: 'Что это значит?',
    label: 'Закрыть предупреждение',
    link: '/feature/2018/05/22/kak-meduza-ispolzuet-cookie'
  },

  en: {
    text: 'We use cookies!',
    linkText: 'What does that mean?',
    label: 'Close notification',
    link: '/en/feature/2018/05/25/how-meduza-uses-cookies'
  }
}
