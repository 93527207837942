// eslint-disable-next-line simple-import-sort/imports
import './wdyr'

import React from 'react'

/**
 * В первую очередь имортируем базвые стили
 */
import './styles/global.css'

import { hydrateRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import Bugsnag, { Event } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Routes } from '@client/routes'
import { Provider as AlertProvider } from '@common/Alert'
import { AlertTemplate } from '@common/AlertTemplate'
import { ErrorComponent } from '@common/Error/Error'
import { Provider as LightboxProvider } from '@common/Lightbox'
import { App } from '@components/App'
import { loadableReady } from '@loadable/component'
import configureStore from '@store/configureStore'
import { handleEmailLogin } from '@utils/handleEmailLogin'
import { checkUserData } from '@utils/checkUserData'
import { setupFirebaseMessaging } from '@utils/setupFirebaseMessaging'
import rootSaga from '@store/rootSaga'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { RE_CAPTCHA_KEY } from './constants'

import './styles/base.module.css'

if (process.env.NODE_ENV === 'development') {
  import('./styles/fonts.css')
}
/*
Это необходимо для того, чтобы идентифицировать устройство ввода пользователя
и скрывать ненужные обводки на элементах при навигации
*/

function handleInputDevice(event: MouseEvent | KeyboardEvent) {
  document.body.dataset.input =
    event.type === 'mousedown' ? 'mouse' : 'keyboard'
}

Bugsnag.start({
  apiKey: '707b4f358cd187b4943be2afd14413ec',
  appVersion: '1.0.0',
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['production', 'staging']
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const store = configureStore(window.mdzAppState)
store.runSaga(rootSaga)

const onError = (event: Event) => {
  console.log('BUGSNAG is about to send this event', { event })
}

const onHydratedCallback = () => {
  window.addEventListener('mousedown', handleInputDevice, false)
  window.addEventListener('keydown', handleInputDevice, false)

  window.loadAndInitializeFirebase()

  if (typeof localStorage !== 'undefined') {
    const { dispatch } = store
    // this methods only work if there is localStorage
    // and only after firebase is loaded
    window.performanceObservable('firebase-loaded', () => {
      checkUserData(dispatch)
      handleEmailLogin(dispatch)
      setupFirebaseMessaging(dispatch)
      window.performance.mark('firebase-ready')
    })
  }
}

loadableReady(() => {
  const rootEl = document.getElementById('root')!

  hydrateRoot(
    rootEl,
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={
          ErrorComponent as React.ComponentType<{
            error: Error
            info: React.ErrorInfo
            clearError: () => void
          }>
        }
        onError={onError}
      >
        <HelmetProvider>
          <AlertProvider template={AlertTemplate}>
            <LightboxProvider>
              <GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_KEY}>
                <Provider store={store}>
                  <App callback={onHydratedCallback}>
                    {renderRoutes(Routes)}
                  </App>
                </Provider>
              </GoogleReCaptchaProvider>
            </LightboxProvider>
          </AlertProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </BrowserRouter>
  )
})
