import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MediaQuerySizes, UIElementsSizes } from '@client/constants'
import { getApp } from '@selectors/appSelectors'
import { trackUiKitAction } from '@store/Analytics/analyticsActions'

/*
хук для общения между нашими эмбедами посредством postMessage
*/

export const useBridge = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isEmbedded } = useSelector(getApp)

  const performAction = (dataArr: string[], el: HTMLIFrameElement) => {
    const app = dataArr[0]
    const action = dataArr[2]
    const data = dataArr[3]

    if (el) {
      // это для игр написанных до 2018 года
      if (app === 'duckling' || app === 'shprota') {
        if (action === 'height') {
          el.style.height = data + 'px'
        } else if (action === 'origin') {
          const { origin, pathname } = window.location
          const msg =
            'external::origin::' + encodeURIComponent(origin + pathname)
          el.contentWindow && el.contentWindow.postMessage(msg, '*')
        } else if (action === 'bridge') {
          const embedded =
            window.location.search.indexOf('embedded=true') > -1 ||
            window.location.search.indexOf('embedded=live') > -1
          const msg = 'external::bridge::' + (embedded ? 'true' : 'false')

          el.contentWindow && el.contentWindow.postMessage(msg, '*')
          el.contentWindow && el.contentWindow.postMessage('start', '*')
        }
      }
    }
  }

  const handleMessage = useCallback(
    (e: MessageEvent) => {
      if (
        typeof e.data === 'string' &&
        e.data.indexOf('mdzMaterialEmbedHeight=') > -1
      ) {
        const msg = JSON.parse(e.data.replace('mdzMaterialEmbedHeight=', ''))
        if (msg.action.type === 'UPDATE_HEIGHT') {
          const sourceEl = document.getElementById(msg.action.payload.id)

          if (sourceEl) {
            sourceEl.style.height = `${msg.action.payload.height}px`
          }
        }
      }

      // Это для трекинга аналитикой событий в ui-kit
      if (typeof e.data === 'string' && e.data.indexOf('mdzKitMsg=') > -1) {
        const { category, element, action } = JSON.parse(
          e.data.replace('mdzKitMsg=', '')
        )
        dispatch(trackUiKitAction({ category, element, action }))
      }

      // Это для современных игр
      if (typeof e.data === 'string' && e.data.indexOf('mdzEmbedMsg=') > -1) {
        const msg = JSON.parse(e.data.replace('mdzEmbedMsg=', ''))

        if (msg.action.type === 'PING') {
          const embedded = isEmbedded

          const source = e.source as Window

          source &&
            source.postMessage(
              `mdzEmbedMsg=${JSON.stringify({
                embedId: msg.embedId,
                action: {
                  type: 'PONG',
                  payload: { embedded }
                }
              })}`,
              '*'
            )
        } else if (msg.action.type === 'UPDATE_HEIGHT') {
          const sourceEl = document.getElementById(msg.embedId)

          if (sourceEl) {
            sourceEl.style.height = `${msg.action.payload.height}px`
          }
        } else if (msg.action.type === 'NEXT_QUESTION') {
          console.log('NEXT_QUESTION')
          const sourceEl = document.getElementById(msg.embedId)

          sourceEl && sourceEl.scrollIntoView()
          if (sourceEl) {
            const topbarOffset =
              window.innerWidth > MediaQuerySizes.LANDSCAPE_TABLET
                ? UIElementsSizes.TOPBAR_HEIGHT + UIElementsSizes.TOPBAR_GAP
                : 0
            const targetY =
              sourceEl.getBoundingClientRect().top -
              window.top.document.body.getBoundingClientRect().top -
              topbarOffset
            window.scroll({
              top: targetY,
              behavior: 'smooth'
            })
          }
        }
      }

      // это для игр написанных до 2018 года
      if (typeof e.data === 'string' && e.data.includes('duckling')) {
        const dataArr: string[] = e.data.split('::')
        const id = dataArr[1]

        if (id) {
          performAction(
            dataArr,
            document.getElementById(id) as HTMLIFrameElement
          )
        }
      }

      // это для монитора
      if (typeof e.data === 'object' && e.data.type === 'monitor:navigation') {
        history.push(e.data.to)
      }
    },
    [dispatch, history, isEmbedded]
  )

  useEffect(() => {
    if (process.env.PLATFORM === 'web') {
      window.addEventListener('message', handleMessage)

      return () => window.removeEventListener('message', handleMessage)
    }
    return undefined
  }, [handleMessage])
}
