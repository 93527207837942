import React, { useEffect, useState } from 'react'
import { Lang } from '@client/types'

import { GDPRPanel } from './GDPRPanel'

interface GDPRPaneContainerProps {
  lang: Lang
}

export const GDPRPanelContainer: React.FC<GDPRPaneContainerProps> = ({
  lang
}) => {
  const [isRendered, setIsRendered] = useState(false)
  const [gdprVisible, setGdprVisible] = useState(false)

  useEffect(() => {
    setIsRendered(true)
    const isGdprVisible = !Boolean(localStorage.getItem('mdzGDPRPanelClosed'))
    setGdprVisible(isGdprVisible)
    document.body.dataset.gdprPanel = String(isGdprVisible)
  }, [])

  const closeGdrp = () => {
    setGdprVisible(false)
    document.body.dataset.gdprPanel = 'false'
    localStorage.setItem('mdzGDPRPanelClosed', JSON.stringify(true))
  }

  if (!isRendered || !gdprVisible || lang === 'en') return null

  return <GDPRPanel lang={lang} closeGdrp={closeGdrp} />
}
